import $ from "jquery";

///////////////////////////Private Functions START//////////////////////////////
// In-memory cache (for quick access during the session)
const inMemoryCache = new Map();

// Cache expiration time in milliseconds (e.g., 7 days)
const CACHE_EXPIRATION = 7 * 24 * 60 * 60 * 1000;

// localStorage key namespace to avoid collisions
const LOCAL_STORAGE_KEY = "HttpBrowserCache";

// Load cache from localStorage
function loadCacheFromLocalStorage() {
  const cacheString = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (cacheString) {
    try {
      const parsedCache = JSON.parse(cacheString);
      Object.keys(parsedCache).forEach((key) => {
        inMemoryCache.set(key, parsedCache[key]);
      });
    } catch (e) {
      console.error("Failed to parse cache from localStorage:", e);
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }
}

// Save cache to localStorage
function saveCacheToLocalStorage() {
  const cacheObject = {};
  inMemoryCache.forEach((value, key) => {
    cacheObject[key] = value;
  });
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(cacheObject));
  } catch (e) {
    console.error("Failed to save cache to localStorage:", e);
    // Optionally implement cache eviction here if storage is full
  }
}

// Helper function to generate unique cache keys
function generateCacheKey(url, method, params) {
  const paramsString = params ? JSON.stringify(params) : "";
  return `${method.toUpperCase()}::${url}::${paramsString}`;
}

// Initialize cache on module load
loadCacheFromLocalStorage();
///////////////////////////Private Functions END//////////////////////////////

///////////////////////////Public Functions START//////////////////////////////
const HttpBrowser = {
  /**
   * Get JSON-Data from remote server using http(s) protocol.
   *
   * @param {Function} cb - Callback function to be invoked once async execution is finished
   * @param {String} url - Full URL without protocol
   * @param {String} method - ENUM-String for request method (GET | POST | .. )
   * @param {Map} params - (Optional) Params to be sent with this request
   * @param {String} request_content_type - (Optional) Request Body data type. Default: x-www-form-urlencoded ('json' | 'x-www-form-urlencoded' | 'multipart')
   * @param {String} auth - (Optional) Authorization header
   *
   * @return - Thru Callback
   *
   * @callback(error, response_status, response_data) - Request Callback
   */
  fetch: function (cb, url, method, params, request_content_type, auth) {
    const cacheKey = generateCacheKey(url, method, params);

    // Only cache GET requests
    if (method.toUpperCase() === "GET") {
      if (inMemoryCache.has(cacheKey)) {
        const cachedResponse = inMemoryCache.get(cacheKey);
        const isExpired =
          Date.now() - cachedResponse.timestamp > CACHE_EXPIRATION;

        if (!isExpired) {
          console.log("Serving from in-memory cache:", cacheKey);
          cb(null, cachedResponse.data, cachedResponse.status);
        } else {
          // Remove expired cache entry
          inMemoryCache.delete(cacheKey);
          saveCacheToLocalStorage();
        }
      } else {
        // Attempt to load from localStorage
        const cacheString = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (cacheString) {
          try {
            const parsedCache = JSON.parse(cacheString);
            if (parsedCache[cacheKey]) {
              const cachedResponse = parsedCache[cacheKey];
              const isExpired =
                Date.now() - cachedResponse.timestamp > CACHE_EXPIRATION;

              if (!isExpired) {
                console.log("Serving from localStorage cache:", cacheKey);
                // Populate in-memory cache for faster access next time
                inMemoryCache.set(cacheKey, cachedResponse);
                return cb(null, cachedResponse.data, cachedResponse.status);
              } else {
                // Remove expired cache entry from both caches
                inMemoryCache.delete(cacheKey);
                delete parsedCache[cacheKey];
                localStorage.setItem(
                  LOCAL_STORAGE_KEY,
                  JSON.stringify(parsedCache)
                );
              }
            }
          } catch (e) {
            console.error("Failed to parse cache from localStorage:", e);
            localStorage.removeItem(LOCAL_STORAGE_KEY);
          }
        }
      }
    }

    // Proceed with AJAX request if not cached or cache expired
    $.ajax({
      url: url,
      type: method,
      data: params,
      crossDomain: true,
      xhrFields: {
        withCredentials: true, // Set to false if credentials aren't needed
      },
      contentType: "application/x-www-form-urlencoded",

      success: function (data, text_status, xhr) {
        // If GET request, store the response in both caches
        if (method.toUpperCase() === "GET" && xhr.status === 200) {
          const cacheEntry = {
            data: data,
            status: xhr.status,
            timestamp: Date.now(),
          };
          inMemoryCache.set(cacheKey, cacheEntry);

          // Load existing cache from localStorage
          let existingCache = {};
          const cacheString = localStorage.getItem(LOCAL_STORAGE_KEY);
          if (cacheString) {
            try {
              existingCache = JSON.parse(cacheString);
            } catch (e) {
              console.error(
                "Failed to parse existing cache from localStorage:",
                e
              );
              existingCache = {};
            }
          }

          // Update cache and save back to localStorage
          existingCache[cacheKey] = cacheEntry;
          try {
            localStorage.setItem(
              LOCAL_STORAGE_KEY,
              JSON.stringify(existingCache)
            );
          } catch (e) {
            console.error("Failed to save cache to localStorage:", e);
            // Optionally implement cache eviction here if storage is full
          }
        }

        cb(null, data ? data : null, xhr.status);
      },
      error: function (error, text_status, xhr) {
        cb(error, null, xhr.status);
      },
    });
  },
};
///////////////////////////Public Functions END//////////////////////////////

export default HttpBrowser;
