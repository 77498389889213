import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  BorderedDropdown,
  CustomDropdownOptions,
  CustomText,
  CustomTextArea,
  CustomTextInput,
  ModalComponent,
  PrimaryCta,
} from "../components";
import Core from "../common/clientSdk.js";
import Sdk from "../common/core/module";
// Import Redux State (Private scope)
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../redux/actions";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Utils from "../common/utils";
import { ToastContainer, toast } from "react-toastify";
import validator from "validator";
import UploadImageS3 from "../common/s3";
import { useSnackbar } from "react-simple-snackbar";
import WebStorage from "../common/web_storage";
import { BRAND_ID_KEY } from "../common/web_storage_keys";
import Icon from "../components/core_component/icon";
import { Loader } from "./loader";
import Modal from "react-modal";
import Select from "react-dropdown-select";

//const imagePickerIcon = require("../resources/images/image-picker.svg");
import Cropper from "react-easy-crop";
import ReactGA from "react-ga";
import LeftSideSignup from "./authentication/leftSideSignup.js";
import { isMobile } from "react-device-detect";
import countryList from "../common/data/country.js";
import { countries } from "../common/data/cuntries_code.js";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var( --blue-blue-500)",
    textAlign: "center",
    zIndex: "5",
    display: "flex",
    flexDirection: "column",
    height: "65%",
    // justifyContent: "space-around",
    minWidth: "350px",
    borderColor: "#f55879",
  },

  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

const AddBrandDetails = function (props) {
  const isAddNewBrand = useParams().id;
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  console.log("redux_selector v", redux_selector);

  var navigate = useNavigate();
  var redux_dispatch = useDispatch();
  const { BrandUser = {} } = redux_selector;
  const { org_deep_data = {} } = BrandUser;

  console.log("org_deep_data", org_deep_data.org_data.country);

  const imageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [imageId, setImageId] = useState();
  const [note, setNote] = useState("");
  const [brand_name, setBrandName] = useState("");
  const [openSnackbar] = useSnackbar();
  const [modalOpen, setModalOpen] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropArea, setCropArea] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [country, setCountry] = useState();
  const [countrySelected, setCountrySelected] = useState();
  const [error, set_error] = useState({
    brand_name: "",
    note: "",
    selected_image: "",
  });

  useEffect(() => {
    // Set the initial selected value based on idToShow
    if (org_deep_data?.org_data?.country) {
      const initialValue = Object.values(countryList).find(
        (option) => option.country_code === org_deep_data?.org_data?.country
      );
      console.log("initialValue", initialValue);
      if (initialValue) setCountrySelected([initialValue]);
    }
  }, [org_deep_data?.org_data?.country]);

  console.log("countrySelected", countrySelected);
  console.log("countrySelected 4", countryList);
  console.log("countrySelected 1", org_deep_data?.org_data?.country);

  useEffect(() => {
    if (croppedImage) {
      generateUploadUrl();
    }
  }, [croppedImage]);

  const onFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
    openModal();
  };

  const onCropChange = useCallback((crop) => {
    setCrop(crop);
  }, []);

  const onZoomChange = useCallback((zoom) => {
    setZoom(zoom);
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropArea(croppedAreaPixels);
  }, []);

  const handleCropImageSave = () => {
    if (cropArea) {
      const canvas = document.createElement("canvas");

      const ctx = canvas.getContext("2d");
      const imageObj = new Image();

      imageObj.onload = () => {
        canvas.width = cropArea.width;
        canvas.height = cropArea.height;
        ctx.drawImage(
          imageObj,
          cropArea.x,
          cropArea.y,
          cropArea.width,
          cropArea.height,
          0,
          0,
          cropArea.width,
          cropArea.height
        );

        // Get the cropped image data as a base64-encoded string
        const croppedImageData = canvas.toDataURL("image/jpeg");

        // Determine the image type (JPEG, PNG, etc.)
        const imageType = croppedImageData.split(";")[0].split(":")[1];

        // Convert the base64 data to a Blob
        const byteCharacters = atob(croppedImageData.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: imageType });

        // Create a File object
        const fileName = `cropped_${Date.now()}.${imageType.split("/")[1]}`;
        const croppedFile = new File([blob], fileName, { type: imageType });

        // Save the cropped File to the state

        setCroppedImage(croppedFile);
      };

      imageObj.src = image;
    }
    closeModal();
  };

  function updateProgress(status) {
    setLoading(status);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function createBrand(progressCb) {
    if (!validationForm()) {
      return false;
    }
    var params = {
      brand_data: JSON.stringify({
        name: brand_name,
        image_id: imageId,
        user_id: Sdk.User.getUserId(redux_selector.BrandUser.user_data),
        org_id: Sdk.User.getUserOrgId(redux_selector.BrandUser.user_data),
        note: note,
      }),
      org_id: Sdk.User.getUserOrgId(redux_selector.BrandUser.user_data),
    };

    Core.createBrand(
      createBrandSuccess,
      createBrandFailed,
      progressCb,
      params,
      redux_selector.Auth.auth_token
    );
  }

  function createBrandSuccess(response_data) {
    redux_dispatch({
      type: Actions.UPDATE_BRAND_DATA,
      payload: { brand_deep_data: response_data.b_d },
    });

    WebStorage.saveDataInWebStorage(
      BRAND_ID_KEY,
      response_data.b_d.brand_data.brand_id
    );

    if (isAddNewBrand) {
      navigate("/");
    } else {
      navigate("/create-campaign");
    }

    setCroppedImage(null);
  }

  function createBrandFailed(errorList) {
    showError(errorList.map((item) => item.m).join(", "));
  }

  // generate url for upload image
  function generateUploadUrl() {
    var params = {
      id: Sdk.User.getUserOrgId(redux_selector.BrandUser.user_data),
      file_type: croppedImage.type,
      action: "brand_logo",
    };
    setUploading(true);
    Core.getGenerateUploadUrl(
      generateUrlSuccess,
      generateUrlFailed,
      () => {},
      params,
      redux_selector.Auth.auth_token,
      croppedImage
    );
    closeModal();
  }

  function generateUrlSuccess(response) {
    if (response) {
      // upload image file on server call uploadImageS3
      UploadImageS3(croppedImage, response.upload_data, (error) => {
        setUploading(false);
        if (error) {
          return toast.error("Upload failed");
        }
        setImageId(response.upload_data.media_id);
      });
    } else {
      setUploading(false);
      toast.error("Something went wrong");
    }
  }

  function generateUrlFailed(error) {
    toast.error("Something went wrong");
    setUploading(false);
  }
  function validateImage(value) {
    // if (!value) {
    //   toast.info({
    //     ...error,
    //     selected_image: "Please Select image in format .jpg, .png",
    //   });
    // } else if (!value.name.match(/\.(jpg|jpeg|png|gif|JPG|PNG)$/)) {
    //   toast.info({
    //     ...error,
    //     selected_image: "Please select valid image.",
    //   });
    // } else {
    //   toast.info({ ...error, selected_image: "" });
    //   return true;
    // }
  }

  function validateBrandName() {
    // if (validator.isEmpty(value, {})) {
    //   toast.info({ ...error, brand_name: "Please enter brand name" });
    // } else {
    //   toast.info({ ...error, brand_name: "" });
    //   return true;
    // }
  }

  function validateBrandDescription() {
    // if (validator.isEmpty(value, {})) {
    //   toast.info({ ...error, note: "Please enter brand description" });
    // } else {
    //   toast.info({ ...error, note: "" });
    //   return true;
    // }
  }

  function validationForm() {
    // if (!imageId) {
    //   toast.info("Please select image.");
    //   return false;
    // }

    // if (!imageId.match(/\.(jpg|jpeg|png|gif|JPG|PNG)$/)) {
    //   toast.info("Please select valid image.");
    //   return false;
    // }
    if (!brand_name) {
      toast.info("Please enter brand name");
      return false;
    }

    // if (!note) {
    //   toast.info("Please enter description ");
    //   return false;
    // }
    return true;
  }

  // Image upload

  const handleImageClick = () => {
    if (imageRef.current) {
      imageRef.current.click();
    }
  };

  function googleAnalytics() {
    ReactGA.event({
      category: "User",
      action: "REELAX_ADS_WEB_LOGIN",
    });
  }

  function showError(message) {
    toast.error(message);
  }

  function updateCounty(selectedItem) {
    setCountrySelected(selectedItem);
    setCountry(selectedItem ? selectedItem.map((option) => option.value) : []);
  }

  return (
    <div className="main-container">
      {/**brand-page-container */}
      <div className="content-sign-container">
        <LeftSideSignup />
        <div className="auth-right-sign-container">
          <div
            style={{ position: "relative" }}
            className={isMobile ? "p-l-r-20 m-l-15" : "inner-container"}
          >
            {/* {isAddNewBrand == "add-new" && (
            <div onClick={() => navigate("/")} className="close-icon-brand">
              <Icon iconName="CloseRoundButtonIcon" />
            </div>
          )} */}

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
            >
              <div onClick={closeModal} className="close-btn-icon m-b-15">
                <Icon iconName="CloseRoundButtonIcon" />
              </div>

              <div
                style={{
                  flexDirection: "column",
                  position: "relative",
                  height: 300,
                }}
                className="m-t-20"
              >
                <div style={{ height: 200, width: 200 }}>
                  {image && (
                    <React.Fragment>
                      <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={1 / 1}
                        onCropChange={onCropChange}
                        onZoomChange={onZoomChange}
                        onCropComplete={onCropComplete}
                      />
                    </React.Fragment>
                  )}
                </div>
              </div>
              {image && (
                <div className="m-t-32 add-new-brand-btn full-width">
                  <PrimaryCta
                    fontSize={"font_size_large"}
                    fullWidth={true}
                    text={"Set Brand Logo"}
                    isLoading={loading}
                    // disabled={Utils.isNull(creditId)}
                    onClick={() => handleCropImageSave()}
                  />
                </div>
              )}
            </Modal>
            <div className="m-t-16 sign-up-logo full-width">
              <CustomText
                h5
                fontSize={"font_size_25"}
                text={
                  isAddNewBrand == "add-new" ? "Add New Brand" : "Add a Brand"
                }
                textColor={"text_color_primary"}
                fontWeight={"rubik_regular"}
                textAlign={"text_align_left"}
              />
              {/* <CustomText
              p
              fontSize={"font_size_small"}
              text={`Step 2 of 2`}
              textColor={"text_color_secondary"}
              fontWeight={"Poppins_regular_medium"}
            /> */}
              {isAddNewBrand !== "add-new" && (
                <div className="create_profile_step">
                  Step <span>2</span> of 2
                </div>
              )}
            </div>
            <div className="m-t-16 full-width">
              <CustomText
                h5
                fontSize={"font_size_large"}
                text={"Brand Logo"}
                textColor={"text_color_primary"}
                fontWeight={"rubik_regular"}
                textAlign={"text_align_left"}
              />
            </div>
            {/* Image upload start */}
            <div style={{ position: "relative", top: 10 }}>
              <div
                style={{
                  //display: 'flex',
                  width: "120px",
                  height: "120px",
                  backgroundColor: "#F0F3F9",
                  border: "1px solid #E6E7E8",
                  borderRadius: "50%",
                  marginRight: "8px",
                  cursor: "pointer",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {croppedImage ? (
                  <div className="m-t-4">
                    <img
                      alt="not fount"
                      width={"125px"}
                      height={"125px"}
                      style={{
                        borderRadius: "50%",
                        marginTop: 3,
                        cursor: "pointer",
                      }}
                      src={URL.createObjectURL(croppedImage)}
                    />
                    <img
                      src="../assets/images/re-upload-brand.svg"
                      className="re_upload_brand_icon"
                      alt=""
                      srcset=""
                    />
                  </div>
                ) : (
                  <img
                    style={{ cursor: "pointer" }}
                    src={"../assets/images/Camera.svg"}
                    height={50}
                  />
                )}

                <input
                  style={{
                    position: "absolute",
                    left: 0,
                    opacity: 0,
                    width: 150,
                    height: 150,
                    borderRadius: "50%",
                    cursor: "pointer",
                    background: "#8E8E93",
                  }}
                  type="file"
                  accept="image/*"
                  name="myImage"
                  ref={imageRef}
                  onChange={onFileChange}
                />
              </div>
              <div className="brand-add-page-loader">
                {isUploading && <Loader />}
              </div>
            </div>

            <h5 style={{ color: "red" }}>{error.selected_image}</h5>
            <div className="full-width m-t-20">
              <CustomText
                p
                fontSize={"font_size_small"}
                text={`Supported formates: JPEG & PNG`}
                textColor={"text_color_secondary"}
                fontWeight={"Poppins_regular_medium"}
                textAlign={"text_align_left"}
              />
            </div>

            {/* Image upload start */}
            <div className="m-t-16">
              <CustomText
                h5
                fontSize={"font_size_large"}
                text={`Brand Name`}
                textColor={"text_grey_400_color"}
                fontWeight={"Poppins_regular_large"}
              />

              <div className="m-t-4 full-width ">
                <CustomTextInput
                  className="custom-input-text"
                  placeholder="Your brand Name"
                  onChange={(e) => {
                    setBrandName(e.target.value);
                  }}
                  value={brand_name}
                  // onBlur={(e) => validateBrandName(brand_name)}
                />
                {/* <h5 style={{ color: "red" }}>{error.brand_name}</h5> */}
              </div>
            </div>
            <div className="m-t-16 full-width">
              <CustomText
                h5
                fontSize={"font_size_large"}
                text={`Country`}
                textColor={"text_grey_400_color"}
                fontWeight={"Poppins_regular_large"}
              />

              <div className="m-t-4 full-width ">
                <Select
                  multi={false}
                  options={Object.values(countryList)}
                  values={countrySelected}
                  onChange={(value) => updateCounty(value)}
                  placeholder="Select County"
                  labelField="name"
                  valueField="country_code"
                  autoFocus={false}
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "1px solid #b3b3b6",
                    backgroundColor: "#fff",
                    borderRadius: 8,
                    paddingBottom: "16px",
                    paddingTop: "16px",
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />
              </div>
            </div>
            {/* <div className="m-t-16 full-width">
              <CustomText
                h5
                fontSize={"font_size_large"}
                text={`Description`}
                textColor={"text_grey_400_color"}
                fontWeight={"Poppins_regular_large"}
                textAlign={"text_align_left"}
              />
            </div> */}

            {/* <div className="full-width m-t-4 h-center">
              <CustomTextArea
                className="custom-input-text"
                placeholder={`Enter your message`}
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                value={note}
                // onBlur={(e) => validateBrandDescription(note)}
              />
              <h5 style={{ color: "red" }}>{error.note}</h5>
            </div> */}

            <div className="m-t-36 full-width h-center">
              <PrimaryCta
                fullWidth={true}
                text={"Add Brand"}
                // disabled={!brand_name || !note || !imageId}
                isLoading={loading}
                onClick={() => createBrand(updateProgress)}
              />
            </div>
          </div>
        </div>
        <ModalComponent
          isOpen={modalOpen}
          onRequestClose={() => setModalOpen(!modalOpen)}
          customStyle={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              borderColor: "#f55879",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
            },
          }}
        >
          <button onClick={() => setModalOpen(!modalOpen)}>Close Modal</button>
        </ModalComponent>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          zIndex={9999999}
        />
      </div>
    </div>
  );
};
export default AddBrandDetails;
