"use-strict";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Icon from "../components/core_component/icon";
import { standardPlaneIcon } from "../components/assets/icons";
import Core from "../common/clientSdk";
import { ToastContainer, toast } from "react-toastify";
import {
  CustomRadioButton,
  CustomText,
  PrimaryCta,
  UnderLinedLinkCta,
} from "../components";
import { Loader } from "../screens/loader";
import Utils from "../common/utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckoutParcel from "./checkoutParcel";
import CompanySettingModal from "../screens/companyDetails/addressDetailVerify";
import UpdateGSTModal from "./updateGSTModal";

function SubscriptionPlan(props) {
  const [selectSubscriptionPlan, setSelectSubscriptionPlan] = useState();
  const [showSubscriptionPlan, setShowSubscriptionPlan] = useState(false);
  const [checkoutPlan, setCheckoutPlan] = useState();
  const [checkoutSummary, setCheckoutSummary] = useState();
  const [addGSTModalOpen, setAddGSTModalOpen] = useState(false);

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { org_deep_data = {} } = BrandUser;
  const { user_data = {} } = BrandUser;

  useEffect(() => {
    if (checkoutSummary) {
      props.setCheckoutSummary(checkoutSummary);
    }
  }, [checkoutSummary]);

  function showPlanHandler() {
    setShowSubscriptionPlan(!showSubscriptionPlan);
  }

  function subscriptionUserCreateCreditNew() {
    const params = {
      // org_id: org_deep_data?.org_data?.org_id,
      c_id: "",
      quantity: "",
      value: "",
      redirect_url: window.location.origin + "/create-campaign",
      platform: "web",
    };

    Core.subscriptionUserCreateCreditNew(
      subscriptionUserCreateCreditNewSuccess,
      subscriptionUserCreateCreditNewFail,
      () => {},
      params
    );
  }

  function subscriptionUserCreateCreditNewSuccess(response) {
    const pgOrderId = response.credit_order_data.pg_order_id;
    redirectToPaymentPage(pgOrderId);
  }

  function subscriptionUserCreateCreditNewFail() {}
  const phonePayGateWay = process.env.REACT_APP_API_PHONE_PAY_URL;
  function redirectToPaymentPage(pgOrderId) {
    // const paymentUrl = `https://mercury-t2.phonepe.com/transact/pg?token=${pgOrderId}`;
    const paymentUrl = `${phonePayGateWay}${pgOrderId}`;
    window.location.href = paymentUrl;
  }

  const openModalGTS = () => {
    if (addGSTModalOpen == false) {
      setAddGSTModalOpen(true);
    } else {
      setAddGSTModalOpen(false);
    }
  };

  const closeModalGST = () => {
    setAddGSTModalOpen(false);
  };

  function renderStandardPlan(item) {
    return (
      <div className="subscription-plan-container subscription-container-">
        <div>
          <div
            style={{ justifyContent: "space-evenly" }}
            className="standard-plan-heading"
          >
            <Icon iconName="standardPlaneIcon" />
            <div className="">
              <p className="typography-14 m-b-4 ">
                <span>Standard Plan</span>
                {item?.subscription_data?.name}
              </p>
              <h5 className="typography-25 standard-plan-amount">
                {Utils.convertFromINR(item?.subscription_data?.price, "IN")}
              </h5>
            </div>
            <div className="m-l-15">
              <CustomRadioButton
                value={"FIRST"}
                checked={selectSubscriptionPlan == "FIRST"}
                onChange={(e) => setSelectSubscriptionPlan(e.target.value)}
              />
            </div>
          </div>
          <div className="all-row-sub-plan-data">
            <div className="row-plan">
              <p className="typography-14-600">Free Credits</p>
              <p className="typography-14-600">{item.feature.free_credits}</p>
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Discount on Credits</p>
              <p className="typography-14-600">
                {" "}
                {item.feature.discount_on_credits} %
              </p>
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Dedicated Support</p>
              <Icon iconName="nonFillTick" />
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Influencer Discovery</p>
              <Icon iconName="nonFillTick" />
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Multi Brand</p>
              <Icon iconName="nonFillTick" />
            </div>
            <div className="row-plan">
              <p className="typography-14-600">Fake Follower Check</p>
              <Icon iconName="nonFillTick" />
            </div>
          </div>
          {/* <PrimaryCta
            invert
            className="buy-credits-btn height-42"
            //  onClick={() => postCreditOrder()}
            fontSize={"font_size_large"}
            //  isLoading={isLoading}
            text={"Buy Credits"}
          /> */}
        </div>
      </div>
    );
  }

  function calculatePercentage(actual_value, value) {
    let percentage = value * 100;
    return percentage / actual_value;
  }

  return (
    <div className="create-campaign-subscription-main-container">
      {/* {showSubscriptionPlan && (
        <div className="create-campaign-subscription-inner-container">
          <div className="all-plan-container">
            <div>{renderStandardPlan(data)}</div>
            <div>{renderStandardPlan(data)}</div>
            <div>{renderStandardPlan(data)}</div>
          </div>
          <div>
            <CustomText
              p
              ellipsis
              fontSize={"font_size_extraSmall"}
              text={"*All texes are includes"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_regular"}
            />
          </div>
        </div>
      )} */}

      <div style={{}} className="">
        <div>
          <CustomText p text={"Checkout"} className="h6Text" />
          {props?.basic_subscription_data && (
            <div className="checkout-select-inner-container">
              <CheckoutParcel
                credits={props?.basic_subscription_data?.credits}
                user_data={user_data}
                setCheckoutSummary={setCheckoutSummary}
              />
            </div>
          )}
        </div>
        {checkoutSummary && (
          <div className="m-t-20">
            <CustomText p text={"Purchase Summary"} className="button2" />
            <div className="purchase-summary-inner-container  m-t-12 m-b-10">
              <CustomText p text={"Credits"} className="typographyH5" />
              <CustomText
                p
                text={checkoutSummary?.quantity}
                className="typographyH5 "
              />
            </div>
            <div className="purchase-summary-inner-container  m-t-12 m-b-10">
              <CustomText p text={"Price"} className="typographyH5" />
              <CustomText
                p
                text={
                  checkoutSummary?.value
                    ? Utils.convertFromINR(
                        checkoutSummary?.value,
                        user_data.country
                      )
                    : Utils.convertFromINR(
                        checkoutSummary?.actual_value,
                        user_data.country
                      )
                }
                className="typographyH5 "
              />
            </div>

            {checkoutSummary?.actual_value && (
              <div className="purchase-summary-inner-container   m-t-12 ">
                <div className="flex-row-baseline">
                  <CustomText
                    p
                    text={`Active Subscription Discount (${props.active_subscription_data?.feature?.discount_on_credits}%)`}
                    className="typographyH5"
                  />
                  {/* <CustomText
                    p
                    text={`(${props.active_subscription_data?.feature?.discount_on_credits}%)`}
                    className="caption_2_10 "
                  /> */}
                </div>
                <CustomText
                  p
                  text={Utils.convertFromINR(
                    checkoutSummary?.actual_value - checkoutSummary?.value,
                    user_data.country
                  )}
                  className="typographyH5 "
                />
              </div>
            )}
            <div className="dash-border-bottom m-b-10 m-t-16"></div>
            <div className="purchase-summary-inner-container p-b-10 ">
              <CustomText p text={`Sub Total`} className="typographyH5 " />

              <CustomText
                p
                text={Utils.convertFromINR(
                  checkoutSummary?.value,
                  user_data.country
                )}
                className="typographyH5 "
              />
            </div>

            <div className="purchase-summary-inner-container dash-border-bottom p-b-20">
              <div className="flex-row-baseline">
                <CustomText
                  p
                  text={`GST (${checkoutSummary.gst}%)`}
                  className="typographyH5 "
                />
                {/* <CustomText
                  p
                  text={`(${checkoutSummary.gst}%)`}
                  className="caption_2_10 "
                /> */}
              </div>
              <CustomText
                p
                text={Utils.convertFromINR(
                  checkoutSummary.gst_value,
                  user_data.country
                )}
                className="typographyH5"
              />
            </div>
            {!org_deep_data?.org_data?.gst_number && (
              <div onClick={openModalGTS} className="m-t-8">
                <u className="add-gts-number">Add GST Number</u>
              </div>
            )}
            <div className="purchase-summary-inner-container m-t-16">
              <CustomText p text={"Total "} className="typography14 " />
              <CustomText
                p
                text={Utils.convertFromINR(
                  checkoutSummary?.total,
                  user_data.country
                )}
                className="typographyH5 "
              />
            </div>
          </div>
        )}
      </div>
      {addGSTModalOpen && <UpdateGSTModal closeModal={closeModalGST} />}
    </div>
  );
}

export default SubscriptionPlan;
