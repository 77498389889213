import React, { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  FallBack,
  ProposalsList,
  CustomText,
  CategoryNames,
  ConfirmationDialog,
  Image,
  SearchFallBack,
  CustomButton,
  PrimaryCta,
  FallBackBtn,
  Sidebar,
  CustomTextInput,
} from "../../components";
import Core from "../../common/clientSdk";
import Utils from "../../common/utils";
import { Loader } from "../loader";
import InfluencerDetail from "./influencerDetailScreen";
import ReactGA from "react-ga";
import Icon from "../../components/core_component/icon";
//import { Tooltip as ReactTooltip } from "react-tooltip";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import tr from "date-fns/esm/locale/tr/index.js";
import CustomTable from "../../components/customTable";
import * as CampaignData from "../../common/data/campaign";
import { useDispatch } from "react-redux";
import * as Actions from "../../redux/actions";
import WebStorage from "../../common/web_storage";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-dropdown-select";
import Style from "../../resources/style";
import { ToastContainer, toast } from "react-toastify";
import CreateCollectionGrid from "../collection/collectionGrid";
import InfluencerDetailModal from "../../modal/influencerDetialModal";
import { RepostJobOptionModal } from "../../modal/repostJobOption";
import CreateCampaignCollection from "./createCampaignCollection";
const options = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "all", label: "All" },
];
const inviteOption = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];
const customBidStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--general-secondary-color)", // Change header background color
      color: "var(--gray-gray-300)", // Change header text color
      fontSize: "14px",
      fontWeight: 600,
      lineHight: "19.2px",
      border: "none",
    },
  },
  headCells: {
    style: {
      padding: "10px 0px 10px 20px",
    },
  },
  rows: {
    style: {
      cursor: "pointer",
      fontSize: "12px",
      paddingTop: "5px",
      paddingBottom: "5px",
      fontWeight: 400,
      lineHight: "19.2px",
      "&:hover": {
        backgroundColor: "var(--general-secondary-color)",
        fontWeight: 600,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
    },
  },
};

const sortTypes = [
  {
    sortBy: "followers",
    sortOrder: "asc",
    label: "Followers - Low to High",
  },
  {
    sortBy: "followers",
    sortOrder: "desc",
    label: "Followers - High to Low",
  },
];

const limit = 10;
function InviteList(props) {
  const [isLoading, setLoading] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [modalIsOpenCollection, setModalIsOpenCollection] = useState(false);
  const [modalIsOpenCollectionGrid, setModalIsOpenCollectionGrid] =
    useState(false);
  const [isInviteLoading, setInviteLoading] = useState({});
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(10);

  const [openSnackbar] = useSnackbar();
  const [hoveredIcons, setHoveredIcons] = useState([]);
  const [selectedCollectionId, setSelectedCollectionId] = useState(
    props.selectedCollectionId
  );

  console.log("selectedCollectionId", selectedCollectionId);
  const [searchActive, setSearchActive] = useState(false);
  const [searchName, setSearchName] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [filterLabel, setFilterLabel] = useState("");
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [influencerList, setInfluencerList] = useState(null);
  const [influencerInviteList, setInfluencerInviteList] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [bidList, setBidList] = useState();
  const [listLoading, setListLoading] = useState(false);
  const [totalRows, setTotalRows] = useState("");
  const [influencerId, setInfluencerId] = useState(null);
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [profession, setProfession] = useState([]);
  const [invitedMark, setInvitedMark] = useState([]);
  const [influencerGender, setInfluencerGender] = useState([]);
  const [clearFilter, setClearFilter] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);
  const [showCollectionBtn, setShowCollectionBtn] = useState(false);
  const [repostModal, setRepostModal] = useState(false);
  const [collectionInfluencerList, setCollectionInfluencerList] = useState([]);

  const [filterData, setFilterData] = useState({
    gender: null,
    city: [],
    state: [],
    follower: [],
    category: [],
    profession: [],
    counter: null,
    invite_mark: null,
  });
  const campaignId = props.campaignId;
  var redux_dispatch = useDispatch();
  const navigate = useNavigate();
  const campaignDetail = props.campaignDetail;
  const categoryId =
    campaignDetail?.influencer_data &&
    Object.keys(campaignDetail?.influencer_data?.categories);
  const platformId =
    campaignDetail?.content_type_deep_data &&
    campaignDetail?.content_type_deep_data[0]?.platform_data?.platform_id;

  const campaignStatus = campaignDetail?.campaign_data?.status;
  const genderFilter = campaignDetail?.audience_data?.gender;
  const genderLocation = campaignDetail?.audience_data?.state;
  let cities = CampaignData.CITY.filter((item) => {
    return item.type == "city";
  });
  console.log("genderLocation 1", genderLocation);
  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { user_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;
  function updateProgress(status) {
    setLoading(status);
  }
  function updateNotProgress(status) {
    // setLoading(status);
  }

  useEffect(() => {
    if (props.selectedCollectionId) {
      getCollectionInfluencerList();
    }
  }, [props.selectedCollectionId]);
  useEffect(() => {
    if (props.isActive) {
      getInfluencerInviteList(updateNotProgress);
    }
  }, [props.isActive]);

  useEffect(() => {
    getBidList();

    if (campaignStatus != "ACTIVE") {
      setInfluencerList([]);
    }

    setClearFilter(false);
  }, [clearFilter]);

  useEffect(() => {
    if (sortBy) {
      getBidList();
    }
  }, [sortBy, sortOrder]);

  function getBidList() {
    var params = {
      campaign_id: campaignId,
    };

    Core.getBidList(
      getBidListSuccess,
      getBidListFailed,
      updateProgress,
      params
    );
  }

  function getBidListSuccess(response_data) {
    setBidList(response_data["bid_list"]);

    getInfluencerList(response_data["bid_list"], 10);
    getInfluencerInviteList(updateProgress);
  }

  function getBidListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
    setLoading(false);
  }

  function getCollectionInfluencerList() {
    const params = {
      collection_id: props.selectedCollectionId,
    };
    Core.getCollectionInfluencerList(
      getCollectionInfluencerListSuccess,
      getCollectionInfluencerListFailed,
      () => {},
      params
    );
  }

  function getCollectionInfluencerListSuccess(response_data) {
    console.log("response_data", response_data);

    setCollectionInfluencerList(response_data.collection_deep_data);
  }

  function getCollectionInfluencerListFailed(errorList) {
    // toast.error(errorList.map((item) => item.m).join(", "));
    setCollectionInfluencerList([]);
    setListLoading(false);
  }
  function getInfluencerList(bidList, page) {
    setIsLoading(true);
    let newPage = 0;
    if (page == 10) {
      newPage = 0 * Number(page);
    } else {
      newPage = 10 * Number(page) - 10;
    }

    let filter_data = {
      location: filterData.state,
      gender: filterData.gender,
      profession: filterData.profession,
      is_invited: filterData?.invite_mark?.toString(),
    };
    var params = {
      campaign_id: campaignId,
      //  profession: campaignDetail?.campaign_data?.profession,
      // limit: 50,
      start: isNaN(newPage) ? 0 : newPage,
      filter_data: JSON.stringify(filter_data),
    };

    if (sortBy != null) {
      params.sort_by = sortBy;
    }
    if (sortOrder != null) {
      params.sort_order = sortOrder;
    }
    Core.getInfluencerList(
      (...args) => getInfluencerListSuccess(bidList, ...args),
      getInfluencerListFailed,
      updateNotProgress,
      params
    );
  }

  function getInfluencerListSuccess(bidList, response_data) {
    // setInfluencerListData(response_data.invite_list);
    if (bidList) {
      let influencerId =
        bidList && bidList?.map((item) => item.bid_data.influencer_id);
      if (response_data.invite_list) {
        const updateList = response_data.invite_list.filter(
          (item) => !influencerId?.includes(item.influencer_data?.influencer_id)
        );
        if (updateList) {
          setInfluencerList(updateList);
        } else {
          setInfluencerList([]);
        }
      }
    }
    if (response_data?.total_count) {
      setTotalRows(response_data?.total_count);
    }

    if (response_data?.total_count) {
      setTotalRows(response_data?.total_count);
    }

    setLoading(false);

    inviteListFilterClose();
    // const updateList =
    //   response_data &&
    //   response_data?.invite_list.filter((item) =>
    //     bidList?.find(
    //       (data) =>
    //         data.bid_data.influencer_id !== item.influencer_data?.influencer_id
    //     )
    //   );

    setIsLoading(false);
  }

  function getInfluencerListFailed(errorList) {
    toast.error(errorList);
    setInfluencerList([]);
    setLoading(false);
    setIsLoading(false);
  }

  const handlePageChange = (page) => {
    getInfluencerList(bidList, page);
  };

  function getInfluencerInviteList(progressBar) {
    var params = {
      campaign_id: campaignId,
    };
    Core.getInfluencerInviteList(
      getInfluencerInviteListSuccess,
      getInfluencerInviteListFailed,
      progressBar,
      params
    );
  }

  function getInfluencerInviteListSuccess(response_data) {
    setInfluencerInviteList(response_data.invite_list);
  }

  function getInfluencerInviteListFailed(errorList) {
    toast.error(errorList);
    setListLoading(false);
  }

  function postInfluencerInvite(influencer_id) {
    var params = {
      campaign_id: campaignId,
      influencer_id: influencer_id,
    };
    Core.postInfluencerInvite(
      postInfluencerInviteSuccess,
      postInfluencerInviteFailed,
      (status) => setInviteLoading({ [influencer_id]: status }),
      params
    );
  }

  function postInfluencerInviteSuccess(response_data) {
    toast.success("Request price sent to Influencer");
    userSelfData();
    getInfluencerList(bidList, 10);
    getInfluencerInviteList(updateNotProgress);
  }

  function postInfluencerInviteFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  function postInfluencerInviteWithdraw(influencer_id) {
    var params = {
      campaign_id: campaignId,
      influencer_id: influencer_id,
    };
    Core.postInfluencerInviteWithdraw(
      postInfluencerInviteWithdrawSuccess,
      postInfluencerInviteWithdrawFailed,
      () => {},
      params
    );
  }

  function postInfluencerInviteWithdrawSuccess(response_data) {
    toast.success("Request price withdraw");

    getInfluencerList(updateNotProgress);
    getInfluencerInviteList(updateNotProgress);
  }

  function postInfluencerInviteWithdrawFailed(errorList) {
    toast.error(errorList);
  }

  function userSelfData() {
    var params = { org_id: brand_deep_data.brand_data.org_id };

    Core.userSelfData(
      userSelfDataSuccess,
      userSelfDataFailed,
      () => {},
      params
    );
  }

  function getCollectionList() {
    const params = {
      campaign_id: campaignId,
      org_id: brand_data.org_id,
    };
    Core.getCollectionList(
      getCollectionListSuccess,
      getCollectionListFailed,
      updateProgress,
      params
    );
  }

  function getCollectionListSuccess(response_data) {
    if (response_data.collection_list.length > 0) {
      setSelectedCollectionId(response_data.collection_list[0].collection_id);
    }
  }

  function getCollectionListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function userSelfDataSuccess(response_data) {
    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: { org_deep_data: response_data.org_data },
    });
  }

  function userSelfDataFailed(errorList) {
    toast.error(errorList);
  }

  function inviteAndReject(id) {
    let inviteId = influencerInviteList.find(
      (item) => item.influencer_id == id
    );

    console.log("isShortlisted isSho", inviteId);
    return inviteId;
  }
  console.log("isShortlisted list", collectionInfluencerList);
  function disableShortListedBtn(influencerId) {
    console.log("isShortlisted 2", influencerId);
    let isShortlisted =
      collectionInfluencerList?.collection_influencer_list?.find(
        (item) => item?.influencer_data?.influencer_id == influencerId
      );

    console.log("isShortlisted ccc", isShortlisted);

    return isShortlisted;
  }

  const toggleFlyoutDropdown = () => {
    setInfluencerFlyOut(!influencerFlyOut);
  };

  // filter sub category

  function FilterOutSubCategory() {
    let subCateDataList = [];
    if (categoryId) {
      subCateDataList = categoryId.flatMap(
        (item) => CampaignData.FULL_CATEGORIES.subcategories[item]
      );
    }

    return subCateDataList;
  }

  // const influencerFollowersCounts = (platforms_data) => {
  //   const selectedPlatformData =
  //     platforms_data &&
  //     platforms_data?.find((item) => item.platform_id === platformId);

  //   return Utils.changeNumberFormate(selectedPlatformData?.followers);
  // };
  // const influencerLikesCounts = (platforms_data) => {
  //   const selectedPlatformData =
  //     platforms_data &&
  //     platforms_data?.find((item) => item.platform_id === platformId);

  //   return Utils.changeNumberFormate(selectedPlatformData?.avg_likes);
  // };
  // const influencerCommentCounts = (platforms_data) => {
  //   const selectedPlatformData =
  //     platforms_data &&
  //     platforms_data?.find((item) => item.platform_id === platformId);

  //   return Utils.changeNumberFormate(selectedPlatformData?.avg_comments);
  // };
  // const influencerViewCounts = (platforms_data) => {
  //   const selectedPlatformData =
  //     platforms_data &&
  //     platforms_data?.find((item) => item.platform_id === platformId);

  //   return Utils.changeNumberFormate(selectedPlatformData?.avg_views);
  // };

  const getSelectedPlatformData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === platformId);
  };

  const formatPlatformData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);
    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };

  const getEngagementRate = (platforms_data) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);
    var eng_data = null;

    var eng_rate = null;
    if (selectedPlatformData?.avg_likes && selectedPlatformData?.avg_comments) {
      eng_data =
        Number(selectedPlatformData?.avg_likes) +
        Number(selectedPlatformData.avg_comments);

      eng_rate = (eng_data / Number(selectedPlatformData.avg_views)) * 100;
    }

    return eng_rate?.toFixed(1);
  };
  function getLocations(locationsData, location) {
    let result = [];
    let specifiedStates = {};
    let includedCities = {};

    if (!location || location?.length == 0) {
      return locationsData;
    }

    // Identify specified states and cities
    location.forEach((locId) => {
      let item = locationsData.find((x) => x.id.toString() == locId);
      if (item) {
        if (item.type == "state") {
          specifiedStates[item.id] = true;
        } else if (item.type == "city") {
          includedCities[item.id] = true;
        }
      }
    });

    // Add all cities that belong to the specified states and the states themselves
    locationsData.forEach((item) => {
      if (item.type == "state" && specifiedStates[item.id]) {
        // Check if there are no specific cities selected for this state
        let citiesInStateSelected = location.some((locId) => {
          let city = locationsData.find(
            (x) => x.id.toString() == locId && x.type === "city"
          );
          return city && city.state_id == item.id;
        });

        if (!citiesInStateSelected) {
          result.push(item); // Add the state if no cities in it are specifically selected
        }
      } else if (
        item.type == "city" &&
        (includedCities[item.id] || specifiedStates[item.state_id])
      ) {
        if (!result.find((r) => r.id === item.id)) {
          // Check for duplicates
          result.push(item);
        }
      }
    });

    return result;
  }

  function SearchBarActive() {
    if (searchActive) {
      setSearchActive(false);
    } else {
      setSearchActive(true);
    }
  }

  function updateGender(selectedItem) {
    setInfluencerGender(selectedItem);
    if (selectedItem?.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          gender: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }
  function updateInviteMark(selectedItem) {
    setInvitedMark(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          invite_mark: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }

  function clearFilterFun() {
    setCity([]);
    setState([]);
    setInfluencerGender([]);
    setProfession([]);
    setInvitedMark([]);

    setFilterData({
      gender: null,
      city: [],
      state: [],
      follower: [],
      category: [],
      invite_mark: null,
    });
    setClearFilter(true);
  }
  function updateState(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setFilterData((prev) => {
      return {
        ...prev,
        state: value,
      };
    });

    setState(selectedList);
  }
  function updateProfession(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setFilterData((prev) => {
      return {
        ...prev,
        profession: value,
      };
    });

    setProfession(selectedList);
  }
  function updateCity(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });
    setFilterData((prev) => {
      return {
        ...prev,
        city: value,
      };
    });

    setCity(selectedList);
  }

  console.log("filterOpen", filterOpen);
  function inviteListFilterOpen() {
    setFilterOpen(true);
  }

  function inviteListFilterClose() {
    setFilterOpen(false);
  }

  const inviteListSidebarHeader = () => <div>ALL FILTERS</div>;

  const inviteListSidebarFooter = () => (
    <div style={{ display: "flex", gap: 10 }}>
      <div>
        <PrimaryCta
          invert
          onClick={() => clearFilterFun()}
          fontSize={"font_size_large"}
          text={"Clear Filter"}
          isLoading={""}
        />
      </div>
      <div>
        <PrimaryCta
          fifth
          onClick={() => getBidList()}
          fontSize={"font_size_large"}
          text={"Apply Filter"}
          isLoading={""}
          className="blue-button"
        />
      </div>
    </div>
  );
  function inviteListFilter() {
    return (
      <Sidebar
        isOpen={filterOpen}
        onClose={inviteListFilterClose}
        Header={inviteListSidebarHeader}
        Footer={inviteListSidebarFooter}
        headerAlignment="left"
        sidebarWidth={"30%"}
      >
        {/* <div className="">
          <div style={{ gap: 5 }} className="m-b-5 flex-row">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Engagement Rate"}
              textAlign={"text_align_left"}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 20,
            }}
          >
            <div>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Min Rate"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  // value={searchBio}
                  placeholder="Choose Min Rate"
                  // onChange={(e) => {
                  //   setSearchBio(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Max Rate"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  // value={searchBio}
                  placeholder="Choose Max Rate"
                  // onChange={(e) => {
                  //   setSearchBio(e.target.value);
                  // }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="m-t-20">
          <div style={{ gap: 5 }} className="m-b-5 flex-row">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Avg Views"}
              textAlign={"text_align_left"}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 20,
            }}
          >
            <div>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Min Views"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  // value={searchBio}
                  placeholder="Choose Min Views"
                  // onChange={(e) => {
                  //   setSearchBio(e.target.value);
                  // }}
                />
              </div>
            </div>
            <div>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Max Views"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  // value={searchBio}
                  placeholder="Choose Max Views"
                  // onChange={(e) => {
                  //   setSearchBio(e.target.value);
                  // }}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="">
          <div className="m-b-5">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Profession"}
              textAlign={"text_align_left"}
            />
          </div>
          <Multiselect
            placeholder="Select profession"
            id="css_custom"
            selectedValues={profession}
            options={FilterOutSubCategory()}
            onSelect={(selectedList, selectedItem) =>
              updateProfession(selectedList, selectedItem)
            }
            onRemove={(selectedList, selectedItem) =>
              updateProfession(selectedList, selectedItem)
            }
            singleSelect={false}
            displayValue={"name"}
            showArrow={false}
            style={{
              chips: Style.chips,
              option: Style.option,
              multiselectContainer: Style.multiselectContainerFilter,
              searchBox: Style.searchBox,
            }}
          />
        </div>

        <div className="m-t-20">
          <div className="m-b-5">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Location"}
              textAlign={"text_align_left"}
            />
          </div>
          <Multiselect
            placeholder="Select location"
            id="css_custom"
            options={getLocations(
              CampaignData.CITY,
              JSON.parse(genderLocation)
            )}
            selectedValues={state}
            onSelect={(selectedList, selectedItem) =>
              updateState(selectedList, selectedItem)
            }
            onRemove={(selectedList, selectedItem) =>
              updateState(selectedList, selectedItem)
            }
            singleSelect={false}
            displayValue={"label"}
            showArrow={false}
            style={{
              chips: Style.chips,
              option: Style.option,
              multiselectContainer: Style.multiselectContainerFilter,
              searchBox: Style.searchBox,
            }}
          />
        </div>

        {genderFilter == "all" && (
          <div style={{ textAlign: "left" }} className="m-t-20">
            <div className="m-b-5">
              <CustomText
                p
                fontSize={"font_size_medium"}
                textColor={"text_color_primary"}
                text={"Gender"}
                textAlign={"text_align_left"}
              />
            </div>

            <Select
              multi={false}
              options={options}
              values={influencerGender}
              onChange={(value) => updateGender(value)}
              placeholder="Select gender"
              autoFocus={false}
              color="#c8c2c2"
              // Customcolor
              style={{
                border: "1px solid #b3b3b6",
                backgroundColor: "#fff",
                borderRadius: 4,
                paddingBottom: "9px",
                paddingTop: "9px",
                option: {
                  backgroundColor: "red",
                },
                color:"#B3B3B6"
              }}
            />
          </div>
        )}
        
      </Sidebar>
    );
  }

  function openModalCollection() {
    if (modalIsOpenCollection == false) {
      setModalIsOpenCollection(true);
    } else {
      setModalIsOpenCollection(false);
    }
  }

  function closeModalCollection() {
    setModalIsOpenCollection(false);
  }
  function openModalCollectionGrid() {
    if (modalIsOpenCollectionGrid == false) {
      setModalIsOpenCollectionGrid(true);
    } else {
      setModalIsOpenCollectionGrid(false);
    }
  }

  function closeModalCollectionGrid() {
    setModalIsOpenCollectionGrid(false);
  }

  const handleMouseEnterCollection = (id) => {
    setIsHovered(true);
  };

  const handleMouseLeaveCollection = () => {
    setIsHovered(false);
  };

  function hoveredButton() {
    return (
      <button
        onClick={openModalCollectionGrid}
        className={`button-hover-show-btn ${
          showCollectionBtn ? "hidden-hover-show-btn" : ""
        }`}
      >
        Shortlist
      </button>
    );
  }

  const influencerTableSchema = [
    {
      name: "Influencer Name",
      selector: (row) => row["influencer_data"]["name"],

      cell: (data) => (
        <span
          onClick={(e) => {
            toggleFlyoutDropdown();
            setInfluencerId(data?.influencer_data.influencer_id);
          }}
          style={{ display: "flex", gap: 8, alignItems: "center" }}
        >
          <Image
            source={
              data.influencer_data.profile_picture
                ? Utils.generateInfluencerDpUrl(
                    data.influencer_data.influencer_id,
                    data.influencer_data.profile_picture
                  )
                : require("../../resources/images/user-copy.png")
            }
            className="proposal-user-profile"
            fallBack={require("../../resources/images/user-copy.png")}
          />
          <div style={{ flexDirection: "row", display: "flex", gap: 5 }}>
            <div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  gap: 5,
                  alignItems: "baseline",
                }}
              >
                <CustomText
                  p
                  text={data.influencer_data.name}
                  className="Body-1"
                />
                {/* <div>
                  <CustomText
                    p
                    text={`(${data.influencer_data.ratings.toFixed(1)})`}
                    className="typographyH5"
                  />
                </div> */}
              </div>
              <CustomText
                p
                text={getInflencerLocation(data?.influencer_data)}
                fontSize={"font_size_extraSmall"}
                fontWeight={"rubik_regular"}
                textColor={"text_color_primary"}
                textAlign={"text_align_left"}
              />
            </div>
          </div>
        </span>
      ),
      width: "250px",
    },
    {
      name: "Platform",
      selector: (row) =>
        platFormName(row.platforms_data, row.influencer_data.influencer_id),
      // sortable: true,
     
    },
    {
      name: "Followers",
      selector: (row) => formatPlatformData(row.platforms_data, "followers"),

      
    },
    // {
    //   name: "Like",
    //   selector: (row) => formatPlatformData(row.platforms_data, "avg_likes"),
    //   width: "90px",
    // },
    // {
    //   name: "Comment",
    //   selector: (row) => formatPlatformData(row.platforms_data, "avg_comments"),
    //   width: "90px",
    // },
    {
      name: "Avg Views",
      selector: (row) =>
        Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(row.platforms_data, "avg_views")
        )
          ? "-"
          : formatPlatformData(row.platforms_data, "avg_views"),
      
    },
    {
      name: "ER",
      selector: (row) =>
        Utils.isNullOrUndefinedOrZero(
          formatPlatformData(row.platforms_data, "engagement_rate")
        )
          ? "-"
          : formatPlatformData(row.platforms_data, "engagement_rate"),
      cell: (data) => (
        <span>
          <Tooltip
            title={
              <>
                <div>
                  Avg Likes ={" "}
                  {formatPlatformData(data.platforms_data, "avg_likes")}
                </div>
                <div>
                  Avg Comments ={" "}
                  {formatPlatformData(data.platforms_data, "avg_comments")}
                </div>
              </>
            }
          >
            {Utils.isNullOrUndefinedOrZero(
              Number(formatPlatformData(data.platforms_data, "engagement_rate"))
            )
              ? "-"
              : Number(
                  formatPlatformData(data.platforms_data, "engagement_rate")
                ).toFixed(2) + " %"}
          </Tooltip>
        </span>
      ),
    },

    {
      name: "",
      lastCol: true,
      conditionalCellStyles: [
        {
          when: (row) => true,
          style: {
            justifyContent: "center",
          },
        },
      ],
      cell: (data) => (
        <snap
          
        >
          
          {!disableShortListedBtn(data.influencer_data.influencer_id) ? (
            <div
              
              style={{ textWrap: "nowrap", fontSize: "bold", width: "120px" }}
            > 
            <PrimaryCta 
            fifth
            className="blue-button"
            text={"Shortlist"}
            onClick={() => {
              setHoveredId(data.influencer_data.influencer_id);
              openModalCollectionGrid();
            }}
            />
              
            </div>
          ) : (
            <div
              onClick={() => {
                //toast.info("Campaign is not active");
              }}
              style={{ textWrap: "nowrap", fontSize: "bold", width: "120px" }}
            >
              <PrimaryCta 
            fifth
            disabled
            className="blue-button"
            text={"Shortlisted"}
            />
            </div>
          )}
        </snap>
      ),

      // sortable: true,
    },
    // {
    //   name: "",
    //   maxWidth: "10px",
    //   cell: (data) => (
    //     <span
    //       style={{ paddingLeft: 10, paddingRight: 10 }}
    //       onClick={() => {
    //         setShowCollectionBtn(!showCollectionBtn);
    //         setHoveredId(data.influencer_data.influencer_id);
    //       }}>
    //       <img src="../assets/images/dots.png" height={15} alt="" srcset="" />{" "}
    //       {data.influencer_data.influencer_id == hoveredId && hoveredButton()}
    //     </span>
    //   ),
    // },
  ];

  const openModalRepost = () => {
    if (repostModal == false) {
      setRepostModal(true);
    } else {
      setRepostModal(false);
    }
  };

  const closeModalRepost = () => {
    setRepostModal(false);
  };

  function renderFallback() {
    if (!loading && campaignStatus == "COMPLETED") {
      return (
        <>
          <div className="fall-back-center-campaign">
            <FallBackBtn
              heading="Campaign Completed"
              title="Your campaign got completed or expired"
              text="Repost Job"
              onClick={openModalRepost}
            />
          </div>
        </>
      );
    } else if (!loading && campaignStatus == "IN_REVIEW") {
      return (
        <div className="fall-back-center-campaign">
          <FallBack
            heading={"Your campaign is under review"}
            title={
              "You will start receiving proposals as soon as it gets reviewed"
            }
          />
        </div>
      );
    } else if (!loading && influencerList?.length == 0) {
      return (
        <>
          {" "}
          {searchName == null ? (
            <div className="fall-back-center-campaign">
              <FallBack
                heading={"No influencer available"}
                title={"No influencer in your demographic criteria."}
              />
            </div>
          ) : (
            <div className="fall-back-center-campaign">
              <SearchFallBack
                heading={"Your search did not match any results."}
                title={"Make sure all words are spelled correctly"}
                subTitle={"Try different keywords"}
              />
            </div>
          )}
        </>
      );
    }
  }
  function renderLoader() {
    return (
      <>
        <div className="fall-back-center-campaign">
          <Loader />
        </div>
      </>
    );
  }

  function renderInviteList() {
    return (
      <div className="custom-table-bid">
        <CustomTable
          className="CustomTable"
          tableSchema={influencerTableSchema}
          paginationPerPage={10}
          tableData={influencerList ? influencerList : []}
          rowPaddingTop={16}
          rowPaddingBottom={16}
          customStyles={customBidStyles}
          // conditionalRowStyles={conditionalRowStyles}

          paginationTotalRows={totalRows}
          // onChangeRowsPerPage={getInfluencerListChange}
          onChangePage={handlePageChange}
          // onRowClicked={(item) =>
          //   CampaignClick(item.campaign_data.campaign_id)
          // }
          noDataComponent={renderFallback()}
          progressComponent={renderLoader()}
          progressPending={loading}
          paginationServer
          pagination
        />
      </div>
    );
  }

  const platFormName = (platforms_data, influencer_id) => {
    const handleMouseEnter = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = true;
      setHoveredIcons(newHoveredIcons);
    };

    const handleMouseLeave = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = false;
      setHoveredIcons(newHoveredIcons);
    };

    const platformData = platforms_data.find(
      (item) => item.platform_id === platformId
    );

    const handlePlatformClick = (url) => {
      // Open the platform URL here
      window.open(url, "_blank");
      // Reset all hover states to false after clicking
      setHoveredIcons(new Array(platforms_data.length).fill(false));
    };

    return (
      <>
        {platforms_data.length > 0 &&
          platformData &&
          platforms_data[0].platform_id != null && (
            <div>
              {/* <CustomText
              p
              text={"Social Presence"}
              fontSize={"font_size_small"}
              textColor={"text_color_black"}
              fontWeight={"Poppins_semiBold"}
            /> */}
            </div>
          )}
        <div style={{ marginTop: 7 }} className="flex-row">
          <div
            className={"platform-name"}
            onMouseEnter={() =>
              handleMouseEnter(`${influencer_id}${platformData?.platform_id}`)
            }
            onMouseLeave={() =>
              handleMouseLeave(`${influencer_id}${platformData.platform_id}`)
            }
            onClick={() =>
              handlePlatformClick(
                Utils.getSocialUrl(
                  platformData?.platform_id,
                  platformData?.identifier_id
                )
              )
            }
          >
            {platformData?.platform_id && (
              <Icon
                iconName={
                  hoveredIcons[`${influencer_id}${platformData?.platform_id}`]
                    ? Utils.getSocialIconHover(platformData?.platform_id)
                    : Utils.getSocialIcon(platformData?.platform_id)
                }
                height={14}
                width={14}
              />
            )}
          </div>
          {/* {platforms_data.map((item, index) => {
            if (Utils.getSocialIcon(item.platform_id)) {
              return (
                <>
                  <div
                    key={index}
                    className={"platform-name"}
                    data-tooltip-id={`tooltip-${influencer_id}${item.platform_id}`}
                    onMouseEnter={() =>
                      handleMouseEnter(`${influencer_id}${item.platform_id}`)
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(`${influencer_id}${item.platform_id}`)
                    }
                    onClick={() =>
                      handlePlatformClick(
                        Utils.getSocialUrl(item.platform_id, item.identifier_id)
                      )
                    }
                  >
                    <Icon
                      iconName={
                        hoveredIcons[`${influencer_id}${item.platform_id}`]
                          ? Utils.getSocialIconHover(item.platform_id)
                          : Utils.getSocialIcon(item.platform_id)
                      }
                      height={14}
                      width={14}
                    />
                  </div>
                </>
              );
            }
            return null;
          })} */}
        </div>
      </>
    );
  };

  function influencerDetail(influencer_id) {
    const url = `/influencer/${influencer_id}`;
    window.open(url, "_blank");
  }

  function getInflencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]?.n;
    } else {
      return "-";
    }
  }

  function filterDropDownOpenClose() {
    if ((sortBy != null) & (sortOrder != null)) {
      setSortBy(null);
      setSortOrder(null);
      setFilterLabel(null);
    } else {
      SortDropDown();
    }
  }

  function SortDropDown() {
    if (filterDropDown) {
      setFilterDropDown(false);
    } else {
      setFilterDropDown(true);
    }
  }

  if (!props.isActive) {
    return <></>;
  }

  return (
    <div style={{ minHeight: 500 }}>
      <>
        <div className="bid-list-dropdown-header-container">
          <div>
            {influencerList && (
              <div
                style={{ marginTop: 12 }}
                className="flex-row justify-between align-center"
              >
                {/* <CustomText
                  p
                  text={"Top "}
                  fontSize={"font_size_regular"}
                  textColor={"text_color_primary"}
                  fontWeight={"Poppins_regular_medium"}
                />
                &nbsp;
                <CustomText
                  p
                  text={`${start + 1}-${totalRows ? totalRows : 0} 
                   Results`}
                  fontSize={"font_size_regular"}
                  textColor={"text_color_primary"}
                  fontWeight={"Poppins_regular"}
                /> */}
              </div>
            )}
          </div>
          <div className="flex-row" style={{ display: "flex", gap: 5 }}>
            <div className="dropdown-button-container">
              <button
                onClick={() => filterDropDownOpenClose()}
                className="dropdown-button"
              >
                <CustomText
                  p
                  text={filterLabel ? filterLabel : "Sort by"}
                  fontSize={"font_size_regular"}
                  textColor={"text_color_black"}
                  textAlign={"text_align_right"}
                />
                {filterLabel ? (
                  <div style={{width:10}}>
                    <Icon iconName="crossCloseIcon" height={10} width={10} />
                  </div>
                ) : (
                  <div className="cursor-pointer">
                    <Icon iconName={"DropdownIcon"} height={18} width={18} />
                  </div>
                )}
              </button>
              {filterDropDown && (
                <div className="dropdown-list-container-invite">
                  {sortTypes.map((item) => {
                    return (
                      <div
                        onClick={() => {
                          setNext(0);
                          setSortBy(item.sortBy);
                          setSortOrder(item.sortOrder);
                          SortDropDown();
                          setFilterLabel(item.label);
                        }}
                        className={
                          item.sortBy == sortBy && item.sortOrder == sortOrder
                            ? "dropdown-button-list-selected"
                            : "dropdown-button-list"
                        }
                      >
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div
              className="filter-icon-btn-invite"
              onClick={() => inviteListFilterOpen()}
            >
              <Icon iconName="filterIconNew" />
            </div>
          </div>
        </div>
        {<>{renderInviteList()}</>}
        {/* {campaignStatus == "ACTIVE" ? (
          <>{renderInviteList()}</>
        ) : (
          <div className="fall-back-center-campaign">
            <FallBack
              heading={"No influencer found"}
              title={"No influencer matching your requirements."}
            />
          </div>
        )} */}

        {/* {isLoading ? (
          <div className="fall-back-center-campaign">
            <Loader />
          </div>
        ) : (
          <>
            {campaignDetail?.campaign_data?.status !== "IN_REVIEW" ? (
              <div>
                <>
                  {isLoading && influencerList.length == 0 ? (
                    <div className="fall-back-center-campaign">
                      <FallBack
                        heading={"No influencer available"}
                        title={"No influencer in your demographic criteria."}
                      />
                    </div>
                  ) : (
                    <div className="custom-table-bid">
                      <CustomTable
                        className="CustomTable"
                        tableSchema={influencerTableSchema}
                        paginationPerPage={15}
                        tableData={
                          influencerList.length == 0 ? [] : influencerList
                        }
                        rowPaddingTop={16}
                        rowPaddingBottom={16}
                        customStyles={customBidStyles}
                        conditionalRowStyles={conditionalRowStyles}
                        progressPending={isLoading}
                        // onChangePage={() => getInfluencerList(next, updateProgress)}
                        // onRowClicked={(item) =>
                        //   CampaignClick(item.campaign_data.campaign_id)
                        // }
                        progressComponent={
                          <div className="fall-back-center-campaign">
                            <Loader />
                          </div>
                        }
                        pagination
                      />
                    </div>
                  )}
                </>
              </div>
            ) : (
              <div className="fall-back-center-campaign">
                <FallBack
                  heading={"No influencer available "}
                  title={"No influencer in your demographic criteria."}
                />
              </div>
            )}
          </>
        )} */}
      </>

      {/* {modalIsOpenCollectionGrid && (
        <CreateCollectionGrid
          closeModal={closeModalCollectionGrid}
          id={hoveredId}
          campaignId={campaignId}
        />
      )} */}
      {
        <InfluencerDetailModal
          influencerFlyOut={influencerFlyOut}
          toggleFlyoutDropdown={toggleFlyoutDropdown}
          influencer_id={influencerId}
          org_id={brand_deep_data.brand_data.org_id}
        />
      }
      {repostModal && (
        <RepostJobOptionModal
          handleUpdateCampaign={props.handleUpdateCampaign}
          campaignDetail={props.campaignDetail}
          campaignId={campaignId}
          closeModalRepost={closeModalRepost}
        />
      )}

      {modalIsOpenCollectionGrid && (
        <CreateCampaignCollection
          closeModal={closeModalCollectionGrid}
          influencer_id={hoveredId}
          campaignId={campaignId}
          selectedCollectionId={props.selectedCollectionId}
          org_id={brand_deep_data.brand_data.org_id}
          getCollectionInfluencerList={getCollectionInfluencerList}
        />
      )}
      {inviteListFilter()}
    </div>
  );
}

export default InviteList;
