import React from "react";
import {
  CustomTextInput,
  PrimaryCta,
  CustomText,
  Image,
  UnderLinedLinkCta,
} from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useState } from "react";
import Core from "../../common/clientSdk";
import validator from "validator";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useSnackbar } from "react-simple-snackbar";
import LeftSideSignup from "../authentication/leftSideSignup";
import Utils from "../../common/utils";
import OtpInput from "react-otp-input";
function VerifyEmailOtpScreen() {
  const [loading, setLoading] = useState(false);
  const [emailOtp, setEmailOtp] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [openSnackbar] = useSnackbar();

  const [error, set_error] = useState({
    otp: "",
  });

  var navigate = useNavigate();

  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { user_data = {} } = BrandUser;

  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;

  function postVerifyEmailOtpOrg(progress_cb) {
    const params = {
      verification_code: emailOtp,
    };
    Core.postVerifyEmailOtpOrg(
      postVerifyEmailOrgOtpSuccess,
      postVerifyEmailOrgOtpFailed,
      progress_cb,
      params
    );
  }

  function postVerifyEmailOrgOtpSuccess(response_data) {
    const successMessage = "Email verified Successfully";
    showError(successMessage);
    setLoading(false);
    navigate("/settings");
  }

  function postVerifyEmailOrgOtpFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function postVerifyEmailOrg() {
    const params = {};
    Core.postVerifyEmailOrg(
      postVerifyEmailOrgSuccess,
      postVerifyEmailOrgFailed,
      () => {},
      params
    );
  }

  function postVerifyEmailOrgSuccess(response_data) {
    openSnackbar("Send Otp");
  }

  function postVerifyEmailOrgFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
    openSnackbar("Send Otp Fail");
  }

  function updateProgress(status) {
    setLoading(status);
  }
  function validateName(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, otp: "Please enter valid otp" });
    } else {
      set_error({ ...error, otp: "" });
      return true;
    }
  }

  function showError(message) {
    openSnackbar(message);
  }

  const sendOtp = (otp) => {
    setEmailOtp(otp);
  };
  return (
    <div className="main-container background-color-primary">
      <div className="content-container">
        <LeftSideSignup />

        <div className="auth-right-container">
          <div className="inner-left-container">
            <div className="inner-left-container-bind">
            <Image source={"../assets/images/Reelax.png"}
              />
              <div className=" flex-row">
                <CustomText
                  h1
                  fontSize={"font_size_h1"}
                  text={`Verify Email Address`}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                />
              </div>

              <div className="m-t-16">
                <OtpInput
                  className="otp-box"
                  inputStyle={{
                    height: "54px",
                    width: "54px",
                    borderRadius: "8px",
                    border: "1px solid #4A4A4A",
                    background: "#E6E7E8",
                    color: "#3A3B43",
                    marginRight: "5px",
                  }}
                  value={emailOtp}
                  onChange={sendOtp}
                  numInputs={6}
                  // type="number"
                  // renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>

              <div className="m-t-32 full-width">
                <PrimaryCta
                  fontSize={"font_size_large"}
                  fullWidth={true}
                  text={"Verify"}
                  disabled={Utils.isNullOrUndefined(emailOtp)} // || !sent_notification
                  isLoading={loading}
                  onClick={() => {
                    postVerifyEmailOtpOrg(updateProgress);
                  }}
                />
              </div>
              <div className="resent-email m-t-16">
                <CustomText
                  p
                  fontSize={"font_size_small"}
                  text={`Didn’t received the code?`}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                />

                <UnderLinedLinkCta
                  fontSize={"font_size_small"}
                  textColor={"text_color_register"}
                  text={"Click to resend"}
                  onClick={() => postVerifyEmailOrg()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmailOtpScreen;
