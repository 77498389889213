import React from "react";
import { useState } from "react";
import {
  CustomTextInput,
  H1,
  PrimaryCta,
  Image,
  CustomText,
  CustomTextInput480Width,
} from "../../components";
// Core Sdk (Private Scope)
//import Core from '../common/clientSdk.js';
import Utils from "../../common/utils.js";
import validator from "validator";
import { Link, useNavigate } from "react-router-dom";
import Core from "../../common/clientSdk";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import LeftSideSignup from "./leftSideSignup.js";
import NewLoginLeftSide from "./newLoginLeftSide.js";

const ForgotPassword = function () {
  const [loading, set_loading] = useState(false);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState({ email: "" });
  const [openSnackbar, closeSnackbar] = useSnackbar();

  var navigate = useNavigate();

  function validateEmail(value) {
    if (!validator.isEmail(value, {})) {
      setError({ ...error, email: "Please enter valid email" });
    } else {
      setError({ ...error, email: "" });
      return true;
    }
  }

  /********************************************************************
  Forgot password

  @param {Function} progress_cb - manage status of screen

  No return
  *********************************************************************/

  function forgotPassword(progress_cb) {
    var params = {
      email: email,
    };
    if (validateEmail(email)) {
      Core.postForgotPassword(
        forgotPasswordSuccess,
        forgotPasswordFailed,
        progress_cb,
        params
      );
    }
  }

  function forgotPasswordSuccess(response_data) {
    openSnackbar("OTP sent to provided email");
    OtpVerifyScreen(response_data.response_data.request_id);
  }

  function forgotPasswordFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }
  // function forgotPassword(progress_cb) {
  //   var params = {
  //     user_data: {
  //       email: work_email,
  //     },
  //   };

  //   if (validationHandler(params)) {
  //     console.log("it is an Email");
  //   } else {
  //     console.log("it is not an Email");
  //   }
  //   // Core.forgotPaswword(

  //   //   //progress_cb,
  //   //   //params

  //   // )
  // }

  /********************************************************************
  Screen Progress

  @param {Boolean} status - true/false

  No return
  *********************************************************************/
  function updateProgress(status) {
    set_loading(status);
  }

  function OtpVerifyScreen(request_id) {
    navigate(`/verify-email?request_id=${request_id}&email=${email}`);

    // navigate.push({
    //   pathname: "/verify-email",
    //   query: { request_id: request_id, email: email },
    // });

    // navigate(`/verify-email/${request_id}`);
  }

  return (
    <div className="forgot-password-screen">
      <div className="forgot-password-left-side">
        <NewLoginLeftSide />
      </div>
      <div className="forgot-password-right-side">
        <div className="inner-left-container-forget">
          <div className="forgot-password-reelax-logo">
            <Image source={"../assets/images/Reelax.png"} />
          </div>
          <div className="forgot-password-top-container">
            <div className="flex-row">
              <CustomText
                h1
                fontSize={"font_size_h1"}
                text={`Reset Password`}
                textColor={"text_color_primary"}
                fontWeight={"rubik_regular"}
              />
              &nbsp;
            </div>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <CustomText
                p
                fontSize={"font_size_small"}
                text={`Please enter your registered email address and we will send you an OTP to generate a new password`}
                textColor={"text_color_primary"}
                fontWeight={"rubik_regular"}
              />
              <div style={{ textAlign: "center" }}>
                {/* <CustomText
                  p
                  
                  fontSize={"font_size_small"}
                  text={` generate new password`}
                  textColor={"text_color_primary"}
                  fontWeight={"rubik_regular"}
                /> */}
              </div>
            </div>

            <div className="m-t-16 ">
              <CustomText
                h5
                fontSize={"font_size_large"}
                text={`Email`}
                textColor={"text_grey_400_color"}
                fontWeight={"Poppins_regular_large"}
                height={"line_height_19_2"}
              />
              <div className="forgot-password-textfield ">
                <CustomTextInput
                  placeholder="Enter Email"
                  height="51px"
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={(e) => validateEmail(e.target.value)}
                />
              </div>
              <h5 className="error">{error.email}</h5>
            </div>

            <div className="forgot-password-textfield">
              <PrimaryCta
               
                className="forgot-password-button"
                fontSize={"font_size_large"}
                // fullWidth={true}
                text={"Reset Password"}
                disabled={Utils.isNullOrUndefined(email)} // || !sent_notification
                isLoading={loading}
                onClick={() => {
                  forgotPassword(updateProgress);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
