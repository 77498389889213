import React, { useEffect, useState, useLayoutEffect } from "react";
import { useSnackbar } from "react-simple-snackbar";
import {
  CustomText,
  Image,
  CustomButton,
  PrimaryCta,
  FallBack,
  SubscriptionBuyButton,
  ModalComponent,
  CustomTextInput,
  CustomCheckBoxCircularRequestPrice,
  SocialMediaCardsRequestPrice,
  CustomRadioButton,
  Popup,
} from "../../components";
import Core from "../../common/clientSdk";
import Utils from "../../common/utils";
import { Loader } from "../loader";
import Icon from "../../components/core_component/icon";
//import { Tooltip as ReactTooltip } from "react-tooltip";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/customTable";
import * as CampaignData from "../../common/data/campaign";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-dropdown-select";
import Style from "../../resources/style";
import { toast } from "react-toastify";
import CreateCollectionGrid from "../collection/collectionGrid";
import SideMenu from "../sideMenu";
import Header from "../header";
import fi from "date-fns/esm/locale/fi/index.js";
import InfluencerDetailModal from "../../modal/influencerDetialModal";
import SubscriptionModal from "../../modal/subscriptionModal";
import contentList from "./../../resources/data/content_data.json";
import { useLocation } from "react-router-dom";
import RichTextEditor from "react-rte";
import styled from "@emotion/styled";
import { Sidebar } from "../../components";
import AddInfluencerSidebar from "../../modal/addInfluencerSidebar";
const options = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "all", label: "All" },
];

const verifyOrNot = [
  { value: 1, label: "Verified" },
  { value: 0, label: "All" },
];
const inviteOption = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];
const followers = [
  { value: "1000", label: "Nano    1K - 10k " },
  { value: "10000", label: "Micro  10k - 50k " },
  { value: "50000", label: "Mid Tier  50k - 2.5L" },
  { value: "250000", label: "Macro  2.5L - 10L" },
  { value: "1000000", label: "Top Tier  1M+ " },
];
const customBidStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--general-secondary-color)", // Change header background color
      color: "var(--gray-gray-300)", // Change header text color
      fontSize: "14px",
      fontWeight: 600,
      lineHight: "19.2px",
      border: "none",
    },
  },
  headCells: {
    style: {
      padding: "10px 0px 10px 20px",
    },
  },
  rows: {
    style: {
      cursor: "pointer",
      fontSize: "12px",
      paddingTop: "10px",
      paddingBottom: "10px",
      fontWeight: 400,
      lineHight: "19.2px",
      "&:hover": {
        backgroundColor: "var(--general-secondary-color)",
        fontWeight: 600,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
    },
  },
};
const sortTypes = [
  {
    sortBy: "followers",
    sortOrder: "asc",
    label: "Followers - Low to High",
  },
  {
    sortBy: "followers",
    sortOrder: "desc",
    label: "Followers - High to Low",
  },
];
const platformTypes = [
  {
    platformName: "btll",
    label: "Instagram",
  },
  {
    platformName: "nxjo",
    label: "Youtube",
  },
];
const toolbarConfig = {
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
  HISTORY_BUTTONS: [{}],
  LINK_BUTTONS: [{}],
};
const limit = 10;
function InfluencerListTable() {
  const [isLoading, setLoading] = useState(false);
  const [modalIsOpenCollection, setModalIsOpenCollection] = useState(false);
  const [modalIsOpenCollectionGrid, setModalIsOpenCollectionGrid] =
    useState(false);
  const [isInviteLoading, setInviteLoading] = useState({});
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(10);
  const [minFollower, setMinFollower] = useState([]);
  const [openModal3, setOpenModal3] = useState(false);
  const [addInfluencerLoading, setAddInfluencerLoading] = useState(false);
  const [openSnackbar] = useSnackbar();
  const [hoveredIcons, setHoveredIcons] = useState([]);
  const [totalRows, setTotalRows] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [filterLabel, setFilterLabel] = useState("");
  const [platformLabel, setPlatformLabel] = useState("Instagram");
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [platformDropDown, setPlatformDropDown] = useState(false);
  const [platformId, setPlatformId] = useState("btll");
  const [influencerList, setInfluencerList] = useState([]);
  const [selectedInfluencerRows, setSelectedInfluencerRows] = useState([]);
  const [influencerInviteList, setInfluencerInviteList] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [searchBio, setSearchBio] = useState(null);
  const [addToCollectionLoading, setAddToCollectionLoading] = useState(false);
  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [influencerId, setInfluencerId] = useState(null);
  const [page, setPage] = useState(1);
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [influencerLanguage, setInfluencerLanguage] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [subscriptionSelected, setSubscriptionSelected] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [subscriptionList, setSubscriptionList] = useState(null);
  const [profession, setProfession] = useState([]);
  const [invitedMark, setInvitedMark] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [influencerGender, setInfluencerGender] = useState([]);
  const [influencerContact, setInfluencerContact] = useState({});

  const [showContract, setShowContract] = useState([]);
  const [showPlatform, setShowPlatform] = useState("");
  const [verifiedOrNot, setVerifiedOrNot] = useState([]);
  const [updateCollectionData, setUpdateCollectionData] = useState(null);
  const [clearFilter, setClearFilter] = useState(false);
  const [category, setCategory] = useState([]);
  const [hoveredId, setHoveredId] = useState(null);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [showCollectionBtn, setShowCollectionBtn] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [collectionInfluencerList, setCollectionInfluencerList] = useState([]);
  const [collectionData, setCollectionData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilterFlag, setShowFilterFlag] = useState(false);
  const [requestPriceModalOpen, setRequestPriceModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [selectedPlatform, setSelectedPlatform] = useState({});
  const [title, setTitle] = useState("");
  const [triggeredBy, setTriggeredBy] = useState(null);
  const [instructionNote, setInstructionNote] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [autoUpdate, setAutoUpdate] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [campaignDeepData, setCampaignDeepData] = useState({
    campaign_data: {
      type: "",
      brand_id: "",
      product_price: 0,
      end_date: 0,
      delivery_date: 0,
    },

    content_type_deep_data: " ",

    reward_data: {
      reward_by_follower: {
        below_5k: "",
        above_5k_below_10k: "",
        above_10k_below_25k: "",
        above_25k_below_1m: "",
      },
      type: " ", //check
    },

    influencer_data: {
      minimum_followers: 0, //check
      categories: [" "], //check
    },

    sample_content_data: [],

    audience_data: {
      age: [], //check
      gender: [""], //check
      language: [""], //check
      state: [""], //check
    },
  });
  const instructionNoteText = instructionNote.toString("html");
  const [requestPriceLoading, setRequestPriceLoading] = useState(false);
  const [addInfluencer, setAddInfluencer] = useState(false);
  const location = useLocation();
  const { categoryId } = location.state || {};
  const locationId = location.state?.locationId;
  console.log("categoryId", categoryId);
  console.log("locationId", locationId);

  const [filterData, setFilterData] = useState({
    gender: null,
    city: [],
    state: locationId ? [locationId] : [],
    follower: [],
    category: categoryId ? [categoryId] : [],
    profession: [],
    language: [],
    counter: null,
    invite_mark: null,
    verified: null,
    contact: [],
  });

  console.log("statewsfdsasd", state);

  const hasContact = [
    {
      value: "has_email",
      result: true,
      label: "Email address available",
      id: "has_email",
    },
    {
      value: "has_phone_number",
      result: true,
      label: "Phone number available",
      id: "has_phone",
    },
  ];
  let subCategoryAll = Object.values(
    CampaignData.FULL_CATEGORIES.subcategories
  ).flat();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // If screen width is less than or equal to 768px, consider it mobile
  };

  console.log("stateasdasdas", state);

  // Trigger API call whenever filterData is updated
  useEffect(() => {
    if (locationId) {
      const stateObj = CampaignData["CITY"].find(
        (city) => city.id === locationId
      );
      setState([stateObj]);
      //setShowFilterFlag(true)
    }
    if (categoryId) {
      let catObj = Object.values(CampaignData.FULL_CATEGORIES.CATEGORYS).find(
        (cat) => cat.value === categoryId
      );
      setCategory([catObj]);
      //setShowFilterFlag(true)
    }
  }, []);

  useEffect(() => {
    // Add an event listener to handle resize
    window.addEventListener("resize", handleResize);

    // Set initial value
    handleResize();

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getCampaignPlatform(contentList);
    contentList.map((item) => {
      selectedPlatform[item.platform_data.platform_id] = [];
    });
  }, [contentList]);

  // useEffect(() => {
  //   if (platformId) {
  //     getInfluencerDiscoveryList(1, updateProgress);
  //   }
  // }, [platformId || !showFilterFlag]);

  // useEffect(() => {

  //   getInfluencerDiscoveryList(1, updateProgress);

  // }, []);

  useEffect(() => {
    //if(showFilterFlag){

    if (!filterOpen && platformId) {
      getInfluencerDiscoveryList(1, updateProgress);
    }
  }, [platformId, filterOpen]);

  // useEffect(() => {
  //   if (showFilterFlag) {
  //     getInfluencerDiscoveryList(1, updateProgress);
  //   }
  // }, [profession.length]);

  useEffect(() => {
    if (updateCollectionData) {
      getCollectionInfluencerList();
    }
  }, [updateCollectionData]);

  var redux_dispatch = useDispatch();
  const navigate = useNavigate();

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { org_deep_data = {} } = BrandUser;

  console.log("org_deep_data", brand_deep_data);

  function updateProgress(status) {
    setLoading(status);
  }

  function updateTableProgress() {}

  let is_subscribe = false;

  function getInfluencerDiscoveryList(page, progressCb) {
    //inviteListFilterFun();

    let newPage = 0;
    if (page == 10) {
      newPage = 0 * Number(page);
    } else {
      newPage = 10 * Number(page) - 10;
    }

    console.log("newPage", newPage);

    setListLoading(true);
    let filter_data = {
      minimum_followers: Number(filterData.follower),
      location: filterData.state,
      gender: filterData.gender,
      categories: filterData?.category,
      profession: filterData.profession,
      language: filterData?.language,
      is_invited: filterData?.invite_mark?.toString(),
      search_text: searchBio,
      is_verified: filterData.verified,
      has_email: influencerContact?.has_email,
      has_phone_number: influencerContact?.has_phone,
    };
    var params = {
      platform_id: platformId ? platformId : "btll",
      org_id: brand_deep_data?.brand_data?.org_id,
      start: newPage,
      // limit: p,
      filter_data: JSON.stringify(filter_data),
    };

    if (sortBy != null) {
      params.sort_by = sortBy;
    }
    if (sortOrder != null) {
      params.sort_order = sortOrder;
    }
    Core.getInfluencerDiscovery(
      getInfluencerDiscoveryListSuccess,
      getInfluencerDiscoveryListFailed,
      progressCb,
      params
    );
  }

  function getInfluencerDiscoveryListSuccess(response_data) {
    if (response_data && response_data?.influencer_list?.data) {
      setInfluencerList([]);
    } else {
      setInfluencerList(response_data?.influencer_list);
    }

    setTotalRows(response_data?.pagination_data?.total_count);
    if (response_data?.influencer_list.data?.length == 0) {
      setTotalRows(0);
    }

    setLoading(false);
  }

  function getInfluencerDiscoveryListFailed(errorList) {
    toast.error(errorList);
    setInfluencerList([]);
    setLoading(false);
  }

  function getCollectionInfluencerList(isLoading) {
    const params = {
      collection_id: updateCollectionData?.collection_id,
    };
    Core.getCollectionInfluencerList(
      getCollectionInfluencerListSuccess,
      getCollectionInfluencerListFailed,
      (...args) => {
        updateProgress(...args);
        if (!isLoading) {
          return;
        }
      },
      params
    );
  }

  function getCollectionInfluencerListSuccess(response_data) {
    setCollectionInfluencerList(response_data.collection_deep_data);
    setCollectionData(response_data?.collection_deep_data?.collection_data);
  }

  function getCollectionInfluencerListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
    setCollectionInfluencerList([]);
  }

  function postAddInfluencerCollection() {
    const params = {
      collection_id: updateCollectionData?.collection_id,
      influencer_id: hoveredId,
    };
    Core.postAddInfluencerCollection(
      postAddInfluencerCollectionSuccess,
      postAddInfluencerCollectionFailed,
      (status) => setAddInfluencerLoading(status),
      params
    );
  }

  function postAddInfluencerCollectionSuccess(response_data) {
    toast.success("Influencer Added Collection Successfully");
    addToCollectionModalClose();
  }

  function postAddInfluencerCollectionFailed(errorList) {
    console.log("errorList", errorList);
    addToCollectionModalClose();
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  // function alreadyAddCollectionInfluencer(id) {
  //   let inviteId = collectionInfluencerList?.collection_influencer_list?.find(
  //     (item) => item.influencer_data?.influencer_id == id
  //   );
  //   return inviteId;
  // }

  function addToCollectionModalOpen() {
    setOpenModal3(!openModal3);
  }
  const addToCollectionModalClose = () => {
    setOpenModal3(false);
  };
  function checkAddToCollectionInfluencer(influencer_id) {
    setHoveredId(influencer_id);
    if (updateCollectionData) {
      addToCollectionModalOpen();
    } else if (!updateCollectionData) {
      handleShortlistClick();
      openModalCollectionGrid();
    }
  }

  const handlePageChange = (page) => {
    console.log("page", page);
    getInfluencerDiscoveryList(page, updateProgress);
  };

  const handleRowSelected = ({ selectedRows }) => {
    let influencer_ids = [];
    selectedRows.forEach(function (influencer_data) {
      let influencer_id = influencer_data.influencer_data.influencer_id;
      influencer_ids.push(influencer_id);
    });
    setSelectedInfluencerRows(influencer_ids);
    console.log("onSelectedRowsChange", selectedRows);
  };
  console.log("selectedRows0", selectedInfluencerRows);

  const toggleSocialMedia = (platform_id) => {
    if (showPlatform === platform_id) {
      setShowPlatform("");
      setSelectedPlatform((prev) => {
        const newState = { ...prev };
        delete newState[platform_id];
        return newState;
      });
    } else {
      setShowPlatform(platform_id);
      setSelectedPlatform({
        [platform_id]: [],
      });
    }
  };

  const handleShortlistClick = () => {
    setTriggeredBy("shortlist");
  };

  const handleShortlistAllClick = () => {
    setTriggeredBy("shortlistAll");
    setModalIsOpenCollectionGrid(true);
  };
  const getSelectedPlatformData = (platforms_data) => {
    if (!Utils.isEmpty(platforms_data)) {
      return platforms_data?.find((item) => item?.platform_id === platformId);
    }
  };

  const formatPlatformData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);
    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };

  const getEngagementRate = (platforms_data) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);
    var eng_data = null;

    var eng_rate = null;
    if (selectedPlatformData?.avg_likes && selectedPlatformData?.avg_comments) {
      eng_data =
        Number(selectedPlatformData?.avg_likes) +
        Number(selectedPlatformData.avg_comments);

      eng_rate = (eng_data / Number(selectedPlatformData.avg_views)) * 100;
    }

    return eng_rate?.toFixed(1);
  };

  function inviteListFilterFun() {
    setFilterOpen(false);
  }

  function inviteListFilterFunOpen() {
    setFilterOpen(true);
  }
  function requestPriceOpen() {
    setRequestPriceModalOpen(true);
  }
  function requestPriceClose() {
    setRequestPriceModalOpen(false);
  }

  const toggleFlyoutDropdown = () => {
    setInfluencerFlyOut(!influencerFlyOut);
    setInfluencerId(null);
  };

  function contentTypeOnToggle(status, content_id, platform_id) {
    let newSelectedPlatform = { ...selectedPlatform };
    console.log("newSelectedPlatform", newSelectedPlatform);

    newSelectedPlatform[platform_id] = [content_id];
    Object.keys(newSelectedPlatform).forEach((selected_platform_id) => {
      if (selected_platform_id !== platform_id) {
        newSelectedPlatform[selected_platform_id] = [];
      }
    });

    setSelectedPlatform(newSelectedPlatform);
  }

  function updateLanguage(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });
    setFilterData((prev) => {
      return {
        ...prev,
        language: value,
      };
    });

    setInfluencerLanguage(selectedList);
  }

  function updateCategory(selectedList, selectedItem) {
    const value = selectedList.map((item) => item.value);

    setFilterData((prev) => ({
      ...prev,
      category: value, // Sync with filterData
    }));

    setCategory(selectedList); // Update the selected categories for the Multiselect
  }

  function updateState(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setFilterData((prev) => {
      return {
        ...prev,
        state: value,
      };
    });

    console.log("selectedList", selectedList);

    setState(selectedList);
  }

  function updateInviteMark(selectedItem) {
    setInvitedMark(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          invite_mark: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }

  function clearFilterFun() {
    setCity([]);
    setState([]);
    setCategory([]);
    setInfluencerGender([]);
    setProfession([]);
    setInvitedMark([]);
    setInfluencerLanguage([]);
    setMinFollower([]);
    setSearchBio(null);
    setVerifiedOrNot([]);
    setInfluencerContact(null);

    setShowContract([]);
    setFilterData({
      gender: null,
      city: [],
      state: [],
      follower: [],
      category: [],
      invite_mark: null,
      language: [],
      verified: null,
    });
    setShowFilterFlag(false);
    // if (showFilterFlag) {
    //   getInfluencerDiscoveryList(1, updateProgress);
    // }
  }

  function notShowFilterChips() {
    if (!showFilterFlag) {
      clearFilterFun();
    }
  }

  function updateGender(selectedItem) {
    setInfluencerGender(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          gender: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }

  function updateContact(selectedItem) {
    console.log("selectedItem", selectedItem);

    const initialObject = selectedItem.reduce((acc, item) => {
      acc[item.id] = true;
      return acc;
    }, {});
    console.log("initialObject", initialObject);
    setInfluencerContact(initialObject);
    // setInfluencerContactEmail()
    setShowContract(selectedItem);
    // selectedItem.forEach((item) => {
    //   hasContact.forEach((data) => {
    //     if (item.id == data.id) {
    //       data.handler(item.value);
    //     }
    //   });
    // });
  }
  function updateVerifiedOrNot(selectedItem) {
    setVerifiedOrNot(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          verified: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }

  function updateMinFollower(selectedItem) {
    setMinFollower(selectedItem);

    if (selectedItem.length != 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          follower: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }

  function updateProfession(selectedList, selectedItem) {
    setShowFilterFlag(false);
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setFilterData((prev) => {
      return {
        ...prev,
        profession: value,
      };
    });

    setProfession(selectedList);
  }

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  function SocialMediaDropDownScreen({
    content_data,
    contentTypeOnToggle,
    selectedPlatform,
  }) {
    return (
      <div>
        <CustomCheckBoxCircularRequestPrice
          text={content_data.name}
          description={content_data.description}
          checked={
            selectedPlatform[content_data.platform_id] &&
            selectedPlatform[content_data.platform_id].includes(
              content_data.content_type_id
            )
          }
          handleCheckbox={(status) =>
            contentTypeOnToggle(
              status,
              content_data.content_type_id,
              content_data.platform_id
            )
          }
        />
      </div>
    );
  }

  function getPlatformContents(platform_id, contentList) {
    return contentList.filter(
      (contentData) => contentData.platform_data.platform_id === platform_id
    );
  }

  const handleCard = (id) => {
    let newSelectedPlateform = { ...selectedPlatform };

    if (newSelectedPlateform[id]) {
      delete newSelectedPlateform[id];
    } else {
      newSelectedPlateform[id] = [];
    }

    setSelectedPlatform(newSelectedPlateform);
  };

  const handleToggle = (status, id) => {
    let newSelectedPlateform = { ...selectedPlatform };
    if (status.target.checked == true) {
      newSelectedPlateform[id] = [];
    } else {
      delete newSelectedPlateform[id];
    }
    setSelectedPlatform(newSelectedPlateform);
  };

  function getCampaignPlatform(contentList) {
    let platform_data = {};
    contentList &&
      contentList.forEach((item) => {
        if (item.platform_data.platform_id in platform_data) {
          return;
        } else {
          platform_data = {
            ...platform_data,
            [item["platform_data"]["platform_id"]]: item.platform_data,
          };
        }
      });

    return platform_data;
  }

  const StyledItem = styled.div`
    padding: 10px;
    color: #555;
    border-radius: 3px;
    margin: 3px;
    cursor: pointer;
    > div {
      display: flex;
      align-items: center;
    }

    input {
      margin-right: 10px;
    }

    :hover {
      background: #f2f2f2;
    }
  `;

  function flyoutBidListFilter() {
    return (
      <Sidebar
        isOpen={filterOpen}
        sidebarWidth={"30%"}
        onClose={inviteListFilterFun}
        Header={filterSidebarHeader}
        Footer={filterSidebarFooter}
        headerAlignment="left"
      >
        <div>
          <div style={{ gap: 5 }} className="m-b-5 flex-row">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Influencer's level"}
              textAlign={"text_align_left"}
            />
          </div>
          <Select
            multi={false}
            options={followers}
            values={minFollower}
            onChange={(value) => updateMinFollower(value)}
            placeholder="Select level"
            autoFocus={false}
            color="#C8C2C2"
            style={{
              border: "1px solid #B3B3B6",
              backgroundColor: "#fff",
              borderRadius: 6,
              padding: 10,
              option: {
                backgroundColor: "#3a3b43",
              },
              
            }}
          />
        </div>
        {/* <div className="m-t-16">
          <div className="m-b-5">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Verification status"}
              textAlign={"text_align_left"}
            />
          </div>
          <Select
            multi={false}
            options={verifyOrNot}
            values={verifiedOrNot}
            onChange={(value) => updateVerifiedOrNot(value)}
            placeholder="Select option"
            autoFocus={false}
            color="#C8C2C2"
            // Customcolor
            style={{
              border: "1px solid #B3B3B6",
              backgroundColor: "#fff",
              borderRadius: 4,
              paddingBottom: "9px",
              paddingTop: "9px",
              option: {
                backgroundColor: "red",
              },
            }}
          />
        </div> */}
        <div className="m-t-16">
          <div style={{ gap: 5 }} className="m-b-5 flex-row">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Bio keywords"}
              textAlign={"text_align_left"}
            />
          </div>
          <div>
            <CustomTextInput
              type="text"
              value={searchBio}
              placeholder="Choose bio keywords"
              onChange={(e) => {
                setSearchBio(e.target.value);
              }}
            />
          </div>
        </div>
        {/* <div className="m-t-16">
          <div style={{ gap: 5 }} className="m-b-5 flex-row">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Engagement Rate"}
              textAlign={"text_align_left"}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between",gap:20 }}>
            <div>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Min Rate"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  value={searchBio}
                  placeholder="Choose Min Rate"
                  onChange={(e) => {
                    setSearchBio(e.target.value);
                  }}
                />
              </div>
            </div>
            <div>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Max Rate"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  value={searchBio}
                  placeholder="Choose Max Rate"
                  onChange={(e) => {
                    setSearchBio(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="m-t-16">
          <div style={{ gap: 5 }} className="m-b-5 flex-row">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Avg Views"}
              textAlign={"text_align_left"}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between",gap:20 }}>
            <div>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Min Views"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  value={searchBio}
                  placeholder="Choose Min Views"
                  onChange={(e) => {
                    setSearchBio(e.target.value);
                  }}
                />
              </div>
            </div>
            <div>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Max Views"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  value={searchBio}
                  placeholder="Choose Max Views"
                  onChange={(e) => {
                    setSearchBio(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className="m-t-16">
          <div className="m-b-5">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Location"}
              textAlign={"text_align_left"}
            />
          </div>
          <Multiselect
            placeholder="Select location"
            id="css_custom"
            options={CampaignData.CITY}
            selectedValues={state}
            onSelect={(selectedList, selectedItem) =>
              updateState(selectedList, selectedItem)
            }
            onRemove={(selectedList, selectedItem) =>
              updateState(selectedList, selectedItem)
            }
            singleSelect={false}
            displayValue={"label"}
            showArrow={false}
            style={{
              chips: Style.chips,
              option: Style.option,
              multiselectContainer: Style.multiselectContainerFilter,
              searchBox: Style.searchBox,
            }}
            
          />
        </div>
        <div className="m-t-16">
          <div className="m-b-5">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Gender"}
              textAlign={"text_align_left"}
            />
          </div>
          <Select
            multi={false}
            options={options}
            values={influencerGender}
            onChange={(value) => updateGender(value)}
            placeholder="Select gender"
            autoFocus={false}
            color="#C8C2C2"
            style={{
              border: "1px solid #B3B3B6",
              backgroundColor: "#fff",
              borderRadius: 4,
              padding: 10,
              option: {
                backgroundColor: "red",
              },
             
            }}
          />
        </div>
        <div className="m-t-16">
          <div className="m-b-5">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Category"}
              textAlign={"text_align_left"}
            />
          </div>
          <Multiselect
            placeholder="Select category"
            id="css_custom"
            selectedValues={category}
            options={Object.values(CampaignData.FULL_CATEGORIES.CATEGORYS)}
            onSelect={(selectedList, selectedItem) =>
              updateCategory(selectedList, selectedItem)
            }
            onRemove={(selectedList, selectedItem) =>
              updateCategory(selectedList, selectedItem)
            }
            singleSelect={false}
            displayValue={"label"}
            showArrow={false}
            style={{
              chips: Style.chips,
              option: Style.option,
              multiselectContainer: Style.multiselectContainerFilter,
              searchBox: Style.searchBox,
            }}
          />
        </div>
        <div className="m-t-16">
          <div className="m-b-5">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Profession"}
              textAlign={"text_align_left"}
            />
          </div>
          <Multiselect
            placeholder="Select Profession"
            id="css_custom"
            selectedValues={profession}
            options={subCategoryAll}
            onSelect={(selectedList, selectedItem) =>
              updateProfession(selectedList, selectedItem)
            }
            onRemove={(selectedList, selectedItem) =>
              updateProfession(selectedList, selectedItem)
            }
            singleSelect={false}
            displayValue={"name"}
            showArrow={false}
            style={{
              chips: Style.chips,
              option: Style.option,
              multiselectContainer: Style.multiselectContainerFilter,
              searchBox: Style.searchBox,
              color:"#B3B3B6"
            }}
          />
        </div>
        <div className="m-t-16">
          <div className="m-b-5">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Language"}
              textAlign={"text_align_left"}
            />
          </div>
          <Multiselect
            placeholder="Choose language"
            id="css_custom"
            options={Object.values(CampaignData.LANGUAGE)}
            onSelect={(selectedList, selectedItem) =>
              updateLanguage(selectedList, selectedItem)
            }
            onRemove={(selectedList, selectedItem) =>
              updateLanguage(selectedList, selectedItem)
            }
            selectionLimit={3}
            selectedValues={influencerLanguage}
            singleSelect={false}
            displayValue={"n"}
            showArrow={false}
            style={{
              chips: Style.chips,
              option: Style.option,
              multiselectContainer: Style.multiselectContainerFilter,
              searchBox: Style.searchBox,
            }}
          />
        </div>
        <div className="m-t-16">
          <div className="m-b-5">
            <CustomText
              p
              fontSize={"font_size_medium"}
              textColor={"text_color_primary"}
              text={"Has Contact"}
              textAlign={"text_align_left"}
            />
          </div>
          <Select
            multi={true}
            options={hasContact}
            values={showContract}
            onChange={(value) => updateContact(value)}
            itemRenderer={({ item, methods }) => (
              console.log("demo 22", item, methods),
              (
                <StyledItem>
                  <div onClick={() => methods.addItem(item)}>
                    <input
                      type="checkbox"
                      onChange={() => methods.addItem(item)}
                      checked={methods.isSelected(item)}
                    />
                    {item.label}
                  </div>
                </StyledItem>
              )
            )}
            placeholder="Select "
            autoFocus={false}
            color="#C8C2C2"
            style={{
              border: "1px solid #B3B3B6",
              backgroundColor: "#fff",
              borderRadius: 4,
              padding: 10,
              option: {
                backgroundColor: "red",
              },
            }}
          />
        </div>
      </Sidebar>
    );
  }

  const filterSidebarHeader = () => <div>All Filters</div>;

  const filterSidebarFooter = () => (
    <div style={{ display: "flex", gap: 10 }}>
      <div>
        <PrimaryCta
          invert
          onClick={() => {
            clearFilterFun();
          }}
          fontSize={"font_size_large"}
          text={"Clear Filter"}
          isLoading={""}
        />
      </div>
      <div>
        <PrimaryCta
          fifth
          onClick={() => {
            //getInfluencerDiscoveryList(1, updateProgress);
            //setShowFilterFlag(true);
            inviteListFilterFun();
          }}
          fontSize={"font_size_large"}
          text={"Apply Filter"}
          isLoading={""}
          className="blue-button"
        />
      </div>
    </div>
  );
  function requestPriceFunction() {
    return (
      <div className={`sidebar ${requestPriceModalOpen ? "open" : ""}`}>
        <div className="request-price-modal">
          <div className="request-price-modal-header">
            <div className="request-price-modal-header-text">Request Price</div>
            <div
              onClick={() => requestPriceClose()}
              style={{ cursor: "pointer" }}
            >
              <Icon iconName="CrossBtnIcon" />
            </div>
          </div>

          <div className="sidebar-content-filter-inner">
            <div>
              <div style={{ marginBottom: "15px", fontWeight: "600" }}>
                <CustomText p text={"Deliverables"} />
              </div>
            </div>

            <div className="request-price-type-campaign-container flex-column ">
              {contentList &&
                Object.values(getCampaignPlatform(contentList))
                  .filter((item) => item.platform_id !== "6u73")
                  .map((item, index) => {
                    return (
                      <div
                        className="request-price-cards"
                        key={item.platform_id}
                      >
                        <div className="request-price-title">
                          <SocialMediaCardsRequestPrice
                            text={item.name}
                            iconName={Utils.getPlatformIconNamebyId(
                              item.platform_id
                            )}
                            defaultChecked={Array.isArray(
                              selectedPlatform[item.platform_id]
                            )}
                            onChange={(status) =>
                              handleToggle(status, item.platform_id)
                            }
                            onClickIcon={() => handleCard(item.platform_id)}
                          />
                          <CustomRadioButton
                            name={`radio-${item.platform_id}`}
                            value={item.platform_id}
                            checked={showPlatform === item.platform_id}
                            onChange={() => toggleSocialMedia(item.platform_id)}
                            className="request-price-radio"
                          />
                        </div>

                        {showPlatform === item.platform_id && (
                          <div className="request-price-social-handle">
                            {getPlatformContents(
                              item.platform_id,
                              contentList
                            ).map((contentItem, key) => (
                              <SocialMediaDropDownScreen
                                key={key}
                                {...contentItem}
                                contentTypeOnToggle={contentTypeOnToggle}
                                selectedPlatform={selectedPlatform}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  })}
            </div>

            {/* <div className="m-b-10">
                          <div style={{marginBottom:"10px",marginTop:"10px"}}>
                            <CustomText
                              p
                              text={"Title"}
                             
                            />
                          </div>
            
                          <CustomTextInput
                            newWidth={"login-right-side-top-input-field"}
                            value={title}
                            placeholder={"New year sale"}
                            onChange={(e) => setTitle(e.target.value)}
                          />
            </div> */}

            <div className="">
              <div className="m-b-15 m-t-5" style={{ fontWeight: "600" }}>
                <CustomText p text={"Brief"} />
              </div>

              <div className="m-b-10">
                <RichTextEditor
                  toolbarConfig={toolbarConfig}
                  value={instructionNote}
                  onChange={(newValue) => setInstructionNote(newValue)}
                />
              </div>
            </div>
            <div className="request-price-modal-button">
              <PrimaryCta
                fifth
                fontSize={"font_size_large"}
                text={"Request Price"}
                fullWidth100
                className="login-right-side-input-field"
                isLoading={requestPriceLoading}
                onClick={() => requestPrice()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function updateProgressRequestPrice(status) {
    setRequestPriceLoading(status);
  }

  function requestPrice() {
    let content_type_deep_data = [];
    Object.values(selectedPlatform).forEach((item) => {
      item.forEach((id) => {
        content_type_deep_data.push({
          content_data: {
            content_type_id: id,
          },
        });
      });
    });

    let newCampaignDeepData = campaignDeepData;
    newCampaignDeepData["campaign_data"]["name"] = title;
    newCampaignDeepData["campaign_data"]["brand_id"] =
      brand_deep_data?.brand_data?.brand_id;
    newCampaignDeepData["content_type_deep_data"] = content_type_deep_data;
    newCampaignDeepData["instruction_data"] = {};
    newCampaignDeepData["instruction_data"]["instruction_note"] =
      instructionNoteText;

    var params = {
      campaign_deep_data: JSON.stringify(newCampaignDeepData),
    };

    Core.createCampaignUser(
      createCampaignUserSuccess,
      createCampaignUserFailed,
      updateProgressRequestPrice,
      params
    );
  }

  function createCampaignUserSuccess() {
    toast.success("Price Requested Successfully");
    setRequestPriceLoading(false);
    setRequestPriceModalOpen(false);
    window.location.reload();
  }

  function createCampaignUserFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
    setRequestPriceLoading(false);
  }

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };
  const openModal = () => {
    console.log("modalIsOpen", modalIsOpen);

    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  function openModalCollection() {
    if (modalIsOpenCollection == false) {
      setModalIsOpenCollection(true);
    } else {
      setModalIsOpenCollection(false);
    }
  }

  function closeModalCollection() {
    setModalIsOpenCollection(false);
  }
  function openModalCollectionGrid() {
    if (modalIsOpenCollectionGrid == false) {
      setModalIsOpenCollectionGrid(true);
    } else {
      setModalIsOpenCollectionGrid(false);
    }
  }

  function closeModalCollectionGrid() {
    setModalIsOpenCollectionGrid(false);
  }

  function hoveredButton() {
    return (
      <button
        onClick={openModalCollectionGrid}
        className={`button-hover-show-btn ${
          showCollectionBtn ? "hidden-hover-show-btn" : ""
        }`}
      >
        Add Collection
      </button>
    );
  }

  function onSuccessModalCollectionGrid(collection_data) {
    if (collectionData) {
      setUpdateCollectionData(collection_data);
    }
    console.log("abcd");
    setSelectedInfluencerRows([]);
    handleClearRows();
  }
  console.log("abcd", selectedInfluencerRows);
  const conditionalRowStyles = [
    {
      when: (row) => row.influencer_data.is_protected == true,
      style: {
        filter: "blur(5px)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const influencerTableSchema = [
    {
      name: "Influencers",
      selector: (row) => row["influencer_data"]["name"],
      width: "300px",
      cell: (data) => (
        <span
          onClick={(e) => {
            toggleFlyoutDropdown();
            setInfluencerId(
              !Utils.isEmpty(data.platforms_data)
                ? data?.influencer_data.influencer_id
                : null
            );
          }}
          style={{ display: "flex", gap: 8, alignItems: "center" }}
        >
          <Image
            source={
              data.influencer_data.profile_picture
                ? Utils.generateInfluencerDpUrl(
                    data.influencer_data.influencer_id,
                    data.influencer_data.profile_picture
                  )
                : require("../../resources/images/user-copy.png")
            }
            fallBack={require("../../resources/images/user-copy.png")}
            className={
              data.influencer_data.is_verified
                ? "discovery-user-profile"
                : "proposal-user-profile"
            }
          />
          <div style={{ flexDirection: "row", display: "flex", gap: 5 }}>
            <div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  gap: 5,
                  alignItems: "baseline",
                }}
              >
                <CustomText
                  p
                  text={data.influencer_data.name}
                  className={
                    !data.influencer_data.is_protected == true
                      ? "Body-1"
                      : "blur-text"
                  }
                />
                {/* <div>
                  <CustomText
                    p
                    text={`(${data.influencer_data.ratings.toFixed(1)})`}
                    className="typographyH5"
                  />
                </div> */}
              </div>
              <CustomText
                p
                text={getInflencerLocation(data?.influencer_data)}
                fontSize={"font_size_extraSmall"}
                fontWeight={"rubik_regular"}
                textColor={"text_color_primary"}
                textAlign={"text_align_left"}
                className={
                  !data.influencer_data.is_protected == true ? "" : "blur-text"
                }
              />
            </div>
          </div>
        </span>
      ),
    },
    {
      name: "Platform",
      selector: (row) => (
        <span>
          {row.influencer_data.is_protected == true ? (
            <div className="blur-text">N/A</div>
          ) : (
            <>
              {!Utils.isEmpty(row?.platforms_data)
                ? platFormName(
                    row?.platforms_data,
                    row?.influencer_data?.influencer_id
                  )
                : "N/A"}
            </>
          )}
        </span>
      ),

      // sortable: true,
      // width: "100px",
      omit: isMobile,
    },
    {
      name: "Followers",
      selector: (row) => (
        <span>
          {row.influencer_data.is_protected == true ? (
            <div className="blur-text">N/A</div>
          ) : (
            <>
              {!Utils.isEmpty(row?.platforms_data)
                ? formatPlatformData(row?.platforms_data, "followers")
                : "N/A"}
            </>
          )}
        </span>
      ),

      textAlign: "center",
      omit: isMobile,
    },
    // {
    //   name: "Like",
    //   selector: (row) => formatPlatformData(row.platforms_data, "avg_likes"),
    //   width: "90px",
    // },
    // {
    //   name: "Comment",
    //   selector: (row) => formatPlatformData(row.platforms_data, "avg_comments"),
    //   width: "90px",
    // },
    {
      name: "Avg Views",
      // width: "100px",
      omit: isMobile,
      selector: (row) => (
        <span>
          {row.influencer_data.is_protected == true ? (
            <div className="blur-text">N/A</div>
          ) : (
            <>
              {!Utils.isEmpty(row?.platforms_data)
                ? Utils.isObjectOrNullUndefinedZero(
                    formatPlatformData(row?.platforms_data, "avg_views")
                  )
                  ? "-"
                  : formatPlatformData(row?.platforms_data, "avg_views")
                : "N/A"}
            </>
          )}
        </span>
      ),
    },
    {
      name: "ER",
      omit: isMobile,
      width:"100px",
      selector: (row) =>
        Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(row?.platforms_data, "engagement_rate")
        )
          ? "-"
          : getEngagementRate(row?.platforms_data),

      cell: (data) => (
        <span>
          {!data.influencer_data.is_protected == true ? (
            <Tooltip
              title={
                <>
                  <div>
                    Avg Likes ={" "}
                    {Utils.isObjectOrNullUndefinedZero(data?.platforms_data) &&
                      formatPlatformData(data?.platforms_data, "avg_likes")}
                  </div>
                  <div>
                    Avg Comments ={" "}
                    {Utils.isObjectOrNullUndefinedZero(data?.platforms_data) &&
                      formatPlatformData(data?.platforms_data, "avg_comments")}
                  </div>
                </>
              }
            >
              {!Utils.isEmpty(data?.platforms_data) ? (
                <>
                  {" "}
                  {Utils.isObjectOrNullUndefinedZero(
                    Number(
                      formatPlatformData(
                        data?.platforms_data,
                        "engagement_rate"
                      )
                    )
                  )
                    ? "-"
                    : Number(
                        formatPlatformData(
                          data?.platforms_data,
                          "engagement_rate"
                        )
                      ).toFixed(2) + " %"}
                </>
              ) : (
                "N/A"
              )}
            </Tooltip>
          ) : (
            <div className="blur-text">N/A</div>
          )}
        </span>
      ),
    },

    // {
    //   name: "",
    //   paddingRight: "20px",
    //   cell: (data) => (
    //     <span style={{}}>
    //         <div className="">
    //           <CustomButton
    //             text={"Request Price"}
    //             onClick={() => requestPriceOpen()}
    //             fontSize={"font_size_small"}
    //             borderRadius={4}
    //             padding={{ top: 12, right: 12, bottom: 12, left: 12 }}
    //             textColor={"text_color_light"}
    //             background={"#090A14"}
    //             border={"none"}
    //             boxShadow={"none"}
    //             hoverColor={"var(--hover-color, #1977F2)"}
    //           />
    //         </div>
    //     </span>
    //   ),
    // },
    {
      name: "",
      paddingRight: "20px",
      cell: (data) => (
        <span style={{}}>
          {data.influencer_data.is_protected == true ? (
            <div className="blur-text">
              <PrimaryCta
               fifth
                // onClick={() => {
                //   setHoveredId(data?.influencer_data?.influencer_id);
                //   openModalCollectionGrid();
                // }}
                text={"Shortlist"}
                fontSize={"font_size_small"}
                isLoading={
                  isInviteLoading[data?.influencer_data?.influencer_id]
                }
                className="blue-button"
              />
            </div>
          ) : (
            <>
              <PrimaryCta
               fifth
                onClick={() => {
                  checkAddToCollectionInfluencer(
                    data?.influencer_data?.influencer_id
                  );
                }}
                text={"Shortlist"}
                fontSize={"font_size_small"}
                isLoading={
                  isInviteLoading[data?.influencer_data?.influencer_id]
                }
                className="blue-button"
              />
              {data.influencer_data.influencer_id == hoveredId}
            </>
          )}
        </span>
      ),
    },
  ];

  function inviteAndReject(id) {
    let inviteId = influencerInviteList.find(
      (item) => item.influencer_id == id
    );
    return inviteId;
  }

  const platFormName = (platforms_data, influencer_id) => {
    const handleMouseEnter = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = true;
      setHoveredIcons(newHoveredIcons);
    };

    const handleMouseLeave = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = false;
      setHoveredIcons(newHoveredIcons);
    };

    let platformData;
    if (!Utils.isEmpty(platforms_data)) {
      platformData = platforms_data.find(
        (item) => item?.platform_id === platformId
      );
    }

    const handlePlatformClick = (url) => {
      // Open the platform URL here
      window.open(url, "_blank");
      // Reset all hover states to false after clicking
      setHoveredIcons(new Array(platforms_data.length).fill(false));
    };

    return (
      <>
        {platforms_data.length > 0 &&
          platformData &&
          platforms_data[0].platform_id != null && (
            <div>
              {/* <CustomText
              p
              text={"Social Presence"}
              fontSize={"font_size_small"}
              textColor={"text_color_black"}
              fontWeight={"Poppins_semiBold"}
            /> */}
            </div>
          )}
        <div style={{ marginTop: 7 }} className="flex-row">
          <div
            className={"platform-name"}
            onMouseEnter={() =>
              handleMouseEnter(`${influencer_id}${platformData?.platform_id}`)
            }
            onMouseLeave={() =>
              handleMouseLeave(`${influencer_id}${platformData.platform_id}`)
            }
            onClick={() =>
              handlePlatformClick(
                Utils.getSocialUrl(
                  platformData?.platform_id,
                  platformData?.identifier_id
                )
              )
            }
          >
            {platformData?.platform_id && (
              <Icon
                iconName={
                  hoveredIcons[`${influencer_id}${platformData?.platform_id}`]
                    ? Utils.getSocialIconHover(platformData?.platform_id)
                    : Utils.getSocialIcon(platformData?.platform_id)
                }
                height={14}
                width={14}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  function influencerDetail(influencer_id) {
    const url = `/influencer/${influencer_id}`;
    window.open(url, "_blank");
  }

  function getInflencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]?.n;
    } else {
      return "-";
    }
  }

  function filterDropDownOpenClose() {
    if ((sortBy != null) & (sortOrder != null)) {
      setSortBy(null);
      setSortOrder(null);
      setFilterLabel(null);
    } else {
      SortDropDown();
    }
  }

  function SortDropDown() {
    if (filterDropDown) {
      setFilterDropDown(false);
    } else {
      setFilterDropDown(true);
    }
  }
  function filterPlatformClose() {
    if (platformId != null) {
      setPlatformId(null);
      setPlatformLabel(null);
    } else {
      platformDropDownFun();
    }
  }

  function platformDropDownFun() {
    if (platformDropDown) {
      setPlatformDropDown(false);
    } else {
      setPlatformDropDown(true);
    }
  }
  function clearFilterCategory() {
    let cateData = filterData;
    cateData["category"] = [];
    setFilterData(cateData);
    setCategory([]);
    getInfluencerDiscoveryList(1, updateProgress);
  }
  function switchCollection() {
    if (updateCollectionData) {
      return (
        <div
          onClick={() => openModalCollectionGrid()}
          className="label-of-filter-card-container-collection "
        >
          <CustomText
            p
            text={`Switch List`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          {/* <div
            onClick={() => clearFilterLocation()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div> */}
        </div>
      );
    } else {
      return <></>;
    }
  }

  function changeSocialMediaFilter() {
    return (
      <div className="dropdown-button-container-discovery">
        <button
          onClick={() => platformDropDownFun()}
          className="dropdown-button"
        >
          <CustomText
            p
            text={platformLabel ? platformLabel : "Platform"}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          {platformLabel ? (
            <div className="cursor-pointer">
              <div className="cursor-pointer">
                <Icon iconName={"DropdownIcon"} height={18} width={18} />
              </div>
            </div>
          ) : (
            <div className="cursor-pointer">
              <Icon iconName={"DropdownIcon"} height={18} width={18} />
            </div>
          )}
        </button>
        {platformDropDown && (
          <div className="dropdown-list-container-invite">
            {platformTypes.map((item) => {
              return (
                <div
                  onClick={() => {
                    setNext(0);
                    setPlatformId(item.platformName);
                    platformDropDownFun();
                    setPlatformLabel(item.label);
                  }}
                  className={
                    item.platformName == platformId
                      ? "dropdown-button-list-selected"
                      : "dropdown-button-list"
                  }
                >
                  {item.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
  function filterDataShowCategory() {
    if (filterData.category && filterData.category.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${filterData.category.length} Categories`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterCategory()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }
  function clearFilterGender() {
    let genderData = filterData;
    genderData["gender"] = null;
    setFilterData(genderData);

    setInfluencerGender([]);
    getInfluencerDiscoveryList(1, updateProgress);
  }
  function filterDataShowGender() {
    if (filterData.gender) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`Gender ${filterData.gender}`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterGender()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }

  function clearFilterVerifiedOrNot() {
    let verified = filterData;
    verified["verified"] = null;
    setFilterData(verified);

    setVerifiedOrNot([]);
    getInfluencerDiscoveryList(1, updateProgress);
  }
  function filterDataVerified() {
    if (filterData.verified) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={"Verified"}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterVerifiedOrNot()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }
  function clearFilterLevel() {
    let followerData = filterData;
    followerData["follower"] = [];
    setFilterData(followerData);

    setMinFollower([]);
    getInfluencerDiscoveryList(1, updateProgress);
  }

  function filterDataShowLevel() {
    if (filterData.follower && filterData.follower?.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${Utils.getFollowersValue(filterData.follower)}`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterLevel()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }
  function clearFilterLocation() {
    let stateData = filterData;
    stateData["state"] = [];
    setFilterData(stateData);

    setState([]);
    getInfluencerDiscoveryList(1, updateProgress);
  }
  function filterDataShowLocation() {
    if (filterData.state && filterData.state.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${filterData.state.length} Locations`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterLocation()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }
  function clearFilterLanguage() {
    let languageData = filterData;
    languageData["language"] = [];
    setFilterData(languageData);

    setInfluencerLanguage([]);
    getInfluencerDiscoveryList(1, updateProgress);
  }
  function filterDataShowLanguage() {
    if (filterData.language && filterData.language.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${filterData.language.length} Languages`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterLanguage()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }
  function clearFilterProfession() {
    let professionData = filterData;
    professionData["profession"] = [];
    setFilterData(professionData);

    setProfession([]);
    getInfluencerDiscoveryList(1, updateProgress);
  }

  const removeFilterProfession = (id) => {
    const updatedProfession = profession.filter((item) => item.id !== id);
    setProfession(updatedProfession);
    var value = [];
    updatedProfession.forEach((item) => {
      value.push(item.id);
    });

    setFilterData((prev) => {
      return {
        ...prev,
        profession: value,
      };
    });
    getInfluencerDiscoveryList(1, updateProgress);
  };

  function filterDataShowProfession() {
    if (filterData.profession && filterData.profession.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${filterData.profession.length} Professions`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterProfession()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }
  function filterDataShowProfessionChips() {
    if (filterData.profession && filterData.profession.length > 0) {
      return (
        <>
          {profession.map((item) => {
            return (
              <div className="label-of-filter-card-container">
                <CustomText
                  p
                  text={`${item.name}`}
                  fontSize={"font_size_regular"}
                  textColor={"text_color_black"}
                  textAlign={"text_align_right"}
                />
                <div
                  onClick={() => removeFilterProfession(item.id)}
                  className="close-filters-data"
                >
                  <Icon iconName="CrossBtnIcon" />
                </div>
              </div>
            );
          })}
        </>
      );
    }
  }

  function clearFilterSearchName() {
    setSearchBio(null);
  }
  function filterDataShowSearchName() {
    if (searchBio) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={searchBio}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterSearchName()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }

  // const influencerObject = influencerList.map((item, index) => ({
  //   index: index + 1,
  //   name: item.influencer_data.name,
  //   platform: item.platforms_data,
  //   influencer_id: item.influencer_data.influencer_id,
  //   followers: formatPlatformData(item.platforms_data, "followers"),
  //   avgViews: Utils.isObjectOrNullUndefinedZero(
  //     formatPlatformData(item.platforms_data, "avg_views")
  //   )
  //     ? "-"
  //     : formatPlatformData(item.platforms_data, "avg_views"),
  //   engagementRate: Utils.isObjectOrNullUndefinedZero(
  //     formatPlatformData(item.platforms_data, "engagement_rate")
  //   )
  //     ? "-"
  //     : `${Number(
  //         formatPlatformData(item.platforms_data, "engagement_rate")
  //       ).toFixed(2)} %`,
  //   profile_picture: item.influencer_data.profile_picture,
  //   isVerified: item.influencer_data.is_verified,
  //   location: getInflencerLocation(item.influencer_data),
  //   avgLikes: formatPlatformData(item.platforms_data, "avg_likes"),
  //   avgComments: formatPlatformData(item.platforms_data, "avg_comments"),
  // }));
  function ActionOnLoadHandler(subscription_list) {
    console.log("subscription_list", subscription_list);

    setSubscriptionList(subscription_list);
  }

  function openSidebar() {
    setIsSidebarOpen(true);
  }
  function closeSidebar() {
    setIsSidebarOpen(false);
  }

  function addInfluencerList() {
    setAddInfluencer(true);
  }
  return (
    <div className="dashboard-container background-color-primary">
      <SideMenu />
      <div className="main-screen">
        <Header
          onLoadHeader={(subscription_list) =>
            //console.log('sadasfdasddfaad')

            ActionOnLoadHandler(subscription_list)
          }
          hideHeaderLeft={true}
        />
        <div style={{ minHeight: 500 }}>
          <div className="page-container-campaignList">
            <div className="bid-list-dropdown-header-container m-l-20 m-r-20">
              <div>
                {influencerList && (
                  <div
                    style={{ marginTop: 12 }}
                    className="flex-row justify-between align-center"
                  >
                    {/* <CustomText
                      p
                      text={"Top "}
                      fontSize={"font_size_regular"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular_medium"}
                    />
                    &nbsp;
                    <CustomText
                      p
                      text={`${start + 1}-${totalRows ? totalRows : 0} 
                   Results`}
                      fontSize={"font_size_regular"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular"}
                    /> */}
                  </div>
                )}
              </div>
              <div style={{ gap: 5 }} className="flex-row m-l-5">
                {/* <div className="dropdown-button-container">
                  <button
                    onClick={() => filterDropDownOpenClose()}
                    className="dropdown-button"
                  >
                    <CustomText
                      p
                      text={filterLabel ? filterLabel : "Sort by"}
                      fontSize={"font_size_regular"}
                      textColor={"text_color_black"}
                      textAlign={"text_align_right"}
                    />
                    {filterLabel ? (
                      <div className="cursor-pointer">
                        <Image
                          source={require("../../resources/images/bid-search-cross.png")}
                        />
                      </div>
                    ) : (
                      <div className="cursor-pointer">
                        <Icon
                          iconName={"DropdownIcon"}
                          height={18}
                          width={18}
                        />
                      </div>
                    )}
                  </button>
                  {filterDropDown && (
                    <div className="dropdown-list-container-discovery">
                      {sortTypes.map((item) => {
                        return (
                          <div
                            onClick={() => {
                              setNext(0);
                              setSortBy(item.sortBy);
                              setSortOrder(item.sortOrder);
                              SortDropDown();
                              setFilterLabel(item.label);
                            }}
                            className={
                              item.sortBy == sortBy &&
                              item.sortOrder == sortOrder
                                ? "dropdown-button-list-selected"
                                : "dropdown-button-list"
                            }
                          >
                            {item.label}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div> */}
                {/* {selectedInfluencerRows.length > 0 && (
                  <div>
                    <PrimaryCta
                      fifth
                      text={"Request Price All"}
                    />
                  </div>
                )} */}
                {selectedInfluencerRows.length > 0 && (
                  <div>
                    <PrimaryCta
                      fifth
                      onClick={() => {
                        handleShortlistAllClick();
                      }}
                      text={"Shortlist"}
                      className="blue-button"
                    />
                  </div>
                )}

                {/* <div>
                    <PrimaryCta
                      fifth
                      onClick={() => addInfluencerList()
                      }
                      text={"Add Influencer"}
                    />
                  </div> */}

                {/* {(
                  <div>
                    <PrimaryCta
                      fifth
                      onClick={() => openSidebar()}
                      text={"SideBar Component"}
                    />
                  </div>
                )} */}

                {switchCollection()}

                <div className="filter-chips-container">
                  {changeSocialMediaFilter()}
                  {filterDataShowCategory()}
                  {filterDataShowLanguage()}
                  {filterDataShowGender()}
                  {filterDataVerified()}
                  {filterDataShowLevel()}
                  {filterDataShowLocation()}
                  {/* {filterDataShowProfession()} */}
                  {filterDataShowSearchName()}
                  {filterDataShowProfessionChips()}
                </div>

                <div
                  className="filter-icon-btn-invite"
                  onClick={() => inviteListFilterFunOpen()}
                >
                  <Icon iconName="filterIconNew" />
                </div>
              </div>
            </div>

            <>
              {influencerList &&
                influencerList[8]?.influencer_data?.is_protected && (
                  <SubscriptionBuyButton onClick={openModal} />
                )}

              {totalRows == 0 ? (
                <div className="fall-back-center-campaign">
                  <FallBack
                    heading={"No Influencers Found"}
                    title={
                      "No influencers found. Adjust your filters for better results."
                    }
                  />
                </div>
              ) : (
                <>
                  <div className="custom-table">
                    <CustomTable
                      className="CustomTable"
                      // title="Movie List"
                      tableSchema={influencerTableSchema}
                      paginationPerPage={10}
                      tableData={influencerList}
                      rowPaddingTop={16}
                      rowPaddingBottom={16}
                      customStyles={customBidStyles}
                      // conditionalRowStyles={conditionalRowStyles}
                      progressPending={isLoading}
                      paginationTotalRows={totalRows}
                      selectableRows
                      onSelectedRowsChange={handleRowSelected}
                      onChangePage={handlePageChange}
                      clearSelectedRows={toggledClearRows}
                      // onChangeRowsPerPage={getInfluencerListChange}
                      // onRowClicked={(item) =>
                      //   CampaignClick(item.campaign_data.campaign_id)
                      // }
                      progressComponent={
                        <div className="fall-back-center-campaign">
                          <Loader />
                        </div>
                      }
                      paginationServer
                      pagination
                    />
                  </div>
                </>
              )}
            </>
          </div>
          {flyoutBidListFilter()}
          {requestPriceModalOpen && requestPriceFunction()}

          {modalIsOpenCollectionGrid && (
            <CreateCollectionGrid
              closeModal={closeModalCollectionGrid}
              id={hoveredId}
              collection_data={updateCollectionData}
              onSuccessModalCollectionGrid={onSuccessModalCollectionGrid}
              selectedInfluencerRows={selectedInfluencerRows}
              triggeredBy={triggeredBy}
            />
          )}

          <SubscriptionModal
            subscriptionData={subscriptionList}
            closeModal={closeModal}
            orgData={org_deep_data?.org_data}
            modalIsOpen={modalIsOpen}
          />

          {
            <ModalComponent
              isOpen={openModal3}
              onRequestClose={() => addToCollectionModalClose()}
              customStyle={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 999999,
                },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  // borderColor: "#f55879",
                  backgroundColor: "#F0F3F9",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              <div className="">
                <CustomText
                  p
                  text={`Do you want to Add Influencer to  ${updateCollectionData?.collection_name}`}
                  textColor={"text_color_primary"}
                  fontSize={"font_size_xl"}
                  className="typographyH7 m-b-8"
                />
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex ",
                  justifyContent: "center",
                  gap: "30px",
                }}
              >
                <PrimaryCta
                  invert
                  className="back-btn-campaign"
                  onClick={() => addToCollectionModalClose()}
                  fontSize={"font_size_large"}
                  text={"No"}
                />
                <PrimaryCta
                  third
                  fontSize={"font_size_regular"}
                  className="blue-button"
                  text={"Yes"}
                  isLoading={addInfluencerLoading}
                  onClick={() => postAddInfluencerCollection()}
                />
              </div>
            </ModalComponent>
          }
          {
            <AddInfluencerSidebar
              addInfluencer={addInfluencer}
              setAddInfluencer={setAddInfluencer}
            />
          }
          {
            <InfluencerDetailModal
              influencerFlyOut={influencerFlyOut}
              toggleFlyoutDropdown={toggleFlyoutDropdown}
              influencer_id={influencerId}
              org_id={brand_deep_data.brand_data.org_id}
            />
          }
        </div>
      </div>
    </div>
  );
}

export default InfluencerListTable;
