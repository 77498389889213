import React, { useEffect, useState } from "react";
import { CustomRadioButton, CustomText } from "../components";
import Utils from "../common/utils";

function CheckoutParcel(props) {
  const [checkoutPlan, setCheckoutPlan] = useState(null);

  console.log("props", props);

  useEffect(() => {
    if (props.credits && !checkoutPlan) {
      setCheckoutPlan(props?.credits[0]);
      props.setCheckoutSummary(props?.credits[0]);
    }
  }, [props.credits]);

  console.log("kkk", props.credits);

  function checkoutCheck(item) {
    console.log("item", item);

    if (checkoutPlan?.quantity === item.quantity) {
      setCheckoutPlan(null);
    } else {
      setCheckoutPlan(item);
    }
  }

  function perCredits(item) {
    return item.value / item.quantity;
  }
  console.log("credits2", props);
  // let creditsData;
  // if (props.credits.credit_list) {
  //   creditsData = props.credits?.credit_list;
  // } else {
  //   creditsData = props.credits?.credits;
  // }

  // console.log("creditsData", creditsData);

  return (
    <div className="checkout-select-inner-container">
      {props.credits?.map((item, index) => {
        const isChecked = checkoutPlan?.quantity === item.quantity;

        const text = () => {
          if (props.fromState) {
            return item.quantity === 10
              ? `Buy {} Credit pack`
              : `Buy 50 Credit pack`;
          } else {
            return item.quantity === 10
              ? `Buy ${item.quantity} credit to post this campaign`
              : `Buy ${item.quantity} credits to post 5 campaign`;
          }
        };

        return (
          <div
            onClick={() => {
              setCheckoutPlan(item);
              props.setCheckoutSummary(item);
            }}
            className="checkout-main-container"
          >
            <div>
              <CustomText
                p
                className="typography14_left m-b-5"
                text={
                  `Buy ${item.quantity} credit pack`
                  // item.quantity === 10
                  //   ? `Buy ${item.quantity} credit to post this campaign`
                  //   : `Buy ${item.quantity} credits to post 5 campaign`
                }
                textColor={"text_color_primary"}
                fontWeight={"Inter_regular"}
              />
              <div className="flex-row">
                {item.actual_value && (
                  <CustomText
                    del
                    ellipsis
                    className="m-b-5"
                    fontSize={"font_size_small "}
                    text={Utils.convertFromINR(
                      item.actual_value,
                      props.user_data.country
                    )}
                    textColor={"text_color_primary"}
                    fontWeight={"Poppins_regular_medium"}
                  />
                )}

                <CustomText
                  p
                  ellipsis
                  className="m-b-5 m-l-5"
                  fontSize={"font_size_small "}
                  text={Utils.convertFromINR(
                    item.value,
                    props.user_data.country
                  )}
                  textColor={"text_color_primary"}
                  fontWeight={"Poppins_regular_medium"}
                />
              </div>
              <CustomText
                p
                ellipsis
                fontSize={"font_size_extraSmall"}
                text={`${Utils.convertFromINR(
                  perCredits(item),
                  props.user_data.country
                )}/credit`}
                textColor={"text_color_primary"}
                fontWeight={"Inter_regular"}
              />
            </div>
            <div className="m-l-15">
              <CustomRadioButton
                value={index}
                checked={isChecked}
                onChange={() => checkoutCheck(item)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default CheckoutParcel;
