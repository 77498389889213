import React, { useEffect, useState } from "react";
import { CustomText, Image, PrimaryCta } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import SideMenu from "../../screens/sideMenu";
import Header from "../../screens/header";
import "react-best-tabs/dist/index.css";
import ContractsDetailsScreen from "./contractDetailScreen";

function ContractsDetailsParentScreen(props) {
  const [activeTab, setActiveTab] = useState("detail-page");
  const assignment_id = useParams();
  const [changeStatus, setChangeStatus] = useState(false);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div
      style={{ minHeight: 500 }}
      className="dashboard-container background-color-primary"
    >
      <SideMenu />
      <div className="main-screen-contract-detail">
        <Header />

        <div
          className={
            activeTab === "message-page"
              ? "internal-message-page-container"
              : "internal-page-container"
          }
        >
          {/* <div className="campaign-text-heading">
            <CustomText
              p
              text={"Contract Details"}
              textColor={"text_color_primary"}
              fontSize={"font_size_2xl"}
            />
          </div> */}
          <div className="contract-detail-container">
            {/* <div className="campaign-detail-upper-container">
              <div className="campaign-detail-tab-header">
                <div
                  className={activeTab === "detail-page" ? "active" : ""}
                  onClick={() => handleTabClick("detail-page")}
                >
                  <div className="campaign-detail-btn-tabs">
                    <CustomText
                      p
                      // textColor={"text_color_primary"}
                      fontSize={"font_size_extra_large"}
                      text={"Details"}
                    />
                  </div>
                </div>
                <div
                  className={activeTab === "message-page" ? "active" : ""}
                  onClick={() => handleTabClick("message-page")}
                >
                  <div className="campaign-detail-btn-tabs">
                    <CustomText
                      p
                      // textColor={"text_color_primary"}
                      fontSize={"font_size_extra_large"}
                      text={"Message"}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            {/* {activeTab === "detail-page" && <ContractsDetailsScreen />}
            {activeTab === "message-page" && <MessagesScreen />} */}
            <ContractsDetailsScreen />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContractsDetailsParentScreen;
