import React ,{useState}from 'react';
import Header from '../header';
import SideMenu from '../sideMenu';
import {
    CustomText,
    CustomTextInput,PrimaryCta
  } from "../../components";
  import RichTextEditor from "react-rte";
const CreateScript=()=>{

    const toolbarConfig = {
        display: [
          "INLINE_STYLE_BUTTONS",
          "BLOCK_TYPE_BUTTONS",
          "LINK_BUTTONS",
          "BLOCK_TYPE_DROPDOWN",
          "HISTORY_BUTTONS",
        ],
        INLINE_STYLE_BUTTONS: [
          { label: "Bold", style: "BOLD", className: "custom-css-class" },
          { label: "Italic", style: "ITALIC" },
          { label: "Underline", style: "UNDERLINE" },
        ],
        BLOCK_TYPE_DROPDOWN: [
          { label: "Normal", style: "unstyled" },
          { label: "Heading Large", style: "header-one" },
          { label: "Heading Medium", style: "header-two" },
          { label: "Heading Small", style: "header-three" },
        ],
        BLOCK_TYPE_BUTTONS: [
          { label: "UL", style: "unordered-list-item" },
          { label: "OL", style: "ordered-list-item" },
        ],
        HISTORY_BUTTONS: [{}],
        LINK_BUTTONS: [{}],
      };
    const [instructionNote, setInstructionNote] = useState(
        RichTextEditor.createEmptyValue()
      );

    const [title, setTitle] = useState("");

    const ChangeTitle=(e)=>{
       setTitle(e.target.value);
     }
    const ChangeScript=(e)=>{
      setInstructionNote(e);
     }
    const Click =()=>{
      console.log("Title: ",title);
      console.log("Script:",instructionNote.toString('html')
    );
    }
    
    const [isLoading, setLoading] = useState(false);
    return(
      <div style={{display:'flex',flexDirection:'Column'}}>
        <div className="dashboard-container" >
           <SideMenu />
               <div className="main-screen">
                  <Header />
                     <div style={{ minHeight: 500}}>
                        <div className="page-container-scriptList m-l-20 m-r-20">
                           <div className="bid-list-dropdown-header-container m-l-20 m-r-20">
                            </div>
                              <div className="create-script-container" >
                                 <div className="create-script-detail-page" >
                                   <div className="m-b-8" >
                                     <CustomText
                                     p
                                     fontSize={"font_size_medium"}
                                     textColor={"text_color_primary"}
                                     text={"Title"}
                                      />
                                   </div> 
                                     <CustomTextInput
                                       invert
                                       value={title}
                                       placeholder={"Write your title here"}
                                       onChange={ChangeTitle}
                                     />
                                   </div>
                                   <div className="campaign-detail-textArea">
                                     <div className="m-b-8">
                                       {" "}
                                       <CustomText
                                       p
                                       textColor={"text_color_primary"}
                                       text={"Script for Creator"}
                                        />
                                      </div>

                                      <div className="details-text-area">
                                      <RichTextEditor
                                      toolbarConfig={toolbarConfig}
                                      value={instructionNote}
                                      placeholder={"Write your script here"}
                                      onChange={ChangeScript}
                                      />
                                      </div>
                                      <div className="divide-line2"></div>
                                         <div className='script-submit-button'>
                                         <PrimaryCta 
                                         fifth
                                         className="blue-button"
                                         fontSize={"font_size_large"}
                                         isLoading={isLoading}
                                         text={"Submit"}
                                         onClick={Click}
                                         />
                                     </div>
                                 </div>
                             </div>
                         </div>
                  </div>
          </div>
      </div>
      </div>
    );
}
export default CreateScript;