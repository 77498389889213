import React, { useEffect, useState } from "react";
import Core from "../common/clientSdk";
import Icon from "../components/core_component/icon";
import { CustomText } from "../components";
import Utils from "../common/utils";

function InfluencerContentSocialMedia({ item, brand_deep_data }) {
  const [contentAnalyticsData, setContentAnalyticsData] = useState("");
  const [loading, setLoading] = useState(false);
  const data = item;
  console.log("brand_deep_data", item);

  //   const defaultOptions = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: LoadingUI,
  //     rendererSettings: {
  //       preserveAspectRatio: "xMidYMid slice",
  //     },
  //   };

  useEffect(() => {
    if (item.campaignId) {
      AssignmentContentAnalytics(item);
    }
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function AssignmentContentAnalytics() {
    const params = {
      org_id: brand_deep_data.brand_data.org_id,
      brand_id: brand_deep_data.brand_data.brand_id,
      campaign_id: item.campaignId,
      assignment_id: item.assignmentId,
      content_id: item.content_id,
      media_url: item.url,
    };
    Core.AssignmentContentListByContent(
      AssignmentContentAnalyticsSuccess,
      AssignmentContentAnalyticsFailed,
      updateProgress,
      params
    );
  }

  function AssignmentContentAnalyticsSuccess(response) {
    setContentAnalyticsData(response.content_analytics_data);
  }
  function AssignmentContentAnalyticsFailed(errorList) {
    // toast.error(errorList.map((item) => item.m).join(", "));
  }

  return (
    <>
      {" "}
      {contentAnalyticsData && (
        <>
          <div className="content-media-card-icons-main">
            <div className="content-media-card-icons">
              <div className="flex-row">
                <Icon iconName="heartIcon" height={15} width={15} />
                <CustomText
                  p
                  text={Utils.convertToK(contentAnalyticsData?.like_count)}
                  className="caption_2 m-l-3"
                />
              </div>
              <div className="flex-row">
                <Icon iconName="commentCount" height={15} width={15} />
                <CustomText
                  p
                  text={Utils.convertToK(contentAnalyticsData?.comment_count)}
                  className="caption_2 m-l-3"
                />
              </div>
              <div className="flex-row">
                <Icon iconName="viewEyes" height={15} width={15} />
                <CustomText
                  p
                  text={Utils.convertToK(contentAnalyticsData?.play_count)}
                  className="caption_2 m-l-3"
                />
              </div>
            </div>
            <div>
              <CustomText
                p
                text={Utils.formatUnixDateToCustom(
                  contentAnalyticsData?.last_modified
                )}
                className="caption_2 m-l-3"
              />
            </div>
          </div>
          <div className="content-text-analytic-container">
            <CustomText
              p
              text={contentAnalyticsData?.caption_text}
              className="caption_2 m-l-3"
              textColor={"text_color_light"}
            />
          </div>
        </>
      )}
    </>
  );
}

export default InfluencerContentSocialMedia;
