import React, { useEffect, useState } from "react";
import { CustomText, CustomTextInput, PrimaryCta } from "../../components";
import Style from "../../resources/style";
import * as CampaignData from "../../common/data/campaign";
import Multiselect from "multiselect-react-dropdown";
import Utils from "../../common/utils";
import Core from "../../common/clientSdk";
import { useSnackbar } from "react-simple-snackbar";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../redux/actions";
import { Loader } from "../loader";
import Icon from "../../components/core_component/icon";
import VerifyEmailOtpScreen from "./verifyEmailOtpScreen";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";

function CompanySettingModal({ closeModal, setVerifyOtpModalOpen }) {
  const [isLoading, setLoading] = useState(false);
  const [pan, setPan] = useState("");
  const [gst, setGst] = useState("");
  const [company, setCompany] = useState("");
  const [premise, setPremise] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [pinCode, setPinCode] = useState("");
  const [email, setEmail] = useState("");
  const [addressData, setAddressData] = useState({});
  const [orgData, setOrgData] = useState({});
  const [state, setState] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [error, set_error] = useState({
    premise: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    email: "",
    pan: "",
    gst: "",
    company: "",
    whatsapp: "",
  });

  const navigate = useNavigate();
  const redux_dispatch = useDispatch();
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { user_data = {} } = BrandUser;

  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;
  const { org_deep_data = {} } = BrandUser;
  const openSnackbar = useSnackbar();
  function updateProgress(status) {
    setLoading(status);
  }

  useEffect(() => {
    getOrgDataUser();
  }, []);

  useEffect(() => {
    setWhatsapp(org_deep_data?.org_data.wa_number);
    setCompany(org_deep_data?.org_data.name);
    setGst(org_deep_data?.org_data?.gst_number || "");
    setPan(org_deep_data?.org_data?.pan_number || "");
    setPremise(addressData?.address_premise || "");
    setStreet(addressData?.address_street || "");
    setCity(addressData?.address_city || "");
    setState(addressData?.address_state || "");
    setEmail(user_data?.email || "");
    setPinCode(addressData?.address_pincode || "");
  }, [addressData]);

  console.log("redux_selector 555555", redux_selector);

  function postUpdateUserOrg(progress_cb) {
    const params = {
      org_id: brand_data.org_id,
      name: company,
      pan_number: pan,
      gst_number: gst,
      wa_number: whatsapp,
      address_data: JSON.stringify({
        address_premise: premise,
        address_street: street,
        address_city: city,
        address_state: state,
        address_country: country,
        address_pincode: pinCode,
      }),
    };
    if (
      validateCompany(company) &&
      validatePremise(premise) &&
      validateStreet(street) &&
      validatePAN(pan) &&
      validateCity(city) &&
      validateState(state) &&
      validateCountry(country) &&
      validatePinCode(pinCode) &&
      validateGSTIN()
    ) {
      Core.postUpdateUserOrg(
        postUpdateUserOrgSuccess,
        postUpdateUserOrgFailed,
        progress_cb,
        params
      );
    }
  }

  function postUpdateUserOrgSuccess(response_data) {
    toast.success("Company details updated");
    getOrgDataUser();

    setLoading(false);
    closeModal();
  }

  function postUpdateUserOrgFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getOrgDataUser() {
    const params = { org_id: brand_data.org_id };
    Core.getOrgDataUser(
      getOrgDataUserSuccess,
      getOrgDataUserFailed,
      updateProgress,
      params
    );
  }

  function getOrgDataUserSuccess(response_data) {
    setAddressData(response_data.org_data.address_data);
    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: {
        org_deep_data: response_data.org_data,
      },
    });
    setLoading(false);
  }

  function getOrgDataUserFailed(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }

  function postVerifyEmailOrg() {
    const params = {};

    Core.postVerifyEmailOrg(
      postVerifyEmailOrgSuccess,
      postVerifyEmailOrgFailed,
      () => {},
      params
    );
    setVerifyOtpModalOpen(true);
    closeModal();
  }

  function postVerifyEmailOrgSuccess(response_data) {
    setLoading(true);
    toast.success("We sent an OTP on your Email, check inbox");
  }

  function postVerifyEmailOrgFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
    toast.error("OTP request failed");
  }

  function validateEmail(value) {
    if (!validator.isEmail(value, {})) {
      set_error({ ...error, email: "Please enter valid email" });
    } else {
      set_error({ ...error, email: "" });
      return true;
    }
  }

  // function validatePAN(value) {
  //   if (validator.isEmpty(value, {})) {
  //     set_error({ ...error, pan: "Please enter pan number" });
  //     return false;
  //   } else if (!validator.isLength(value, { min: 10, max: 10 })) {
  //     set_error({ ...error, pan: "Please enter pan number" });
  //     return false;
  //   } else {
  //     set_error({ ...error, pan: "" });
  //     return true;
  //   }
  // }

  function validateGST(value) {
    if (validator.isEmpty(value, {})) {
      return true;
    } else if (!validator.isLength(value, { min: 15, max: 15 })) {
    } else {
      return true;
    }
  }

  function validateCompany(value) {
    if (!value && validator.isEmpty(value, {})) {
      set_error({ ...error, company: "Please enter company name" });
      return false;
    } else {
      set_error({ ...error, company: "" });
      return true;
    }
  }

  function validatePremise(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, premise: "Please enter premise" });
    } else {
      set_error({ ...error, premise: "" });
      return true;
    }
  }
  function validateStreet(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, street: "Please enter street" });
      return false;
    } else {
      set_error({ ...error, street: "" });
      return true;
    }
  }
  function validateCity(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, city: "Please enter city name" });

      return false;
    } else {
      set_error({ ...error, city: "" });
      return true;
    }
  }

  function validateState(value) {
    if (state.length == 0) {
      set_error({ ...error, state: "Please select state" });
      return false;
    } else {
      set_error({ ...error, state: "" });
      return true;
    }
  }

  function validateCountry(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, country: "Please enter country" });
      return false;
    } else {
      set_error({ ...error, country: "" });
      return true;
    }
  }

  function validatePinCode(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, pinCode: "Please enter PinCode" });
      return false;
    } else {
      set_error({ ...error, pinCode: "" });
      return true;
    }
  }
  function validateWhatsapp(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, whatsapp: "Please enter Whatsapp Number" });
      return false;
    } else {
      set_error({ ...error, whatsapp: "" });
      return true;
    }
  }

  function validateGSTIN() {
    if (gst.length > 0 && gst.length !== 15) {
      set_error({ ...error, gst: "Please enter a valid GST Number" });
      return false;
    } else if (gst.length > 0 && !Utils.validateGst(gst)) {
      set_error({ ...error, gst: "Please enter a valid GSTIN" });
    } else {
      set_error({ ...error, gst: "" });
      return true;
    }

    return true;
  }
  function validatePAN() {
    if (!pan) {
      set_error({ ...error, pan: "Please enter PAN number" });
      return false;
    }
    if (pan.length !== 10 || !Utils.validatePan(pan)) {
      set_error({ ...error, pan: "Please enter a valid PAN" });
      return false;
    } else {
      set_error({ ...error, pan: "" });
      return true;
    }
  }

  function updateAddress(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setState(value);
  }
  return (
    <Modal isOpen={true} onRequestClose={closeModal} style={customStyles1}>
      {/* <div onClick={closeModal} className="close-icon-campaign-success">
        <Icon iconName="CloseRoundButtonIcon" />
      </div> */}

      <div className="header-modal-container">
        <CustomText
          h5
          fontSize={"font_size_3xl"}
          text={`Company details`}
          textColor={"text_grey_400_color"}
          fontWeight={"Poppins_regular_large"}
          height={"line_height_24"}
          textAlign="text_align_left"
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              height: 90,
              marginTop: 30,
            }}
          >
            <div className="m-t-20" style={{ marginRight: 24, width: "50%" }}>
              <div className="m-b-4">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Company Name`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                  textAlign="text_align_left"
                />
              </div>
              <CustomTextInput
                newWidth
                className="update_input_text"
                type="name"
                placeholder="Company Name"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                onBlur={(e) => validateCompany(company)}
              />
              <h5 className="error">{error.company}</h5>
            </div>
            <div className="m-t-20 " style={{ width: "50%" }}>
              <div className="m-b-4">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`Email`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                  textAlign="text_align_left"
                />
              </div>
              <CustomTextInput
                rightBtn
                verifyClass="verify-email-full"
                hideVerifyBtn={org_deep_data?.org_data?.is_verified}
                newWidth
                onClickVerify={() => postVerifyEmailOrg()}
                text={"Verify Now"}
                className="update_input_text"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // onBlur={(e) => validateEmail(e)}
              />
            </div>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              marginTop: 24,
            }}
          >
            <div style={{ marginRight: 24, width: "50%" }}>
              <div className="m-b-4">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`PAN Number`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                  textAlign="text_align_left"
                />
              </div>
              <CustomTextInput
                newWidth
                className="update_input_text"
                type="name"
                placeholder="PAN Number"
                value={pan}
                onChange={(e) => setPan(e.target.value)}
                onBlur={(e) => validatePAN(pan)}
              />
              <h5 className="error">{error.pan}</h5>
            </div>
            <div style={{ width: "50%", height: "100px" }}>
              <div className="m-b-4">
                <CustomText
                  h5
                  fontSize={"font_size_large"}
                  text={`GST Number`}
                  textColor={"text_grey_400_color"}
                  fontWeight={"Poppins_regular_large"}
                  height={"line_height_19_2"}
                  textAlign="text_align_left"
                />
              </div>
              <CustomTextInput
                newWidth
                className="update_input_text"
                type="name"
                placeholder="GST Number"
                value={gst}
                onChange={(e) => setGst(e.target.value)}
              />
              <h5 className="error">{error.gst}</h5>
            </div>
          </div>
          <>
            <div style={{ display: "flex", flexDirection: " column" }}>
              <div style={{ height: "100px" }}>
                <div className="m-b-4">
                  <CustomText
                    h5
                    fontSize={"font_size_large"}
                    text={`Premise`}
                    textColor={"text_grey_400_color"}
                    fontWeight={"Poppins_regular_large"}
                    height={"line_height_19_2"}
                    textAlign="text_align_left"
                  />
                </div>
                <CustomTextInput
                  newWidth="new"
                  className="update_input_text"
                  type="name"
                  placeholder="Premise"
                  value={premise}
                  onChange={(e) => setPremise(e.target.value)}
                  onBlur={(e) => validatePremise(premise)}
                />
                <h5 className="error">{error.premise}</h5>
              </div>

              <div style={{ height: "100px" }}>
                <div className="m-b-4">
                  <CustomText
                    h5
                    fontSize={"font_size_large"}
                    text={`Street`}
                    textColor={"text_grey_400_color"}
                    fontWeight={"Poppins_regular_large"}
                    height={"line_height_19_2"}
                    textAlign="text_align_left"
                  />
                </div>
                <CustomTextInput
                  newWidth
                  className="update_input_text"
                  type="name"
                  placeholder="Street"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                  onBlur={(e) => validateStreet(street)}
                />
                <h5 className="error">{error.street}</h5>
              </div>
              <div style={{ height: "100px" }}>
                <div className="m-b-4">
                  <CustomText
                    h5
                    fontSize={"font_size_large"}
                    text={`City`}
                    textColor={"text_grey_400_color"}
                    fontWeight={"Poppins_regular_large"}
                    height={"line_height_19_2"}
                    textAlign="text_align_left"
                  />
                </div>
                <CustomTextInput
                  newWidth="new"
                  className="update_input_text"
                  type="name"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  onBlur={(e) => validateCity(city)}
                />
                <h5 className="error">{error.city}</h5>
              </div>
              <div style={{ height: "80px" }}>
                <div className="m-b-4">
                  <CustomText
                    h5
                    fontSize={"font_size_large"}
                    text={`State`}
                    textColor={"text_grey_400_color"}
                    fontWeight={"Poppins_regular_large"}
                    height={"line_height_19_2"}
                    textAlign="text_align_left"
                  />
                </div>
                <Multiselect
                  selectedValues={Object.values(
                    Utils.getSelectedStateObjects([addressData?.address_state])
                  )}
                  placeholder="Select State"
                  id="css_custom"
                  options={Object.values(CampaignData.LOCATIONS)}
                  onSelect={(selectedList, selectedItem) =>
                    updateAddress(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateAddress(selectedList, selectedItem)
                  }
                  singleSelect={true}
                  displayValue={"n"}
                  showArrow={false}
                  style={{
                    chips: Style.chipsAddress,
                    option: Style.option,
                    optionContainer: Style.optionContainer,
                    multiselectContainer: {
                      ...Style.multiselectContainerAddress,
                    },
                    searchBox: {
                      ...Style.searchBoxAddress,
                      border: "none",
                    },
                    inputField: {
                      ...Style.inputFieldAddress,
                    },
                  }}
                  className="custom-multiselect"
                />

                <h5 className="error">{error.state}</h5>
              </div>
            </div>

            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginTop: 16,
              }}
            >
              <div style={{ marginRight: 24, width: "50%" }}>
                <div className="m-b-4">
                  <CustomText
                    h5
                    fontSize={"font_size_large"}
                    text={`Country`}
                    textColor={"text_grey_400_color"}
                    fontWeight={"Poppins_regular_large"}
                    height={"line_height_19_2"}
                    textAlign="text_align_left"
                  />
                </div>
                <CustomTextInput
                  newWidth
                  className="update_input_text"
                  type="name"
                  placeholder="Country"
                  disabled
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  onBlur={(e) => validateCountry(country)}
                />
                <h5 className="error">{error.country}</h5>
              </div>
              <div style={{ width: "50%" }}>
                <div className="m-b-4">
                  <CustomText
                    h5
                    fontSize={"font_size_large"}
                    text={`Pin code`}
                    textColor={"text_grey_400_color"}
                    fontWeight={"Poppins_regular_large"}
                    height={"line_height_19_2"}
                    textAlign="text_align_left"
                  />
                </div>
                <CustomTextInput
                  newWidth
                  className="update_input_text"
                  type="name"
                  placeholder="Pincode"
                  value={pinCode}
                  onChange={(e) => setPinCode(e.target.value)}
                  onBlur={(e) => validatePinCode(pinCode)}
                />
                <h5 className="error">{error.pinCode}</h5>
              </div>
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                marginTop: 24,
                height: 90,
              }}
            >
              {" "}
              <div style={{ width: "50%", marginRight: 24 }}></div>
              <div className="m-t-20" style={{ width: "50%" }}>
                <PrimaryCta
                  fontSize={"font_size_large"}
                  fullWidth100
                  isLoading={isLoading}
                  text={"Save Details"}
                  onClick={() => postUpdateUserOrg(updateProgress)}
                  // disabled={isLoading ? true : false}
                />
              </div>
            </div>
          </>
        </div>
      )}
    </Modal>
  );
}

export default CompanySettingModal;

const customStyles1 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var( --blue-blue-500)",
    textAlign: "center",
    zIndex: "5",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-around",
    width: "660px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};
