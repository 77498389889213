import React, { useState, useEffect} from "react";
import {
  CustomText,
  CustomTextInput,
  PrimaryCta,
  Sidebar,
} from "../components";
import Core from "../common/clientSdk";
import * as CampaignData from "../common/data/campaign";
import { toast, ToastContainer } from "react-toastify";
import * as LoadingUI from "../resources/data/loading.json";
import Select from "react-dropdown-select";
const options = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "all", label: "All" },
];
const platformTypes = [
  {
    platformName: "btll",
    label: "Instagram",
  },
  {
    platformName: "nxjo",
    label: "Youtube",
  },
];

function AddInfluencerSidebar({ addInfluencer, setAddInfluencer }) {
  const [addInfluencerPlatformId, setAddInfluencerPlatformId] = useState([
    {
      platformName: "btll",
      label: "Instagram",
    },
  ]);
  const [platformHandle, setPlatformHandle] = useState("");
  const [country, setCountry] = useState(null);
  const [addInfluencerLoading, setAddInfluencerLoading] = useState(false);
  const [addAndViewInfluencerLoading, setAddAndViewInfluencerLoading] =
    useState(false);
  const [phoneNumber, setPhoneNumber] = useState([]);
  const [addInfluencerProfession, setAddInfluencerProfession] = useState([]);
  const [addInfluencerGender, setAddInfluencerGender] = useState([]);

  function updateProgressAddInfluencerLoading(status) {
    setAddInfluencerLoading(status);
  }
  function updateProgressAddAndViewInfluencer(status) {
    setAddAndViewInfluencerLoading(status);
  }
  function validationAddInfluencer() {
    if (!platformHandle) {
      toast.error("Please enter social handle");
      return false;
    }
    return true;
  }
  function postAddInfluencers( action) {
    const params = {
      identifier_id: platformHandle,
      platform_id: addInfluencerPlatformId,
      influencer_phone: phoneNumber,
      influencer_gender: addInfluencerGender,
      influencer_country: country,
      profession: addInfluencerProfession,
    };
    if (validationAddInfluencer()) {
      Core.postAddInfluencers(
        (...args) => postAddInfluencersSuccess(action, ...args),
        postAddInfluencersFailed,
        ()=>{},
        params
      );
    }
  }

  function postAddInfluencersSuccess(action, args, response) {
    toast.success("Influencer Added Successfully");
    setAddInfluencerGender([]);
    setCountry(null);
    setPhoneNumber(null);
    setPlatformHandle("");
    if (action == "influencer_view") {
      const influencerId = response?.response?.influencer_data?.influencer_id;
      console.log("influencerId", influencerId);

      if (influencerId) {
        window.open(`influencer/${influencerId}`, "_blank");
      }
    }
  }

  function postAddInfluencersFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  
  function updatePlatform(value) {
    setAddInfluencerPlatformId(value);
  }
  function getAllProfession() {
    let all_profession = [];
    Object.keys(CampaignData.FULL_CATEGORIES.subcategories).forEach((key) => {
      CampaignData.FULL_CATEGORIES.subcategories[key].forEach((item) => {
        let value = item["id"];
        let label = item["name"];
        all_profession.push({ value, label });
      });
    });
    return all_profession;
  }
  function addNewInfluencerProfession(selectedItem) {
    setAddInfluencerProfession(selectedItem);
  }
  function updateAddInfluencerGender(value) {
    setAddInfluencerGender(value ? value[0].value : []);
  }
  const addInfluencerSidebarHeader = () => (
    <CustomText p text={"Add Influencer"} className="side-bar-heading-text" />
  );
  const addInfluencerSidebarFooter = () => (
    <div style={{ display: "flex",justifyContent:"center",alignItems:"center", gap: "10px" }}>
      <div>
        <PrimaryCta
          fifth
          onClick={() => postAddInfluencers(updateProgressAddInfluencerLoading)}
          fontSize={"font_size_large"}
          text={"Add"}
          isLoading={addInfluencerLoading}
        />
      </div>
      <div style={{ marginRight: "5px" }}>
        <PrimaryCta
          fifth
          onClick={() =>
            postAddInfluencers(
              updateProgressAddAndViewInfluencer,
              "influencer_view"
            )
          }
          fontSize={"font_size_large"}
          text={"Add & View"}
          isLoading={addAndViewInfluencerLoading}
        />
      </div>
    </div>
  );
  return (
    <>
      <Sidebar
        sidebarWidth={"30%"}
        isOpen={addInfluencer}
        onClose={() => setAddInfluencer(false)}
        Header={addInfluencerSidebarHeader}
        Footer={addInfluencerSidebarFooter}
        headerAlignment="center"
      >        
          <div>
            <div className="">
                <div style={{ textAlign: "left",marginBottom:"20px" }}>
                  <div
                    style={{ gap: 5, zIndex: 10 }}
                    className="m-b-5 flex-row"
                  >
                    <CustomText
                      p
                      fontSize={"font_size_medium"}
                      textColor={"text_color_primary"}
                      text={"Platform"}
                      textAlign={"text_align_left"}
                    />
                  </div>

                  <Select
                    multi={false}
                    options={platformTypes}
                    values={addInfluencerPlatformId}
                    labelField="label"
                    valueField="platformName"
                    onChange={(value) => updatePlatform(value)}
                    placeholder="Select Platform"
                    autoFocus={false}
                    color="#c8c2c2"
                    // Customcolor
                    style={{
                      border: "1px solid rgb(204, 204, 204)",
                      zIndex: "100",
                      backgroundColor: "#fff",
                      borderRadius: 4,
                      padding:10,
                      option: {
                        backgroundColor: "red",
                      },
                    }}
                  />
                </div>
              
            </div>
            <div className="">
              <div
                style={{
                  overflowX: "hidden",
                  overflowY: "auto",
                  width: "100%",
                  paddingRight: 20,
                  paddingBottom: 20,
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <div style={{ gap: 5 }} className="m-b-5 flex-row">
                    <CustomText
                      p
                      fontSize={"font_size_medium"}
                      textColor={"text_color_primary"}
                      text={"Platform Handle"}
                      textAlign={"text_align_left"}
                    />
                  </div>
                  <CustomTextInput                    
                    type="text"
                    value={platformHandle}
                    placeholder="Platform Handle"
                    onChange={(e) => {
                      setPlatformHandle(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="" style={{ textAlign: "left" }}>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Phone Number(Optional)"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                 
                  type="text"
                  value={phoneNumber}
                  placeholder="Phone Number"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="m-t-20">
              <div className="m-b-5">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Profession(Optional)"}
                  textAlign={"text_align_left"}
                />
              </div>
              <Select
                multi={false}
                options={getAllProfession()}
                values={addInfluencerProfession}
                onChange={(value) => addNewInfluencerProfession(value)}
                placeholder="Select profession"
                autoFocus={false}
                color="#c8c2c2"
                // Customcolor
                style={{
                  border: "1px solid rgb(204, 204, 204)",
                  backgroundColor: "#fff",
                  borderRadius: 4,
                  padding:10,
                  marginTop: "10px",
                  option: {
                    backgroundColor: "red",
                  },
                }}
              />
            </div>
            <div className="m-t-20" style={{ textAlign: "left" }}>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Gender(Optional)"}
                  textAlign={"text_align_left"}
                />
              </div>

              <div>
                <Select
                  multi={false}
                  options={options}
                  values={addInfluencerGender}
                  onChange={(value) => updateAddInfluencerGender(value)}
                  placeholder="Select Gender"
                  autoFocus={false}
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "1px solid rgb(204, 204, 204)",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    padding:10,
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />
              </div>
            </div>
            <div className="m-t-20 " style={{ textAlign: "left" }}>
              <div style={{ gap: 5 }} className="m-b-5 flex-row">
                <CustomText
                  p
                  fontSize={"font_size_medium"}
                  textColor={"text_color_primary"}
                  text={"Country(Optional)"}
                  textAlign={"text_align_left"}
                />
              </div>
              <div>
                <CustomTextInput
                  type="text"
                  placeholder="Country"
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
            </div>
          </div>
        
      </Sidebar>
      <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          zIndex={9999999}
        />
    </>
  );
}

export default AddInfluencerSidebar;
