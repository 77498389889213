import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CustomText } from "../../components";
import Icon from "../../components/core_component/icon";
import { InstagramEmbed } from "react-social-media-embed";
import Core from "../../common/clientSdk";
import Utils from "../../common/utils";
import { toast } from "react-toastify";

function InstagramAnalytics(props) {
  const [contentAnalyticsData, setContentAnalyticsData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (props.campaignId) {
      AssignmentContentAnalytics();
    }
  }, []);

  function updateProgress(status) {
    setLoading(status);
  }

  function AssignmentContentAnalytics() {
    const params = {
      org_id: props.orgId,
      brand_id: props.brandId,
      campaign_id: props.campaignId,
      assignment_id: props.assignmentId,
      content_id: props.contentId,
      media_url: props.mediaUrl,
    };
    Core.AssignmentContentListByContent(
      AssignmentContentAnalyticsSuccess,
      AssignmentContentAnalyticsFailed,
      updateProgress,
      params
    );
  }

  function AssignmentContentAnalyticsSuccess(response) {
    setContentAnalyticsData(response.content_analytics_data);
  }
  function AssignmentContentAnalyticsFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function renderAnalyticsContent() {
    if (contentAnalyticsData) {
      return (
        <div
          style={{ textAlign: "center" }}
          className="content-like-view-count"
        >
          <div style={{ paddingLeft: 5 }} className="flex-row">
            {contentAnalyticsData.like_count && (
              <div className="flex-row">
                <Icon iconName="heartIcon" />
                <CustomText
                  p
                  text={Utils.convertToK(contentAnalyticsData.like_count)}
                  className="subtitle1"
                />
              </div>
            )}

            {contentAnalyticsData.play_count && (
              <div className="flex-row">
                <Icon iconName="viewEyes" />
                <CustomText
                  p
                  text={Utils.convertToK(contentAnalyticsData.play_count)}
                  className="subtitle1"
                />
              </div>
            )}

            {contentAnalyticsData.comment_count && (
              <div className="flex-row">
                <Icon iconName="commentCount" />
                <CustomText
                  p
                  text={Utils.convertToK(contentAnalyticsData.comment_count)}
                  className="subtitle1"
                />
              </div>
            )}
          </div>
          <div style={{ paddingRight: 5 }}>
            <CustomText
              p
              text={Utils.convertUnixToHumanSortReadable(props.dateModified)}
              className="subtitle1"
            />
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
    <div
      style={{
        border: "1px solid rgb(219, 219, 219)",
      }}
    >
      <div className="">
        <InstagramEmbed
          url={props.mediaUrl}
          width={props.width ? props.width : 300}
          height={props.height ? props.height : 400}
        />
      </div>
      {renderAnalyticsContent()}
    </div>
  );
}

export default InstagramAnalytics;
