import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Icon from "../../components/core_component/icon";
import Core from "../../common/clientSdk";
import { useNavigate } from "react-router-dom";
import {
  CustomText,
  FallBack,
  FallBackBtn,
  PrimaryCta,
} from "../../components";
import CreateCollectionList from "./collectionList";
import CreateCollectionModal from "./createCollection";
import Utils from "../../common/utils";
import { Loader } from "../loader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const customStylesModal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var(--gray-gray-50)",
    textAlign: "center",
    zIndex: "5",
    minHeight: "70%",
    height: "70%",
    display: "flex",
    flexDirection: "column",

    paddingLeft: 20,
    width: "600px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};

const CreateCollectionGrid = ({
  closeModal,
  id,
  campaignId,
  onSuccessModalCollectionGrid,
  collection_data,
  selectedInfluencerRows,triggeredBy
}) => {
  const [collectionList, setCollectionList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(false);
  const [collectionId, setCollectionId] = useState(
    collection_data?.collection_id
  );
  const [collectionData, setCollectionData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingAddToList,setIsLoadingAddToList]=useState(false)
  console.log("collectionId", collectionId);
  const navigate = useNavigate();

  useEffect(() => {
    getCollectionList();
  }, []);

  const redux_selector = useSelector((state) => {
    console.log("state", state);
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { user_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;

  console.log("collectionList.length", collectionList.length);

  function getCollectionList() {
    const params = {
      org_id: brand_data?.org_id,
    };
    Core.getCollectionList(
      getCollectionListSuccess,
      getCollectionListFailed,

      (status) => setLoading(status),
      params
    );
  }

  function getCollectionListSuccess(response_data) {
    if (!collection_data?.collection_id) {
      setCollectionId(response_data?.collection_list[0].collection_id);
      setCollectionData(response_data?.collection_list[0]);
    }

    setCollectionList(response_data.collection_list);
    if (response_data?.collection_list?.length == 0) {
      openModalCollection();
    }
  }

  function getCollectionListFailed(errorList) {
    console.error("Failed to fetch collections", errorList);
  }

  function updateProgressShortlist(status){
    setIsLoadingAddToList(status)
  }

  function addToListShortListChecker(){
    if(triggeredBy === "shortlistAll"){
      shortlistAll()
    }else{
      postAddInfluencerCollection()
    }
  }
  function shortlistAll(){
    let influencer_data = {
      influencer_ids: selectedInfluencerRows,
      collection_id: collectionId,
    };
    const params = {
      influencer_data: JSON.stringify(influencer_data),
    };
    Core.postShortlistAll(
      shortlistAllSuccess,
      shortlistAllFailed,
      updateProgressShortlist,
      params
    );
  }

  function shortlistAllSuccess(){
    toast.success("Influencers Added to List Successfully");
    closeModal();
    if (typeof onSuccessModalCollectionGrid == "function") {
      onSuccessModalCollectionGrid();
    }
  
  }
  function shortlistAllFailed(errorList){
    toast.error(errorList.map((item) => item.m).join(", "));
  }
  function postAddInfluencerCollection() {
    const params = {
      influencer_id: id,
      collection_id: collectionId,
    };
    Core.postAddInfluencerCollection(
      postAddInfluencerCollectionSuccess,
      postAddInfluencerCollectionFailed,
      updateProgressShortlist,
      params
    );
  }

  function postAddInfluencerCollectionSuccess(response_data) {
    toast.success("Influencer Added to List Successfully");
    if (typeof onSuccessModalCollectionGrid == "function") {
      onSuccessModalCollectionGrid(collectionData);
    }
    closeModal();
    // collectionInfluencerPage(
    //   response_data.influencer_collection_data.collection_id
    // );
  }

  function postAddInfluencerCollectionFailed(errorList) {
    console.log("errorList", errorList);
    if (typeof onSuccessModalCollectionGrid == "function") {
      onSuccessModalCollectionGrid(collectionData);
    }
    closeModal();
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function collectionInfluencerPage(collection_id) {
    navigate(`/collection-influencer/${collection_id}`);
  }

  const openModalCollection = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModalCollection = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={true}
        onRequestClose={closeModal}
        style={customStylesModal}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            background: "#ffffff",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 54,
          }}
        >
          <div onClick={closeModal} className="close-btn-icon">
            <Icon iconName="CloseRoundButtonIcon" />
          </div>

          <CustomText
            p
            text={"List"}
            textColor={"text_color_primary"}
            fontSize={"font_size_2xl"}
            className="typographyH7"
          />
        </div>
        <div className="collection-grid-card-container">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {collectionList.length == 0 ? (
                <div className="small-fallback-container">
                  <FallBackBtn
                    heading={"Oops!! you don't have any list"}
                    title={
                      "You can add your shortlisted influencers in list, so that you can share them with anyone and get feedback"
                    }
                    widthIcon={100}
                    heightIcon={100}
                    onClick={openModalCollection}
                    text={"Create List"}
                  />
                </div>
              ) : (
                <>
                  <div className="collection-grid-container">
                    {collectionList.map((collection, index) => (
                      <div
                        key={index}
                        className={
                          collectionId !== collection.collection_id
                            ? "collection-grid-card"
                            : "collection-grid-card-active"
                        }
                        onClick={() => {
                          setSelectedCard(true);
                          setCollectionId(collection.collection_id);
                          setCollectionData(collection);
                        }}
                      >
                        <div className="collection-grid-card-name">
                          <CustomText
                            p
                            text={collection.collection_name}
                            className="width-200 Caption-3"
                          />
                          {/* <CustomText
                    p
                    text={collection.collection_name}
                    fontSize={"font_size_regular"}
                    textColor={"text_color_black"}
                    textAlign={"text_align_left"}
                  /> */}
                        </div>
                        <div className="collection-grid-date">
                          <CustomText
                            p
                            text={`Created on:`}
                            className="width-200  typographyH5"
                          />
                          <CustomText
                            p
                            text={Utils.convertUnixToDate(
                              collection.date_added,
                              user_data.time_zone
                            )}
                            //  fontSize={"font_size_tag"}
                            textColor={"text_color_gray"}
                            className="caption_2_10"
                            // textAlign={"text_align_left"}
                          />
                        </div>
                      </div>
                    ))}
                  </div>{" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      padding: "10px 0",
                    }}
                  ><div>
                    <PrimaryCta
                      invert
                      // className="confirm-btn-bid "
                      fontSize={"font_size_large"}
                      onClick={openModalCollection}
                      text="Create New"
                    />
                  </div>
                    
                    <div className=" m-l-32">
                      <PrimaryCta
                        fifth
                        onClick={() => addToListShortListChecker()}
                        fontSize={"font_size_large"}
                        className="blue-button"
                        text={"Add to List"}
                        isLoading={isLoadingAddToList}
                        disabled={!collectionId}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Modal>
      {modalIsOpen && (
        <CreateCollectionModal
          closeModal={closeModalCollection}
          getCollectionList={getCollectionList}
          campaignId={campaignId}
        />
      )}
    </>
  );
};

export default CreateCollectionGrid;
