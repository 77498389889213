import React, { useEffect, useState } from "react";
import { Image } from "../../components";
const NewLoginLeftSide = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselData = [
    {
      name: "Gaurav Khare",
      designation: "Marketing Manager, Malabar Gold",
      brandComment:
        "Reelax helped Malabar to streamline the campaigns and track KPIs, that helps to make key decisions",
      img: "//upload.wikimedia.org/wikipedia/en/thumb/f/f4/Malabar_Gold_and_Diamonds_New_Logo.jpg/220px-Malabar_Gold_and_Diamonds_New_Logo.jpg",
    },
    {
      name: "Kajal",
      designation: "Marketing manager, Wellfa Nutrition",
      brandComment:
        "Transparent platform helped to get desired content from influencers, Influencer chat feature is very helpful",
      img: "https://getreelax.com/wellfa-1.png",
    },
    {
      name: "Alok",
      designation: "CEO, Educase.io",
      brandComment:
        "Reelax has largest database of influencer, the day Educase shifted to Reelax we save a lot in agency cost.",
      img: "https://getreelax.com/educase.png",
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselData.length);
  };

  // Automatically change slide every 3 seconds
  useEffect(() => {
    const interval = setInterval(nextSlide, 3000); // Change every 3 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);
  return (
    <div className="login-left-side">
      <div className="overlay-container">
        <div className="overlay-container-h1">
          #1 Influencer
          <br />
          Marketing Platform
        </div>
        <div style={{ marginTop: "2%", marginBottom: "5%" }}>
          Connect with 2.5 lakh+ influencers and take full control of your
          campaigns, get real time alerts and analytics.
        </div>
        <div className="login-testimonial">
          <div className="login-testimonial-p-1">
            {carouselData[currentIndex].brandComment}
          </div>
          <div className="login-testimonial-p2">
            <div className="login-testimonial-data">
              <div className="">
                <Image
                  className="login-testimonial-image"
                  source={carouselData[currentIndex].img}
                  alt="carousel"
                  width={46}
                  height={46}
                />
              </div>
              <div className="login-testimonial-branddata">
                <div className="login-testimonial-brandname">
                  {carouselData[currentIndex].name}
                </div>
                <div className="login-testimonial-p-2">
                  {carouselData[currentIndex].designation}
                </div>
              </div>
            </div>
            <div className="inverted-comma">
              <Image source={"../assets/images/inverted-comma.png"} />
            </div>
          </div>
        </div>
        <div className="dots-container">
          {carouselData.map((_, index) => (
            <span
              key={index}
              className={`dot ${
                currentIndex === index ? "active" : ""
              }`}></span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewLoginLeftSide;
