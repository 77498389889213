import React, { useEffect, useState } from "react";
import {
  Image,
  CustomText,
  FallBack,
  Video,
  PrimaryCta,
  InstagramComponent,
} from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { InstagramEmbed, YouTubeEmbed, XEmbed } from "react-social-media-embed";
import { ToastContainer, toast } from "react-toastify";
import Core from "../../common/clientSdk";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import InfiniteScroll from "react-infinite-scroll-component";
import Utils from "../../common/utils";
import { Loader } from "../loader";
import { useSelector } from "react-redux";

import InstagramAnalytics from "./contentInstagramAnalytics";
import YoutubeAnalytics from "./contentYoutubeAnalytics";
const limit = 10;
function ContentList(props, { campaignId }) {
  const [contentList, setContentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contentId, setContentId] = useState(null);
  const [assignmentId, setAssignmentId] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(10);

  useEffect(() => {
    AssignmentContentList(start, updateProgress);
  }, []);

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  console.log("brand_deep_data", brand_deep_data);

  function updateProgress(status) {
    // setLoading(status);
  }

  function AssignmentContentList(start, updateProgress) {
    const params = {
      limit: limit,
      start: start,
      brand_id: brand_deep_data.brand_data.brand_id,
      campaign_id: props.campaignId,
    };
    Core.AssignmentContentList(
      AssignmentContentListSuccess,
      AssignmentContentListFailed,
      updateProgress,
      params
    );
  }

  function AssignmentContentListSuccess(response) {
    setLoading(false);

    if (next == 0) {
      setContentList(
        response?.assignment_list?.filter((item) => {
          return item?.assignment_data?.status == "COMPLETED";
        })
      );
    } else {
      setContentList([
        ...contentList,
        ...response?.assignment_list?.filter((item) => {
          return item?.assignment_data?.status == "COMPLETED";
        }),
      ]);
    }

    setNext(response?.pagination_data?.next);
  }

  console.log("contentList 1", contentList);

  function AssignmentContentListFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  const handleDownload = (media_id, isVideo, assignment_id) => {
    const mediaUrl = Utils.constructContentMediaUrl(assignment_id, media_id);

    // Create a temporary anchor element to trigger the download
    const a = document.createElement("a");
    a.href = mediaUrl;
    a.download = `downloaded_${isVideo ? "video" : "image"}.${media_id
      .split(".")
      .pop()}`;
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    // Clean up the anchor element
    document.body.removeChild(a);
  };

  if (!props.isActive) {
    return <></>;
  }

  return (
    <div style={{ minHeight: 500 }} className="m-t-12">
      <InfiniteScroll
        dataLength={contentList && contentList.length}
        next={() => AssignmentContentList(next, updateProgress)}
        hasMore={next !== null}
        loader={""}
      >
        <div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {contentList.length !== 0 ? (
                <>
                  <Masonry columnsCount={3} gutter="10px">
                    {contentList.map((item) => {
                      const platform = Utils.socialMediaPlatform(
                        item?.assignment_data.content_type_id
                      );

                      {
                        /* if(platform == "Instagram"){
                        setContentId(item.content_id),
                        setAssignmentId(item.assignment_id),
                        setMediaUrl(item.media_id)
                      } */
                      }

                      const mediaType =
                        item?.assignment_data?.media_id?.split(".").pop() ==
                        "mp4";
                      if (item?.assignment_data?.status == "COMPLETED") {
                        return (
                          <>
                            {item.assignment_data.url && (
                              <>
                                {platform == "Instagram" && (
                                  <InstagramAnalytics
                                    campaignId={campaignId}
                                    contentId={item.assignment_data?.content_id}
                                    mediaUrl={item.assignment_data.url}
                                    assignmentId={
                                      item?.assignment_data.assignment_id
                                    }
                                    dateModified={
                                      item.assignment_data?.date_modified
                                    }
                                    brandId={
                                      brand_deep_data.brand_data.brand_id
                                    }
                                    orgId={brand_deep_data.brand_data.org_id}
                                  />
                                )}
                              </>
                            )}
                            {item.assignment_data.url && (
                              <div className="embed-container">
                                {platform === "youtube" && (
                                  <YoutubeAnalytics
                                    campaignId={campaignId}
                                    contentId={item.assignment_data?.content_id}
                                    mediaUrl={item.assignment_data.url}
                                    assignmentId={
                                      item?.assignment_data.assignment_id
                                    }
                                    dateModified={
                                      item.assignment_data?.date_modified
                                    }
                                    brandId={
                                      brand_deep_data.brand_data.brand_id
                                    }
                                    orgId={brand_deep_data.brand_data.org_id}
                                  />
                                )}
                              </div>
                            )}
                            {item.assignment_data?.url && (
                              <div className="embed-container">
                                {platform === "twitter" && (
                                  <XEmbed
                                    url={item.assignment_data?.url}
                                    width={325}
                                  />
                                )}
                              </div>
                            )}

                            {item.assignment_data?.media_id &&
                              mediaType &&
                              !item.assignment_data?.url && (
                                <div className="download-content">
                                  <Video
                                    source={Utils.constructContentMediaUrl(
                                      item?.assignment_data.assignment_id,
                                      item.assignment_data?.media_id
                                    )}
                                    width={325}
                                    height={620}
                                    className="content-media"
                                  />
                                  <PrimaryCta
                                    fifth
                                    onClick={() =>
                                      handleDownload(
                                        item.assignment_data?.media_id,
                                        true,
                                        item.assignment_data.assignment_id
                                      )
                                    }
                                    className="blue-button"
                                    fontSize={"font_size_large"}
                                    text={"Download"}
                                    isLoading={""}
                                  />
                                </div>
                              )}

                            {item.assignment_data.media_id &&
                              !mediaType &&
                              !item.assignment_data.url && (
                                <div className="download-content">
                                  <Image
                                    source={Utils.constructContentMediaUrl(
                                      item.assignment_data.assignment_id,
                                      item.assignment_data.media_id
                                    )}
                                    width={325}
                                    height={520}
                                    className="content-media"
                                  />

                                  <PrimaryCta
                                    fifth
                                    onClick={() =>
                                      handleDownload(
                                        item.assignment_data.media_id,
                                        true,
                                        item.assignment_data?.assignment_id
                                      )
                                    }
                                    className="blue-button"
                                    fontSize={"font_size_large"}
                                    text={"Download"}
                                    isLoading={""}
                                  />
                                </div>
                              )}
                          </>
                        );
                      }
                    })}
                  </Masonry>
                </>
              ) : (
                <>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                    className="fall-back-center-campaign"
                  >
                    <FallBack
                      heading={"No Creatives Available"}
                      // title={"Please wait until you get a new proposal"}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default ContentList;
