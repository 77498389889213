import React from "react";
import { CustomText, Image, PrimaryCta } from "../../components";
import Modal from "react-modal";
import Icon from "../../components/core_component/icon";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import * as CampaignSuccess from "../../resources/data/campaign_success.json";
function CreateCampaignSuccess({ closeModal }) {
  var navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CampaignSuccess,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Modal isOpen={true} onRequestClose={closeModal} style={customStyles1}>
        {/* <div onClick={closeModal} className="close-icon-campaign-success">
          <Icon iconName="CloseRoundButtonIcon" />
        </div> */}
        <div>
          <div className="m-b-12">
            {/* <Image
              source={require("../../resources/images/campaign_success.gif")}
            /> */}

            <Lottie options={defaultOptions} height={200} width={200} />

            <CustomText
              h5
              text={"Campaign created successfully"}
              className="typography"
              textColor="text_color_primary"
            />
          </div>
          <CustomText
            p
            text={
              "Your campaign is under review, It takes 15-20 minutes to get the campaign approved."
            }
            className="typography14"
            textColor={"text_light_weight_color"}
          />
          {/* <div className="m-t-24 full-width">
            <PrimaryCta
              fontSize={"font_size_large"}
              fullWidth={true}
              text={"Go to Campaigns"}
              onClick={() => navigate("/campaigns")}
              // isLoading={loading}
              // disabled={Utils.isNull(email) || Utils.isNull(password)}
            />
          </div> */}
        </div>
      </Modal>
    </>
  );
}

export default CreateCampaignSuccess;

const customStyles1 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var( --blue-blue-500)",
    textAlign: "center",
    zIndex: "5",
    height: "70%",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-around",
    width: "660px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};
