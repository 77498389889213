import React from "react";
import { CustomTextInput, PrimaryCta, CustomText } from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useState } from "react";
import Core from "../../common/clientSdk";
import validator from "validator";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useSnackbar } from "react-simple-snackbar";

function EmailVerifyScreen() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const [error, set_error] = useState({
    member_name: "",

    email: "",
  });
  var navigate = useNavigate();

  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { user_data = {} } = BrandUser;

  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;

  const { email: userEmail } = user_data;

  useEffect(() => {
    setEmail(userEmail);
  }, [userEmail]);

  function updateProgress(status) {
    setLoading(status);
  }

  function postVerifyEmailOrg(progress_cb) {
    const params = {};
    Core.postVerifyEmailOrg(
      postVerifyEmailOrgSuccess,
      postVerifyEmailOrgFailed,
      progress_cb,
      params
    );
  }

  function postVerifyEmailOrgSuccess(response_data) {
    navigate("/email-verify-otp");
    setLoading(true);
    openSnackbar("Send Otp");
  }

  function postVerifyEmailOrgFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
    openSnackbar("Send Otp Fail");
  }

  function validateEmail(value) {
    if (!validator.isEmail(value, {})) {
      set_error({ ...error, email: "Please enter valid email" });
    } else {
      set_error({ ...error, email: "" });
      return true;
    }
  }

  return (
    <div className="dashboard-container background-color-primary">
      <SideMenu />

      <div className="main-screen">
        <Header />
        <div className="page-container">
          <div className="campaign-text-heading">
            <CustomText
              p
              text={"Verify Email"}
              textColor={"text_color_light"}
              fontSize={"font_size_2xl"}
            />
          </div>

          <div className="setting-screen-main-container">
            {/* <div className="m-b-12 p-t-10">
              <CustomText
                p
                text={"Enter Email"}
                textColor={"text_color_light"}
                fontSize={"font_size_xl"}
              />
            </div> */}

            <div className="m-t-15 full-width">
              <CustomTextInput
                className="custom-input-text"
                placeholder="Enter Email"
                value={email}
                disabled
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => validateEmail(e.target.value)}
              />
              <h5 className="error">{error.email}</h5>
            </div>
            <div className="m-t-15 full-width">
              <PrimaryCta
                fontSize={"font_size_large"}
                fullWidth={true}
                text={"Verify Email"}
                isLoading={loading}
                onClick={() => postVerifyEmailOrg(updateProgress)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerifyScreen;
