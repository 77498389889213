import Theme from "../../resources/themes/light.js";

const Style = {
  h1Primary: {},

  customSidebar: {
    //backgroundColor: '#DF816D',
    height: "100vh",
    width: "300px",
    border: "1px solid #000000",
    listStyle: "none",
    TextDecoraation: "none",
  },

  topHeading: {
    marginTop: "22px",
    letterSpacing: " 0.08px",
    display: "flex",
    flexDirection: "row",
  },
  campaignItemText: {
    height: "63px",
    width: "100px",
    fontSize: "16px",
    textAlign: "left",
  },

  nonSelectedCheckBoxContainer: {
    flex: 1,
    width: "100%",
    height: 42,
    backgroundColor: "#FFFFFF",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    paddingRight: 16,
    paddingLeft: 16,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#DDDDDD",
    borderRadius: 2,
    cursor: "pointer",
  },
  selectedCheckBoxContainer: {
    flex: 1,
    width: "100%",
    height: 44,
    backgroundColor: "#6C25FF",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    paddingRight: 16,
    paddingLeft: 16,
    border: "none",
    borderRadius: 2,
    outline: "none",
  },
  checkbox: {
    backgroundColor: "#6C25FF",
    borderColor: "red",
    borderWidth: 2,
  },
  checkboxTitle: {
    color: "#B3B3B3",
    fontSize: Theme.dimensions.LARGE,
    fontFamily: Theme.fonts.REGULAR,
  },
  nonSelectedcheckboxTitle: {
    fontSize: Theme.dimensions.LARGE,
    color: "#FFFFFF",
    fontFamily: Theme.fonts.REGULAR,
  },
  proposalsListContainer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: Theme.colors.BC_CARD,
    padding: 14,
    borderRadius: 8,
    gap: 118,
    marginTop: 12,
    marginBottom: 12,
  },
  TeamMembersInnerContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "start",
  },

  TeamMembersMainContainer: {
    display: "flex",
    justifyContent: "space-between",
    // marginBottom: 10,
    // paddingLeft: 16,
    // paddingRight: 14,
    // paddingTop: 24,
    // paddingBottom: 24,
    padding: 10,
    borderRadius: 6,
    // gap: 118,
    backgroundColor: "var(--general-white-color)",
    alignItems: "center",
  },
  
  CompanyProfileHide: {
    display: "none",
  },
  ProfileIcon: {
    cursor: "pointer",
    marginTop: 10,
    marginLeft: 25,
  },
  CompanyProfile: {},

  TransitionHistoryMainContainer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: Theme.colors.BC_CARD,
    paddingLeft: 16,
    paddingRight: 14,
    paddingTop: 24,
    paddingBottom: 24,
    borderRadius: 8,
    gap: 118,
    marginBottom: 10,
  },
  proposalsListContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "var(--general-white-color)",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 12,
    paddingBottom: 24,
    borderRadius: 6,
    gap: 16,
    height: 300,
    minWidth: "296px",
    width: "28%",
    position: "relative",
    marginRight: 12,
    marginBottom: 16,
  },

  CounterStyle: {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 20,
      transform: "translate(-50%, -50%)",
      background: "var(--general-secondary-color)",
      borderColor: "var( --blue-blue-500)",
      textAlign: "center",
      zIndex: "5",
      minHeight: "40%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      minWidth: 352,
      justifyContent: "space-between",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.86)",
      zIndex: 10,
    },
  },
};

export default Style;
