import react, { useState } from "react";
import { useEffect } from "react";
import {
  CustomText,
  BorderedDropdown,
  Image,
  PrimaryCta,
  CustomTextInput,
  CustomDropdown,
} from "../../components";
import Multiselect from "multiselect-react-dropdown";
import * as CampaignData from "../../common/data/campaign";
import Style from "../../resources/style";
import utils from "../../common/utils";
import Core from "../../common/clientSdk";
import Select from "react-dropdown-select";
import { useSnackbar } from "react-simple-snackbar";
import Utils from "../../common/utils";
import { ToastContainer, toast } from "react-toastify";
import CreateCampaignSuccess from "./createCampaignSuccess";

const options = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "all", label: "All" },
];
const strictList = [
  { value: false, label: "No" },
  { value: true, label: "Yes" },
];

const followers = [
  { value: "1000", label: "Nano    1K - 10k (min Rs 1k)" },
  { value: "10000", label: "Micro  10k - 50k (min Rs 5k)" },
  { value: "50000", label: "Mid Tier  50k - 2.5L (min Rs 10k)" },
  { value: "250000", label: "Macro  2.5L - 10L (min Rs 30k)" },
  { value: "1000000", label: "Top Tier  1M+ (min Rs 50k)" },
];
let selectValue = [{ value: false, label: "No" }];
function TargetInfluencer(props) {
  const [minFollower, setMinFollower] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [audienceAgeGroup, setAudienceAgeGroup] = useState([]);
  const [influencerGender, setInfluencerGender] = useState([]);
  const [strict, setStrict] = useState(selectValue);
  const [contentLanguage, setContentLanguage] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [state, setState] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const [modalIsOpenSuccess, setModalIsOpenSuccess] = useState(true);

  const [openSnackbar] = useSnackbar();
  const campaignType = props?.campaignDeepData?.campaign_data?.type;

  useEffect(() => {
    getCategoryList();
  }, []);

  function getCategoryList() {
    var params = {};
    Core.getCategory(
      getCategorySuccess,
      getCategoryFail,
      updateProgress,
      params
      //redux_selector.BrandUser.brand_deep_data.brand_data.brand_id,
      // redux_selector.Auth.auth_token
    );
  }
  console.log("props demo", props);

  function getCategorySuccess(response) {
    setCategoryList(Object.values(response["category_list"]));
  }

  function getCategoryFail(errorList) {
    toast.info(errorList.map((item) => item.m).join(", "));
  }
  function updateProgress(status) {}

  function upDateCategories(id) {
    // let arrCategory = [...categories];

    // if (arrCategory.includes(id)) {
    //   //  delete arrCategory[id]
    //   arrCategory = arrCategory.filter((e) => e != id);
    // } else {
    //   arrCategory.push(id);
    // }
    // //console.log("arrCategory", arrCategory);
    // setCategories(arrCategory);
    if (categories.includes(id)) {
      setCategories([]);
    } else {
      // If a new category is selected, update the state with the new category id
      setCategories([id]);
    }
  }

  const handleBack = () => {
    props.previousStep();
  };

  const handleNext = () => {
    if (!formValidate()) {
      return false;
    } else {
      props.setCampaignDeepData((prev_state) => {
        let newCampaignData = {
          ...prev_state,
          audience_data: {
            age: audienceAgeGroup,
            gender: influencerGender,
            language: contentLanguage,
          },
          influencer_data: {
            minimum_followers: Number(minFollower),
            //gender: influencerGender,
            categories: category,
          },
          campaign_data: {
            ...prev_state.campaign_data,
            is_strict: strict[0].value,
          },
        };

        if (state.length > 0) {
          newCampaignData["audience_data"]["state"] = JSON.stringify(state);
        }

        if (subCategory.length !== 0) {
          newCampaignData["campaign_data"]["profession"] = subCategory;
        }

        return newCampaignData;
      });

      props.nextStep();
    }
  };

  const handleFinish = () => {
    props.lastStep();
  };

  function formValidate() {
    // if (audienceAgeGroup.length == 0) {
    //   toast.info("Please select at least one age Group");
    //   return false;
    // }
    if (influencerGender.length == 0) {
      toast.info("Please select Influencer's Gender");
      return false;
    }

    if (contentLanguage.length == 0) {
      toast.info("Please select at least one language");
      return false;
    }

    if (campaignType == "social_media" && Number(minFollower) <= 999) {
      toast.info("Please  select  Influencer's Level");
      return false;
    }

    if (category.length == 0) {
      toast.info("Please select at least one category");
      return false;
    }
    return true;
  }

  // function showError(msg) {
  //   toast.error(msg);
  // }

  function showAmountOnFollower(followers) {
    if (followers == 1000) {
      return (
        <CustomText
          p
          fontSize={"font_size_extraSmall"}
          textColor={"text_color_green"}
          text={"min Rs 1k"}
        />
      );
    }
    if (followers == 10000) {
      return (
        <CustomText
          p
          fontSize={"font_size_extraSmall"}
          textColor={"text_color_green"}
          text={" min Rs 5K"}
        />
      );
    }
    if (followers == 50000) {
      return (
        <CustomText
          p
          fontSize={"font_size_extraSmall"}
          textColor={"text_color_green"}
          text={"min Rs 10k"}
        />
      );
    }
  }
  function CategoryDetail() {
    return categoryList.slice(0).map((item, index) => {
      return (
        <div key={index} onClick={() => upDateCategories(item.id)}>
          <Image
            source={utils.generateCategoryImageUrl(item.id)}
            width={106}
            height={106}
            className={
              categories.includes(item.id) ? "selected_image" : "category-image"
            }
          />
          <CustomText
            p
            fontSize={"font_size_small"}
            textColor={"text_color_primary"}
            text={item.name}
            textAlign={"text_align_center"}
          />
        </div>
      );
    });
  }

  function updateAudienceAgeGroup(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setAudienceAgeGroup(value);
  }

  function updateGender(selectedItem, item) {
    console.log("item gender", item);
    console.log("selectedItem gender", selectedItem);

    setInfluencerGender(
      selectedItem ? selectedItem.map((option) => option.value) : []
    );
  }
  function updateStrict(selectedItem) {
    setStrict(selectedItem);
  }
  function updateCategory(selectedItem) {
    let value = [];
    selectedItem.map((option) => {
      value.push(option.value);
    });
    setCategory(value);

    value.flatMap((key) => CampaignData.FULL_CATEGORIES.subcategories[key]);
    let data = CampaignData.FULL_CATEGORIES.subcategories[value].map(
      (item) => {}
    );

    setSubCategoryData(CampaignData.FULL_CATEGORIES.subcategories[value]);
  }

  function updateSubCategory(selectedItem) {
    setSubCategory(selectedItem ? selectedItem.map((option) => option.id) : []);
  }

  function updateMinFollower(selectedItem) {
    setMinFollower(
      selectedItem ? selectedItem.map((option) => option.value) : []
    );
  }

  function updateLanguage(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setContentLanguage(value);
  }

  function categoryUpdate(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.value);
    });

    setCategory(value);

    setSubCategoryData(
      value.flatMap((item) => CampaignData.FULL_CATEGORIES.subcategories[item])
    );
  }

  function subCategoryUpdate(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setSubCategory(value);
  }
  // function categoryUpdate(selectedList, selectedItem) {
  //   var value = [];
  //   selectedList.forEach((item) => {
  //     value.push(item.id);
  //   });

  //   setCategory(value);
  // }

  function updateInfluencer(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setMinFollower(value);
  }
  function updateState(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setState(value);
  }
  console.log("subCategoryData", subCategoryData);

  return (
    <div className="create-campaign-container create-campaign-container-mobile">
      <div className="full-width">
        <div className="type-campaign-container-target create-campaign-detail-page">
          <div className=" m-b-10">
            <div className="m-b-8">
              <CustomText
                p
                fontSize={"font_size_medium"}
                textColor={"text_color_primary"}
                text={"Content Language"}
              />
            </div>
            <div className="dropdown-containers">
              <Multiselect
                placeholder="Choose language"
                id="css_custom"
                options={Object.values(CampaignData.LANGUAGE)}
                onSelect={(selectedList, selectedItem) =>
                  updateLanguage(selectedList, selectedItem)
                }
                onRemove={(selectedList, selectedItem) =>
                  updateLanguage(selectedList, selectedItem)
                }
                selectionLimit={3}
                // selectedValues={getDropdownSelectedValue(
                //   CampaignData.LANGUAGE,
                //   "language",
                //   data_type
                // )}
                singleSelect={false}
                displayValue={"n"}
                showArrow={false}
                style={{
                  chips: Style.chips,
                  option: Style.option,
                  multiselectContainer: Style.multiselectContainer,
                  searchBox: Style.searchBox,
                }}
              />
            </div>

            {/* <BorderedDropdown
              invert
              name={"Choose language"}
              placeholder={"Choose language"}
            /> */}
          </div>

          <div className="m-b-10 ">
            <div className="m-b-8">
              <CustomText
                p
                fontSize={"font_size_medium"}
                textColor={"text_color_primary"}
                text={"Influencer's Gender"}
              />
            </div>
            <div className="dropdown-containers">
              <Select
                multi={false}
                options={options}
                // values={influencerGender.map((value) => ({ value }))}
                onChange={(value, item) => updateGender(value, item)}
                placeholder="Select gender"
                autoFocus={false}
                color="#c8c2c2"
                // Customcolor
                style={{
                  borderWidth: 1,
                  borderRadius: 6,
                  borderStyle: "solid",
                  borderColor: "#ccc",
                  backgroundColor: "#fff",
                  paddingBottom: "13px",
                  paddingTop: "13px",
                  option: {
                    backgroundColor: "red",
                  },
                  maxWidth: 369,
                }}
              />
            </div>

            {/* <BorderedDropdown invert placeholder={"Choose gender"} /> */}
          </div>
          <div className="m-b-10 ">
            <div className="m-b-8">
              <CustomText
                p
                fontSize={"font_size_medium"}
                textColor={"text_color_primary"}
                text={"Category"}
              />
            </div>
            {/* <Select
              multi={false}
              options={Object.values(CampaignData.FULL_CATEGORIES.CATEGORYS)}
              values={category.map((value) => ({ value }))}
              onChange={(value) => updateCategory(value)}
              placeholder="Select category"
              autoFocus={false}
              dropdownHeight={"200px"}
              color="#c8c2c2"
              // Customcolor
              style={{
                border: "#fff",
                backgroundColor: "#fff",
                paddingBottom: "10px",
                paddingTop: "10px",
                option: {
                  backgroundColor: "red",
                },
              }}
            /> */}
            <div className="dropdown-containers">
              <Multiselect
                placeholder="Select category"
                id="css_custom"
                options={Object.values(CampaignData.FULL_CATEGORIES.CATEGORYS)}
                onSelect={(selectedList, selectedItem) =>
                  categoryUpdate(selectedList, selectedItem)
                }
                onRemove={(selectedList, selectedItem) =>
                  categoryUpdate(selectedList, selectedItem)
                }
                // selectedValues={getDropdownSelectedValue(
                //   CampaignData.LANGUAGE,
                //   "language",
                //   data_type
                // )}
                singleSelect={false}
                displayValue={"label"}
                showArrow={false}
                style={{
                  chips: Style.chips,
                  option: Style.option,
                  optionContainer: Style.optionContainer,
                  multiselectContainer: Style.multiselectContainer,
                  searchBox: Style.searchBox,
                }}
              />
            </div>
          </div>
          <div className="m-b-10 ">
            <div className="m-b-8">
              <CustomText
                p
                fontSize={"font_size_medium"}
                textColor={"text_color_primary"}
                text={"Sub Category"}
              />
            </div>
            <div className="dropdown-containers">
              <Multiselect
                placeholder={
                  subCategory.length > 0 ? null : "Select sub category"
                }
                id="css_custom"
                options={subCategoryData}
                onSelect={(selectedList, selectedItem) =>
                  subCategoryUpdate(selectedList, selectedItem)
                }
                onRemove={(selectedList, selectedItem) =>
                  subCategoryUpdate(selectedList, selectedItem)
                }
                // selectedValues={getDropdownSelectedValue(
                //   CampaignData.LANGUAGE,
                //   "language",
                //   data_type
                // )}
                singleSelect={false}
                displayValue={"name"}
                showArrow={false}
                style={{
                  chips: Style.chips,
                  option: Style.option,
                  optionContainer: Style.optionContainer,
                  multiselectContainer: Style.multiselectContainer,
                  searchBox: Style.searchBox,
                }}
              />
            </div>
          </div>

          <div className="m-b-10 max-w-369">
            <div className="m-b-8">
              <CustomText
                p
                fontSize={"font_size_medium"}
                textColor={"text_color_primary"}
                text={"Target Location"}
              />
            </div>
            <div className="dropdown-containers">
              <Multiselect
                placeholder="Select states or cities"
                id="css_custom"
                options={CampaignData.CITY}
                onSelect={(selectedList, selectedItem) =>
                  updateState(selectedList, selectedItem)
                }
                onRemove={(selectedList, selectedItem) =>
                  updateState(selectedList, selectedItem)
                }
                singleSelect={false}
                displayValue={"label"}
                showArrow={false}
                style={{
                  chips: Style.chips,
                  option: Style.option,
                  multiselectContainer: {
                    paddingTop: 0,
                    borderRadius: 6,
                    cursor: "pointer",
                    background: "var(--general-white-color)",
                    boxSizing: "content-box",
                  },
                  searchBox: Style.searchBox,
                }}
              />
            </div>
          </div>

          <div className="max-w-369">
            {campaignType == "social_media" && (
              <>
                <div style={{ gap: 5 }} className="m-b-8 flex-row">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Influencer's Level"}
                  />
                </div>
                <div className="dropdown-containers">
                  <Select
                    multi={false}
                    options={followers}
                    values={minFollower.map((value) => ({ value }))}
                    onChange={(value) => updateMinFollower(value)}
                    placeholder="Select level"
                    autoFocus={false}
                    color="#c8c2c2"
                    // Customcolor
                    style={{
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "#ccc",
                      backgroundColor: "#fff",
                      paddingBottom: "13px",
                      borderRadius: 6,
                      paddingTop: "13px",
                      option: {
                        backgroundColor: "red",
                      },
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="divide-line2"></div>
      <div className="type-campaign-btn">
        <PrimaryCta
          invert
          onClick={handleBack}
          fontSize={"font_size_large"}
          text={"Back"}
        />

        <PrimaryCta
          fifth
          onClick={handleNext}
          fontSize={"font_size_large"}
          text={"Next"}
          className="blue-button"
        />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        zIndex={9999999}
        className="toast-container" 
      />
    </div>
  );
}

export default TargetInfluencer;
