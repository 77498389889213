import React, { useState } from "react";
import {
  CustomText,
  Image,
  CustomTextInput,
  CustomTextArea,
  PrimaryCta,
  TeamMembers,
} from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useSelector } from "react-redux";
import Utils from "../../common/utils";
import Icon from "../../components/core_component/icon";
import Core from "../../common/clientSdk";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import ReportSidebar from "../report-sidebar";
import UserFullName from "../../sidebars/changeUserNameSidebar";
import UserPhoneNumber from "../../sidebars/changeUserPhoneNumber";
import UserEmail from "../../sidebars/changeUserEmail";
import UserDesignation from "../../sidebars/changeUserDesignation";
import UserCountry from "../../sidebars/changeUserCountry";
import UserTimeZone from "../../sidebars/changeUserTimeZone";
import UserChangePassword from "../../sidebars/changeUserPassword";

function UserSettingsScreen() {
  const [isLoading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
 const [changeFullNameSidebarOpen, setChangeFullNameSidebarOpen] = useState(false);
 const [changePhoneNumberSidebarOpen, setChangePhoneNumberSidebarOpen] = useState(false);
 const [changeEmailSidebarOpen, setChangeEmailSidebarOpen] = useState(false);
 const [changeDesignationSidebarOpen, setChangeDesignationSidebarOpen] = useState(false);
 const [changeCountrySidebarOpen, setChangeCountrySidebarOpen] = useState(false);
 const [changeTimeZoneSidebarOpen, setChangeTimeZoneSidebarOpen] = useState(false);
 const [changePasswordSidebarOpen, setChangePasswordSidebarOpen] = useState(false);
  const [error, set_error] = useState({ password: "" });
  const [openSnackbar] = useSnackbar();
  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const navigate = useNavigate();
  const { BrandUser = {} } = redux_selector;
  const { user_data = {} } = BrandUser;

  function postChangePassword(progress_cb) {
    const params = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    if (validatePassword(newPassword) && validatePassword(oldPassword))
      Core.postChangePassword(
        postChangePasswordSuccess,
        postChangePasswordFailed,
        progress_cb,
        params
      );
  }

  function postChangePasswordSuccess(response_data) {
    setLoading(false);
  }

  function postChangePasswordFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  function updateProgress(status) {}

  function validatePassword(value) {
    if (!validator.isLength(value, { min: 8 })) {
      set_error({
        ...error,
        password: "Required length 8 ",
      });
    } else {
      set_error({ ...error, password: "" });
      return true;
    }
  }

  return (
    <>
      <div className="dashboard-container background-color-primary">
        <SideMenu />

        <div className="main-screen">
          <Header />
          <div className="page-container" style={{marginLeft : "20px" , maxHeight : "89vw"}} >
            <div className="campaign-text-heading-user">
              <CustomText
                p
                text={"User Settings"}
                textColor={"text_color_primary"}
                fontSize={"font_size_2xl"}
              />
            </div>
            <div class="content-row-parent">
              <div
                onClick={() => setChangeFullNameSidebarOpen(true)}
                className="content-row"
              >
                <TeamMembers Name={"Full Name"} title={user_data.name} />
              </div>

              <div
                onClick={() => setChangePhoneNumberSidebarOpen(true)}
                className="content-row"
              >
                <TeamMembers
                  Name={"Phone Number"}
                  title={user_data.phone_number}
                />
              </div>
              <div onClick={() => setChangeEmailSidebarOpen(true)} className="content-row">
                <TeamMembers Name={"Email"} title={user_data.email} />
              </div>
              <div
                onClick={() => setChangeDesignationSidebarOpen(true)}
                className="content-row"
              >
                <TeamMembers
                  Name={"Designation"}
                  title={
                    user_data.designation
                      ? user_data.designation
                      : "Please Add Designation"
                  }
                />
              </div>
              <div onClick={() => setChangeCountrySidebarOpen(true)} className="content-row">
                <TeamMembers
                  Name={"Country"}
                  title={
                    user_data.country
                      ? user_data.country
                      : "Please update country"
                  }
                />
              </div>
              <div
                onClick={() => setChangeTimeZoneSidebarOpen(true)}
                className="content-row"
              >
                <TeamMembers
                  Name={"Time Zone"}
                  title={
                    user_data?.time_zone
                      ? user_data?.time_zone
                      : "Please update time zone"
                  }
                />
              </div>
              <div
                onClick={() => setChangePasswordSidebarOpen(true)}
                className="content-row"
              >
                <TeamMembers Name={"Change Password"} title={"********"} />
              </div>
            </div>
          </div>
          {
            <UserFullName
            changeFullNameSidebarOpen={changeFullNameSidebarOpen}
            setChangeFullNameSidebarOpen={setChangeFullNameSidebarOpen}
            />
          }
          {
            <UserPhoneNumber
            changePhoneNumberSidebarOpen={changePhoneNumberSidebarOpen}
            setChangePhoneNumberSidebarOpen={setChangePhoneNumberSidebarOpen}
            />
          }{
            <UserEmail
            changeEmailSidebarOpen={changeEmailSidebarOpen}
            setChangeEmailSidebarOpen={setChangeEmailSidebarOpen}
            
            />
          }
          {
            <UserDesignation
            changeDesignationSidebarOpen={changeDesignationSidebarOpen}
            setChangeDesignationSidebarOpen={setChangeDesignationSidebarOpen}
            />
          }{
            <UserCountry
            changeCountrySidebarOpen={changeCountrySidebarOpen}
            setChangeCountrySidebarOpen={setChangeCountrySidebarOpen}
            />
          }{
            <UserTimeZone
            changeTimeZoneSidebarOpen={changeTimeZoneSidebarOpen}
            setChangeTimeZoneSidebarOpen={setChangeTimeZoneSidebarOpen}
            />
          }
          {
            <UserChangePassword
            changePasswordSidebarOpen={changePasswordSidebarOpen}
            setChangePasswordSidebarOpen={setChangePasswordSidebarOpen}
            />
          }
        </div>
      </div>
    </>
  );
}

export default UserSettingsScreen;
