import React, { useState } from "react";
import {
  CustomText,
  Image,
  CustomTextInput,
  CustomTextArea,
  PrimaryCta,
  TeamMembers,
} from "../components";
import { useSelector } from "react-redux";
import Utils from "../common/utils";
import Core from "../common/clientSdk";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../components";
import { toast, ToastContainer } from "react-toastify";
import { useSnackbar } from "react-simple-snackbar";
import { set } from "date-fns";
import { Toast } from "reactstrap";

function UserChangePassword({
  changePasswordSidebarOpen,
  setChangePasswordSidebarOpen,
}) {
  const [isLoading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openStatus, setOpenStatus] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [error, set_error] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const navigate = useNavigate();
  function postChangePassword(progress_cb) {
    const params = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    if (
      validateOldPassword(newPassword) &&
      validateNewPassword(oldPassword) &&
      showError()
    )
      Core.postChangePassword(
        postChangePasswordSuccess,
        postChangePasswordFailed,
        progress_cb,
        params
      );
  }

  function postChangePasswordSuccess(response_data) {
    toast.success("Password Changed Successfully!");
    setLoading(false);
    setChangePasswordSidebarOpen(false);
  }

  function postChangePasswordFailed(errorList) {
    toast.error(errorList);
  }

  function updateProgress(status) {
    setLoading(status);
  }

  function showError() {
    if (newPassword != confirmPassword) {
      toast.error("Passwords do not match");
      return false;
    }
    return true;
  }

  function validateOldPassword(value) {
    if (!validator.isLength(value, { min: 8 })) {
      set_error({
        ...error,
        old_password: "Password should be atleast 8 characters",
      });
    } else {
      set_error({ ...error, old_password: "" });
      return true;
    }
  }
  function validateNewPassword(value) {
    if (!validator.isLength(value, { min: 8 })) {
      set_error({
        ...error,
        new_password: "Password should be atleast 8 characters ",
      });
    } else {
      set_error({ ...error, new_password: "" });
      return true;
    }
  }
  function validateConfirmPassword(value) {
    if (!validator.isLength(value, { min: 8 })) {
      set_error({
        ...error,
        confirm_password: "Password should be atleast 8 characters ",
      });
    } else {
      set_error({ ...error, confirm_password: "" });
      return true;
    }
  }
  const changeFullNameSidebarHeader = () => {
    return <CustomText p text={"Change Password"} />;
  };
  const changeFullNameSidebarFooter = () => {
    return (
      <PrimaryCta
        fontSize={"font_size_large"}
        fullWidth={true}
        className="blue-button"
        text={"Change Password"}
        isLoading={isLoading}
        disabled={Utils.isNull(newPassword)}
        onClick={() => {
          if (newPassword === confirmPassword) {
            postChangePassword(updateProgress);
          } else {
            toast.error("Passwords do not match");
          }
        }}
      />
    );
  };
  return (
    <div>
      <Sidebar
        sidebarWidth={"30%"}
        isOpen={changePasswordSidebarOpen}
        onClose={() => setChangePasswordSidebarOpen(false)}
        Header={changeFullNameSidebarHeader}
        Footer={changeFullNameSidebarFooter}
        headerAlignment="left"
      >
        <div className="">
          <CustomText p text={"Old Password"} />
        </div>
        <div style={{ marginTop: "10px" }}>
          <div>
            <CustomTextInput
              type="password"
              value={oldPassword}
              height={51}
              placeholder="Old Password"
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
              onBlur={(e) => validateOldPassword(e.target.value)}
            />

            <p className="error">{error.old_password}</p>
          </div>
          <div className="">
            <CustomText p text={"New Password"} />
          </div>
          <div style={{ marginTop: "10px" }}>
            <CustomTextInput
              type="password"
              value={newPassword}
              placeholder="New Password"
              height={51}
              onChange={(e) => setNewPassword(e.target.value)}
              onBlur={(e) => validateNewPassword(e.target.value)}
            />
            <p className="error">{error.new_password}</p>
          </div>
          <div className="">
            <CustomText p text={"Confirm New Password"} />
          </div>
          <div style={{ marginTop: "10px" }}>
            <CustomTextInput
              type="password"
              value={confirmPassword}
              placeholder="Confirm New Password"
              height={51}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onBlur={(e) => validateConfirmPassword(e.target.value)}
            />
            <p className="error">{error.confirm_password}</p>
          </div>
        </div>
      </Sidebar>
      <ToastContainer />
    </div>
  );
}

export default UserChangePassword;
